/* eslint-disable indent */
import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });

export default class CmsProductService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getPlaylistGenres() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/playlist-genres/?limit=0`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getBrands() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/brands/?limit=0`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  createPlaylist(data, token) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/playlists/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  createCmsStoryshields(data, token) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyshields/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getProductTypes() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/product-category/?limit=0`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getProductBySku(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.type === 'storyshield') {
        apiEndPoint = `${this.baseURL}v1/cms/storyshields/?search=${term.sku}`;
      } else if (term.type === 'storyphones') {
        apiEndPoint = `${this.baseURL}v1/cms/storyphones/?search=${term.sku}`;
      } else if (term.type === 'zenshield') {
        apiEndPoint = `${this.baseURL}v1/cms/zenshield/?search=${term.sku}`;
      }
      // const apiEndPoint = `${this.baseURL}v1/stories/?limit=10&orderBy=-updated_at`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createProducts(data, token) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/products/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  createStoryPhones(data, token) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/storyphones/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  createZenshield(data, token) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/zenshield/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
}
