/* eslint-disable indent */
import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });

export default class StoryService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getAllLanguage(term) {
    return new Promise((resolve, reject) => {
      // const apiEndPoint = `${this.baseURL}v1/languages/`;
      let apiEndPoint;
      if (term.searchLanguage.value) {
        apiEndPoint = `${this.baseURL}v1/languages/?search=${term.searchLanguage.value}&orderBy=-updated_at&limit=${term.langPerPage.value}&offset=${term.currentOffset.value}`;
      } else {
        apiEndPoint = `${this.baseURL}v1/languages/?orderBy=-updated_at&limit=${term.langPerPage.value}&offset=${term.currentOffset.value}`;
      }
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addLanguage() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/languages/`;
      this.http
        .post(apiEndPoint, {
          headers: {
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateLanguage() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/languages/`;
      this.http
        .put(apiEndPoint, {
          headers: {
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteLanguage() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/languages/`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
