<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading">
      <Loader />
    </div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-music-note-list"></i> Add New Playlist</h1>
            </div>
          </div>
          <div v-if="successMsg" class="card text-success text-center border-success rounded-5 mb-3">
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="GTIN - ALREADY EXISTS"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="title" class="form-label">Title</label>
                    <input type="text" class="form-control" id="title" v-model="playlistUpload.title" />
                    <span class="text-danger" v-if="v$.title.$error">
                      {{ v$.title.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="title" class="form-label">GTIN</label>
                    <input type="text" class="form-control" id="title" v-model="playlistUpload.gtin" />
                    <span class="text-danger" v-if="v$.gtin.$error">
                      {{ v$.gtin.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="inputOwner" class="form-label">Owner</label>

                    <VueMultiselect v-if="users.items" v-model="playlistUpload.owner" :options="users.items"
                      :close-on-select="true" :clear-on-select="false" placeholder="Select one owner" label="email"
                      track-by="email" />
                    <!-- <span class="text-danger" v-if="v$.owner.$error">
                      {{ v$.owner.$errors[0].$message }}*
                    </span> -->
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Image</label>

                    <div class="input-group">
                      <div class="input-group-text">
                        <div v-if="imageURL">
                          <img :src="imageURL" alt="" style="width: 80px" />
                        </div>
                        <div v-else class="text-danger">Preview</div>
                      </div>
                      <div class="upload-block ms-4">

                        <input type="file" class="form-control-file" id="image-file" accept="image/*" hidden="hidden" />
                        <button class="btn btn-primary form-control" type="button" id="custom-button"
                          @click="imageFile()">
                          <span>Upload <i class="bi bi-upload" aria-hidden="true"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label"> Stories</label>
                    <!-- <div class="dropdown"> -->
                    <!-- data-bs-toggle="dropdown" -->
                    <!-- id="dropdownMenuButton1" -->
                    <VueMultiselect v-if="stories.items" :options="stories.items" :close-on-select="true"
                      :clear-on-select="false" placeholder="Select Story" trackBy="id"
                      :onUpdate:modelValue="handleStorySelection" label="title" />
                    <span class="text-danger" v-if="v$.stories.$error">
                      {{ v$.stories.$errors[0].$message }}*
                    </span>
                    <!-- <button
                      class="btn btn-primary dropdown-toggle form-control border-0"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#storySelectionModal"
                      aria-expanded="false"
                      @click="changeModal('Add')"
                    >
                      Select the Story
                    </button> -->
                  </div>
                  <!-- Display selected stories -->
                  <div v-if="playlistUpload.stories.length > 0">
                    <p>Selected Stories:</p>
                    <Vuedraggable v-model="playlistUpload.stories" tag="ol" class="custom-list">
                      <template #item="{ element: story }">
                        <li class="custom-list-item" @mouseenter="isHovered = true" @mouseleave="isHovered = false"
                          @focus="isHovered = true" @blur="isHovered = false">
                          <span>{{ story.title }}</span>
                          <div class="draggable-handle" v-if="isHovered">
                            <button v-if="isHovered" @click="removeStory(story.id)" class="close-button">&#10005;</button>
                          </div>
                        </li>
                      </template>
                    </Vuedraggable>
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="inputDes">Description</label>
                    <textarea
                      class="form-control"
                      name=""
                      id="inputDes"
                      cols="30"
                      rows="5"
                      v-model="playlistUpload.description"
                    ></textarea>
                  </div> -->
                  <div class="col-md-6">
                    <label for="inputType" class="form-label">Type</label>
                    <select name="" id="inputType" class="form-select" v-model="playlistUpload.type">
                      <option value="">select the Type</option>
                      <option v-for="(type, index) in typeLists.items" :key="index" :value="type.slug">
                        {{ type.slug }}
                      </option>
                    </select>
                    <span class="text-danger" v-if="v$.type.$error">
                      {{ v$.type.$errors[0].$message }}*
                    </span>
                  </div>
                </div>
                <div class="container text-end">
                  <button @click="createPlaylist()" class="btn btn-outline-success me-2">
                    Add
                  </button>
                  <router-link to="/playlists" type="button" class="btn btn-outline-danger">Cancel</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
  <!-- story selection Modal -->
  <div class="modal fade modal-lg" id="storySelectionModal" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-labelledby="storySelectionModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="storySelectionModalLabel">Select the Stories</h5>
        </div>
        <StorySelectionModal :modal-data="modalName" />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import Vuedraggable from 'vuedraggable';
import {
  required, email, helpers, between,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import PlaylistService from '@/services/PlaylistService';
import AddAndDeleteStory from '@/components/partials/AddAndDeleteStory.vue';
import StorySelectionModal from '@/components/partials/StorySelectionModal.vue';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';

import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const playlistService = new PlaylistService();

let imageFileBtn;

export default {
  name: 'About',
  props: [],
  components: {
    Loader,
    // AddAndDeleteStory,
    DashboardTemplate,
    // VuejsPaginateNext,
    StorySelectionModal,
    VueMultiselect,
    Vuedraggable,
    ErrorDisplay,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false);
    const playlistsId = ref('');
    const modalName = ref('');
    const routePath = ref('');
    const owner = ref('');
    const storyPerPage = ref(0);
    const searchPlaylist = ref('');
    const searchPlaylistType = ref('');
    const perPagePlaylist = ref(0);
    const searchStory = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const perPageUser = ref(0);
    const playlistPerPage = ref(0);
    const imageURL = ref('');
    const pageCount = ref(0);
    const page = ref(1);
    const successMsg = ref('');
    const errorMsg = ref('');
    routePath.value = route.path;
    const isHovered = ref(false);

    const playlistUpload = reactive({
      title: '',
      description: '',
      type: '',
      owner: '',
      image: '',
      stories: [],
      gtin: '',

    });

    const handleStorySelection = (selectedStories) => {
      const existingStory = playlistUpload.stories.find((story) => story.id === selectedStories.id);

      // If it doesn't exist, push the new story
      if (!existingStory) {
        playlistUpload.stories.push(selectedStories);
      }
    };
    const rules = {
      // owner: { required: helpers.withMessage('Please select an owner', required) },
      type: { required: helpers.withMessage('Please select a type', required) },
      title: { required: helpers.withMessage('Please enter a title', required) },
      stories: { required: helpers.withMessage('Please select a story', required) },
      gtin: { required: helpers.withMessage('Please select a gtin', required) },
    };

    const v$ = useVuelidate(rules, playlistUpload);

    isLoading.value = true;

    onMounted(() => {
      imageFileBtn = document.getElementById('image-file');
    });
    const SearchData = {
      searchPlaylist,
      currentOffset,
      playlistPerPage,
    };
    store.dispatch('fetchAllPlaylists', SearchData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / perPage.value);

      isLoading.value = false;
    });
    const searchUser = ref('');

    const searchUserData = {
      searchUser,
      currentOffset,
      perPageUser,
    };

    store.dispatch('fetchAllUsers', searchUserData);

    store.dispatch('fetchStoriesWithOutlimit');

    const playListData = {
      searchPlaylistType,
      currentOffset,
      perPagePlaylist,
    };

    store.dispatch('fetchAllPlaylistType', playListData);

    const getStoryId = computed(() => store.getters.getStoryIds);

    const playlistDetails = computed(() => store.getters.getStoryByPlaylistId);

    const playlists = computed(() => store.getters.getPlaylists);

    const editPlaylists = computed(() => store.getters.getPlaylistById);

    const typeLists = computed(() => store.getters.getAllPlaylistType);

    const stories = computed(() => store.getters.getAllStoriesWithOutLimt);

    const users = computed(() => store.getters.getAllUsers);

    const imageListener = () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.files[0];
        playlistUpload.image = file;
        const blob = new Blob([file], { type: file.type });
        imageURL.value = URL.createObjectURL(blob);
      } else {
        console.log('no file selected');
      }
    };

    const imageFile = () => {
      imageFileBtn.click();
      imageFileBtn.addEventListener('change', imageListener);
    };

    const createPlaylist = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        const formData = new FormData();
        formData.append('title', playlistUpload.title);
        formData.append('gtin', playlistUpload.gtin);
        // formData.append('description', playlistUpload.description);
        formData.append('type', playlistUpload.type);
        if (playlistUpload.owner) {
          formData.append('owner', playlistUpload.owner.id);
        }
        formData.append('image', playlistUpload.image);
        playlistUpload.stories.forEach((item, index) => {
          const storyObject = {
            order: index + 1,
            story: item.id,
          };
          formData.append('stories[]', JSON.stringify(storyObject));
        });
        playlistService.playlistUpload(formData).then(() => {
          store.dispatch('removeAllStoryIds');
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          isLoading.value = false;
          successMsg.value = ' Playlist Has Been Added Successfully';
          setTimeout(() => {
            successMsg.value = '';
            router.push('/playlists').then(() => { router.go(); });
          }, 3000);
        })
          .catch((error) => {
            isLoading.value = false;
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };

    const changeModal = (val) => {
      modalName.value = val;
    };

    const removeStory = (id) => {
      playlistUpload.stories = playlistUpload.stories.filter((story) => story.id !== id);
    };
    return {
      isLoading,
      typeLists,
      stories,
      users,
      playlists,
      playlistDetails,
      imageFile,
      createPlaylist,
      removeStory,
      playlistUpload,
      getStoryId,
      editPlaylists,
      playlistsId,
      searchPlaylist,
      searchStory,
      searchPlaylistType,
      currentOffset,
      perPage,
      pageCount,
      page,
      changeModal,
      handleStorySelection,
      modalName,
      routePath,
      successMsg,
      errorMsg,
      v$,
      imageURL,
      isHovered,
    };
  },
};
</script>
<style scoped>
.custom-list {
  padding: 10px;
  border-radius: 8px;
  list-style-type: none;
}

.custom-list-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding: 8px;
  border: 1px solid #000;
  border-radius: 4px;
  cursor: grab;
}

.draggable-handle {
  position: absolute;
  right: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-list-item:hover .draggable-handle {
  opacity: 1;
}

.close-button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}
</style>
