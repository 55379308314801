/* eslint-disable indent */
/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const getAllPlaylistType = (state) => isObj(state, 'PlaylistType', []);
export const getPlaylistTypeDetails = (state) => isObj(state, 'PlaylistTypeDetails', []);
export const getAllStoryType = (state) => isObj(state, 'storyType', []);
export const getStoryTypeDetails = (state) => isObj(state, 'storyTypeDetails', []);
export const getAllShieldType = (state) => isObj(state, 'shieldType', []);
export const getShieldTypeDetails = (state) => isObj(state, 'shieldTypeDetails', []);
