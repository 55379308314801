import CmsWidgetService from '@/services/CmsWidgetService';
import * as types from './mutation-types';

const ent = require('ent');

const cmsWidgetService = new CmsWidgetService();

export const fetchAllCmsWidgets = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cmsWidgetService
      .getCmsWidgets(Data)
      .then((response) => {
        commit(types.CMS_WIDGETS_LIST, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
export const fetchCmsWidgetdetailsById = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cmsWidgetService
      .getCmsWidgetDetails(Data)
      .then((response) => {
        commit(types.CMS_WIDGET_DETAILS_BY_ID, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
