<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class=""></i> Firmware Details</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to update firmware"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="title" class="form-label">Version</label>
                    <input type="text" class="form-control" v-model="List.version" />
                    <span class="text-danger" v-if="v$.version.$error">
                      {{ v$.version.$errors[0].$message }}*
                    </span>
                  </div>

                  <div class="col-md-6 mt-4">
                    <div class="form-check form-switch mt-4">
                      <label class="form-check-label" for="flexSwitchActive"
                        >Selected Latest
                      </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="List.selected_latest"
                        id="flexSwitchActive"
                        :true-value="true"
                        :false-value="false"
                      />
                    </div>
                  </div>

                  <!-- File Section -->
                  <!-- <label for="title" class="form-label">File</label>
                    <input type="text" class="form-control" v-model="List.file" /> -->
                  <div class="col-md-12 border">
                    <form class="p-5">
                      <div class="form-group row">
                        <label for="title" class="form-label col-md-2">File</label>
                        <div class="col-md-6 mb-4">
                          <input
                            type="file"
                            class="form-control-file"
                            id="newfile"
                            hidden="hidden"
                            accept=".bin"
                          />
                          <button
                            class="btn btn-primary form-control"
                            type="button"
                            id="custom-button"
                            @click="uploadFile()"
                          >
                            <span>Change File <i class="bi bi-upload" aria-hidden="true"></i></span>
                          </button>
                        </div>
                        <label for="title" class="form-label">File Name : {{ fileName }}</label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="text-end mt-5">
                <button class="btn btn-primary mx-3" @click="editFirmware()">Update</button>
              <button class="btn btn-primary" @click="deletefirmware()">Delete</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script>
import { useStore } from 'vuex';
import {
  ref, reactive, onMounted, computed,
} from 'vue';
import { required, helpers } from '@vuelidate/validators';
import { useRoute, useRouter } from 'vue-router';

import useVuelidate from '@vuelidate/core';

import FirmWareService from '@/services/FirmWareService';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

// import LoginVue from './Login.vue';

const firmWareService = new FirmWareService();
let FileBtn;

export default {
  name: 'FirmWareDetail',
  components: {
    DashboardTemplate,
    Loader,
    ErrorDisplay,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const id = ref('');
    const List = ref([]);
    const fileName = ref('');
    const route = useRoute();
    id.value = route.params.id;
    const searchFirm = ref('');
    const searchCms = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const successMsg = ref('');
    const errorMsg = ref('');
    const isLoading = ref(false);
    const FirmWareUpload = reactive({
      version: '',
      selected_latest: '',
      file: '',
    });

    const rules = {
      version: { required: helpers.withMessage('Please enter a version', required) },
      file: { required: helpers.withMessage('Please upload file', required) },
    };

    const v$ = useVuelidate(rules, FirmWareUpload);
    onMounted(() => {
      FileBtn = document.getElementById('newfile');
    });

    const fileListener = async () => {
      if (FileBtn.value) {
        const file = FileBtn.files[0];
        List.value.file = file;
        fileName.value = file.name;
      } else {
        console.log('no file selected');
      }
    };

    const uploadFile = () => {
      FileBtn.click();
      FileBtn.addEventListener('change', fileListener);
    };

    const editFirmware = async () => {
      isLoading.value = true;
      const formData = new FormData();
      formData.append('version', List.value.version);
      formData.append('selected_latest', List.value.selected_latest);
      formData.append('file', List.value.file);
      firmWareService
        .FirmWareUpdate(List.value.id, formData)
        .then((res) => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          isLoading.value = false;
          successMsg.value = 'Firmware Updated Successfully';
          setTimeout(() => {
            successMsg.value = '';
            router.push('/firm-ware');
          }, 3000);
        })
        .catch((error) => {
          isLoading.value = false;
          errorMsg.value = error?.data;
          setTimeout(() => {
            errorMsg.value = '';
          }, 3000);
        });
    };
    const SearchData = {
      searchCms,
      currentOffset,
      perPage,
    };
    store.dispatch('fetchFirmById', id.value).then((res) => {
      List.value = res.data;
      fileName.value = res.data.file;
    });

    const firmwareListDetailsById = computed(() => store.getters.getFirmWareListsById);
    const deletefirmware = () => {
      isLoading.value = true;
      firmWareService
        .firmWareDelete(id.value)
        .then(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          isLoading.value = false;
          successMsg.value = ' Firmware Has Been Deleted Successfully';
          setTimeout(() => {
            successMsg.value = '';
            router.push('/firm-ware');
          }, 3000);
        })
        .catch((error) => {
          isLoading.value = false;
          errorMsg.value = error?.data;
          setTimeout(() => {
            errorMsg.value = '';
          }, 3000);
        });
    };

    return {
      deletefirmware,
      id,
      searchFirm,
      editFirmware,
      SearchData,
      firmwareListDetailsById,
      currentOffset,
      perPage,
      FirmWareUpload,
      v$,
      isLoading,
      successMsg,
      errorMsg,
      uploadFile,
      List,
      fileName,
    };
  },
};
</script>
