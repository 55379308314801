import { createApp } from 'vue';
import titleMixin from './mixins/titleMixin';

import App from './App.vue';
import routes from './routes';
import store from './store';

const vueApp = createApp(App);

vueApp.use(routes);
vueApp.use(store);
vueApp.mixin(titleMixin);

vueApp.mount('#app');
