/* eslint-disable indent */
/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const getStoryIds = (state) => isObj(state, 'storyId', []);
export const getPlaylists = (state) => isObj(state, 'playlists', []);
export const getPlaylistById = (state) => isObj(state, 'playlistById', []);
export const getStoryByPlaylistId = (state) => isObj(state, 'storyByplaylistId', []);
export const getDeletedPlaylists = (state) => isObj(state, 'deletedPlaylists', []);
