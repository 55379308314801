/* eslint-disable no-undef */
import * as types from './mutation-types';

const mutations = {
  [types.CMS_WIDGETS_LIST](state, cmsWidgets) {
    state.cmsWidgets = cmsWidgets;
  },
  [types.CMS_WIDGET_DETAILS_BY_ID](state, cmsWidgetDetailsById) {
    state.cmsWidgetDetailsById = cmsWidgetDetailsById;
  },

};
export default mutations;
