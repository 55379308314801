import * as types from './mutation-types';

const mutations = {
  [types.SHIELD_DETAILS](state, shield) {
    state.shield = shield;
  },

  [types.SHIELD_BY_ID](state, shieldById) {
    state.shieldById = shieldById;
  },

  [types.DELETED_SHIELD](state, deletedShield) {
    state.deletedShield = deletedShield;
  },

};

export default mutations;
