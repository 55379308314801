// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';

function formatUTC(date, format) {
  return moment.utc(date).local().format(format);
  // return moment(date).format(format);
}
function someOtherHelper() {
  return 'Some other helpers';
}
export { formatUTC, someOtherHelper };
