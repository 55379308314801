import * as types from './mutation-types';

const mutations = {
  [types.STORY_TYPES](state, storyType) {
    state.storyType = storyType;
  },
  [types.STORIES](state, stories) {
    state.stories = stories;
  },
  [types.STORY_BY_ID](state, story) {
    state.story = story;
  },

};

export default mutations;
