import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

export default {
  state() {
    return {
      firmwareLists: [],
      firmwareListsById: [],

    };
  },
  mutations,
  actions,
  getters,
};
