<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-music-note-list"></i> Add New CMS StoryShield</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to add Storyshield"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="" class="form-label">Title</label>
                    <input type="text" class="form-control" v-model="storyUpload.title" />
                    <span class="text-danger" v-if="v$.title.$error">
                      {{ v$.title.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">GTIN</label>
                    <input type="text" class="form-control" v-model="storyUpload.gtin" />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">SKU ( EAN number)</label>
                    <input type="text" class="form-control" v-model="storyUpload.sku" />
                    <span class="text-danger" v-if="v$.sku.$error">
                      {{ v$.sku.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">PEGI</label>
                    <input type="number" class="form-control" v-model="storyUpload.pegi" />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">AGE</label>
                    <input type="number" class="form-control" v-model="storyUpload.age" />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Description</label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      class="form-control"
                      v-model="storyUpload.description"
                    ></textarea>
                    <span class="text-danger" v-if="v$.description.$error">
                      {{ v$.description.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Duration</label>
                    <input type="number" class="form-control" v-model="storyUpload.duration" />
                    <span class="text-danger" v-if="v$.duration.$error">
                      {{ v$.duration.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Add Chapter Name</label>
                    <div class="d-flex">
                      <input type="text" class="form-control" v-model="addInformation" />
                      <button class="btn btn-outline-info rounded-5 mx-3" @click="addDescription">
                        <i class="bi bi-plus-lg"></i>
                      </button>
                    </div>
                    <span class="text-danger" v-if="v$.informations.$error">
                      {{ v$.informations.$errors[0].$message }}*
                    </span>
                    <ul class="mt-3">
                      <li v-for="(item, index) in storyUpload.informations" :key="index">
                        <div class="d-flex justify-content-between mt-1">
                          <span>{{ item }}</span>
                          <button
                            class="btn btn-outline-danger rounded-5 mx-3"
                            @click="delDescription(index)"
                          >
                            <i class="bi bi-trash"></i>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Language</label>
                    <select name="" id="" class="form-select" v-model="storyUpload.language">
                      <option value="">Select the Language</option>
                      <option
                        v-for="(lang, index) in language.items"
                        :key="index"
                        :value="lang.code"
                      >
                        {{ lang.language }}
                      </option>
                    </select>
                    <span class="text-danger" v-if="v$.language.$error">
                      {{ v$.language.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Genre</label>
                    <select name="" id="" class="form-select" v-model="storyUpload.genre">
                      <option value="">select the genre</option>
                      <option
                        v-for="(genre, index) in getPlaylistGenres.items"
                        :key="index"
                        :value="genre.id"
                      >
                        {{ genre.title }}
                      </option>
                    </select>
                    <span class="text-danger" v-if="v$.genre.$error">
                      {{ v$.genre.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Brand</label>
                    <select name="" id="" class="form-select" v-model="storyUpload.brand">
                      <option value="">select the Brand</option>
                      <option
                        v-for="(brand, index) in getBrands.items"
                        :key="index"
                        :value="brand.slug"
                      >
                        {{ brand.name }}
                      </option>
                    </select>
                    <span class="text-danger" v-if="v$.brand.$error">
                      {{ v$.brand.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <div class="upload-block">
                      <label for="" class="form-label">Audio</label>
                      <input
                        type="file"
                        class="form-control-file"
                        id="audio-file"
                        accept="audio/*"
                        hidden="hidden"
                      />
                      <button
                        class="btn btn-primary form-control"
                        type="button"
                        id="custom-button"
                        @click="audioFile()"
                      >
                        <span>Audio <i class="bi bi-upload" aria-hidden="true"></i></span>
                      </button>
                      <div class="mt-4" v-if="audioURL">
                        <audio controls class="w-100">
                          <source :src="audioURL" type="audio/mpeg" />
                        </audio>
                      </div>
                      <div v-else class="text-danger">Preview</div>
                      <span class="text-danger" v-if="v$.audio.$error">
                        {{ v$.audio.$errors[0].$message }}*
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Image</label>

                    <div class="input-group">
                      <div class="input-group-text">
                        <div v-if="imageURL">
                          <img :src="imageURL" alt="" style="width: 80px" />
                        </div>
                        <div v-else class="text-danger">Preview</div>
                      </div>
                      <div class="upload-block ms-4">
                        <input
                          type="file"
                          class="form-control-file"
                          id="image-file"
                          accept="image/*"
                          hidden="hidden"
                        />
                        <button
                          class="btn btn-primary form-control"
                          type="button"
                          id="custom-button"
                          @click="imageFile()"
                        >
                          <span>Image <i class="bi bi-upload" aria-hidden="true"></i></span>
                        </button>
                      </div>

                    </div>
                    <span class="text-danger" v-if="v$.image.$error">
                        {{ v$.image.$errors[0].$message }}*
                      </span>
                  </div>
                  <!-- <div class="col-md-6">
                      <label for="" class="form-label">Description</label>
                      <textarea
                        class="form-control"
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        v-model="storyUpload.description"
                      ></textarea>
                    </div> -->
                </div>
                <div class="container text-end mt-4">
                  <button @click="uploadStory()" class="btn btn-outline-success me-2">Create</button>
                  <!-- <router-link to="/stories" type="button" class="btn btn-outline-danger"
                    >Cancel</router-link
                  > -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import {
  required, email, helpers, between,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import CmsProductService from '@/services/CmsProductService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const cmsProductService = new CmsProductService();

let audioFileBtn;
let imageFileBtn;

export default {
  name: 'About',
  props: [],
  components: {
    DashboardTemplate,
    Loader,
    ErrorDisplay,
    // VuejsPaginateNext,
    // VueMultiselect,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);
    const imageURL = ref('');
    const audioURL = ref('');
    const storyId = ref('');
    const searchStory = ref('');
    const searchStoryType = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const perPageStory = ref(0);
    const perPageUser = ref(0);
    const pageCount = ref(0);
    const page = ref(1);
    const routePath = ref('');
    const searchLanguage = ref('');
    const langPerPage = ref(0);
    routePath.value = route.path;
    const owner = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const storyPerPage = ref(0);
    const isbnStories = ref([]);
    const addInformation = ref([]);

    const storyUpload = reactive({
      title: '',
      description: '',
      image: '',
      audio: '',
      language: '',
      gtin: '',
      pegi: '',
      duration: '',
      brand: '',
      sku: '',
      age: '',
      genre: '',
      informations: [],

    });

    // isLoading.value = true;

    const rules = {
      title: { required: helpers.withMessage('Please Enter the Title', required) },
      description: { required: helpers.withMessage('Please Enter the Description', required) },
      image: { required: helpers.withMessage('Please select a Image', required) },
      brand: { required: helpers.withMessage('Please select a Brand', required) },
      genre: { required: helpers.withMessage('Please select a Genre', required) },
      informations: { required: helpers.withMessage('Please Enter the Story Description', required) },
      sku: { required: helpers.withMessage('Please Enter the sku', required) },
      language: { required: helpers.withMessage('Please select a language', required) },
      audio: { required: helpers.withMessage('Please select a audio', required) },
      duration: { required: helpers.withMessage('Please Enter the Duration', required) },
    };

    const v$ = useVuelidate(rules, storyUpload);

    const searchUser = ref('');

    const langData = {
      searchLanguage,
      currentOffset,
      langPerPage,
    };

    store.dispatch('fetchAllLanguages', langData);
    store.dispatch('fetchPlaylistGenres');
    store.dispatch('fetchBrands');

    const language = computed(() => store.getters.getLanguage);
    const getPlaylistGenres = computed(() => store.getters.getPlaylistGenres);
    const getBrands = computed(() => store.getters.getBrands);

    onMounted(() => {
      audioFileBtn = document.getElementById('audio-file');
      imageFileBtn = document.getElementById('image-file');
    });

    const audioListener = () => {
      if (audioFileBtn.value) {
        const file = audioFileBtn.files[0];
        storyUpload.audio = file;
        const blob = new Blob([file], { type: file.type });
        audioURL.value = URL.createObjectURL(blob);
      } else {
        console.log('no file selected');
      }
    };

    const imageListener = () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.files[0];
        storyUpload.image = file;
        const blob = new Blob([file], { type: file.type });
        imageURL.value = URL.createObjectURL(blob);
      } else {
        console.log('no file selected');
      }
    };

    const audioFile = () => {
      audioFileBtn.click();
      audioFileBtn.addEventListener('change', audioListener);
    };

    const imageFile = () => {
      imageFileBtn.click();
      imageFileBtn.addEventListener('change', imageListener);
    };

    const uploadStory = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        const formData = new FormData();
        formData.append('title', storyUpload.title);
        formData.append('description', storyUpload.description);
        formData.append('gtin', storyUpload.gtin);
        formData.append('image', storyUpload.image);
        formData.append('pegi', storyUpload.pegi);
        formData.append('tracks', storyUpload.informations.length);
        formData.append('duration', storyUpload.duration);
        formData.append('brand', storyUpload.brand);
        const formData2 = new FormData();

        cmsProductService.createPlaylist(formData).then((res) => {
          formData2.append('title', storyUpload.title);
          formData2.append('sku', storyUpload.sku);
          formData2.append('is_empty', false);
          formData2.append('description', storyUpload.description);
          formData2.append('age', storyUpload.age);
          formData2.append('language', storyUpload.language);
          formData2.append('genre', storyUpload.genre);
          formData2.append('playlist', res.data.id);
          formData2.append('brand', storyUpload.brand);
          formData2.append('is_new', true);
          formData2.append('availability_status', 'available');
          formData2.append('image', storyUpload.image);
          // formData2.append('sample_audio', storyUpload.audio);
          storyUpload.informations.forEach((item) => formData2.append('informations[]', item));

          cmsProductService.createCmsStoryshields(formData2).then((response) => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            successMsg.value = 'StoryShield Has Been Added Successfully';
            storyUpload.title = '';
            storyUpload.description = '';
            storyUpload.image = '';
            storyUpload.audio = '';
            storyUpload.language = '';
            storyUpload.gtin = '';
            storyUpload.pegi = '';
            storyUpload.duration = '';
            storyUpload.brand = '';
            storyUpload.sku = '';
            storyUpload.age = '';
            storyUpload.genre = '';
            storyUpload.informations = [];
            imageURL.value = '';
            audioURL.value = '';
            v$.value.$reset();
            setTimeout(() => {
              successMsg.value = '';
            }, 3000);
            owner.value = '';
            isLoading.value = false;
          }).catch((error) => {
            isLoading.value = false;
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
        })
          .catch((error) => {
            isLoading.value = false;
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };
    const addDescription = () => {
      storyUpload.informations.push(addInformation.value);
      setTimeout(() => {
        addInformation.value = '';
      }, 0);
    };
    const delDescription = (index) => {
      storyUpload.informations.splice(index, 1);
    };
    return {
      store,
      audioFile,
      imageFile,
      storyUpload,
      uploadStory,
      isLoading,
      imageURL,
      audioURL,
      storyId,
      searchStory,
      currentOffset,
      perPage,
      pageCount,
      page,
      language,
      routePath,
      owner,
      successMsg,
      errorMsg,
      v$,
      isbnStories,
      addInformation,
      getPlaylistGenres,
      getBrands,
      addDescription,
      delDescription,
    };
  },

};
</script>
