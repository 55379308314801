import * as types from './mutation-types';

const mutations = {
  [types.STORYPHONE_DETAILS](state, storyPhone) {
    state.storyPhone = storyPhone;
  },

  [types.STORYPHONE_DETAILS_BY_ID](state, storyPhoneById) {
    state.storyPhoneById = storyPhoneById;
  },
  [types.DELETED_STORYPHONE](state, deletedStoryPhone) {
    state.deletedStoryPhone = deletedStoryPhone;
  },
  [types.STORYPHONE_MODELS](state, storyPhoneModels) {
    state.storyPhoneModels = storyPhoneModels;
  },

};

export default mutations;
