<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <section class="cloud-bg login container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-sm-6 text-center mt-5">
        <img src="/images/logo.png" alt="" class="img-fluid" />
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-sm-8 col-lg-5 shadow-sm bg-white rounded-3 p-5">
        <h3 class="text-center mb-4">LOGIN</h3>
        <div class="text-center">
          <span class="text-danger" v-if="error">
            {{ error }}
          </span>
        </div>
        <form @submit.prevent="">
          <div class="mb-3 form-group">
            <label for="email" class="form-label">Email</label>
            <input
              type="text"
              class="form-control"
              id="email"
              name="email"
              v-model="userLogin.email"
            />
            <span class="text-danger" v-if="v$.email.$error">
              {{ v$.email.$errors[0].$message }}*
            </span>
          </div>
          <div class="mb-3 form-group">
            <label for="password" class="form-label">Password</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="userLogin.password"
              name="password"
            />
            <span class="text-danger pl-3" v-if="v$.password.$error">
                {{ v$.password.$errors[0].$message }}*
              </span>
            <div class="eye-pwd" @click="toggleShowPassword()">
              <div v-if="showPassword">
                <i class="bi bi-eye" aria-hidden="true"></i>
              </div>
              <div v-if="!showPassword">
                <i class="bi bi-eye-slash" aria-hidden="true"></i>
              </div>

            </div>
          </div>
          <button type="submit" class="btn btn-outline-primary btn-block" @click="login">
            Login
          </button>
        </form>
      </div>
    </div>
    <div class="text-center mt-5">
      <p>&copy; 2023 StoryPhones, All Rights Reserved</p>
    </div>
  </section>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { ref, computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import {
  required, email, helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import AuthenticationService from '@/services/AuthenticationService';
import Loader from '@/components/commons/Loader.vue';

const { cookies } = useCookies({ secure: true });
const authenticationService = new AuthenticationService();

export default {
  name: 'login',
  props: [],
  components: {
    Loader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const showPassword = ref(false);

    const isLoading = ref(false);
    const error = ref('');
    const userLogin = reactive({
      email: '',
      password: '',
      selectedMarket: '',
    });

    const rules = {
      email: { required: helpers.withMessage('Please enter your Email', required), email },
      password: {
        required: helpers.withMessage('Please enter your Password', required),
      },
    };

    const v$ = useVuelidate(rules, userLogin);

    const toggleShowPassword = () => {
      const password = document.querySelector('#password');
      if (showPassword.value) {
        showPassword.value = false;
        password.setAttribute('type', 'password');
      } else {
        showPassword.value = true;
        password.setAttribute('type', 'text');
      }
    };

    const login = async () => {
      const result = await v$.value.$validate();
      if (result) {
        const credentials = {
          email: userLogin.email,
          password: userLogin.password,
        };
        isLoading.value = true;
        authenticationService.login(credentials)
          .then((user) => {
            localStorage.setItem('storyPhonesUser', user.data.access);
            isLoading.value = false;
            router.push('/dashboard');
          }).catch((err) => {
            if (err?.response.status === 409) {
              error.value = err?.response?.data?.message;
            } else if (err?.response.status === 401) {
              error.value = 'Email or Password is incorrect';
            }

            // error.value = 'Somthing went wrong';
            v$.value.$reset();
            isLoading.value = false;
            setTimeout(() => {
              error.value = '';
            }, 3000);
          });
      }
    };

    return {
      showPassword, isLoading, error, userLogin, login, toggleShowPassword, v$,

    };
  },
};
</script>

<style scoped lang="scss"></style>
