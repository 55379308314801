<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-file-music"></i> Add New Story</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="ISBN already exists"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="" class="form-label">Title</label>
                    <input type="text" class="form-control" v-model="storyUpload.title" />
                    <span class="text-danger" v-if="v$.title.$error">
                      {{ v$.title.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">ISBN</label>
                    <input type="text" class="form-control" v-model="storyUpload.isbn" />
                    <span class="text-danger" v-if="v$.isbn.$error">
                      {{ v$.isbn.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Language</label>
                    <select name="" id="" class="form-select" v-model="storyUpload.language">
                      <option value="">Select the Language</option>
                      <option
                        v-for="(lang, index) in language.items"
                        :key="index"
                        :value="lang.code"
                      >
                        {{ lang.language }}
                      </option>
                    </select>
                    <span class="text-danger" v-if="v$.language.$error">
                      {{ v$.language.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Type</label>
                    <select name="" id="" class="form-select" v-model="storyUpload.type">
                      <option value="">select the Type</option>
                      <option
                        v-for="(type, index) in storyType.items"
                        :key="index"
                        :value="type.slug"
                      >
                        {{ type.slug }}
                      </option>
                    </select>
                    <span class="text-danger" v-if="v$.type.$error">
                      {{ v$.type.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Owner</label>
                    <VueMultiselect
                      v-if="user.items"
                      v-model="storyUpload.owner"
                      :options="user.items"
                      :close-on-select="true"
                      :clear-on-select="false"
                      placeholder="Select one owner"
                      label="email"
                      track-by="email"
                    />
                    <!-- <span class="text-danger" v-if="v$.owner.$error">
                      {{ v$.owner.$errors[0].$message }}*
                    </span> -->
                  </div>
                  <div class="col-md-6">
                    <div class="upload-block">

                      <label for="" class="form-label">Audio</label>
                      <input
                        type="file"
                        class="form-control-file"
                        id="audio-file"
                        accept="audio/*"
                        hidden="hidden"
                      />
                      <button
                        class="btn btn-primary form-control"
                        type="button"
                        id="custom-button"
                        @click="audioFile()"
                      >
                        <span>Audio <i class="bi bi-upload" aria-hidden="true"></i></span>
                      </button>
                      <div class="mt-4" v-if="audioURL">
                        <audio controls class="w-100">
                          <source :src="audioURL" type="audio/mpeg" />
                        </audio>
                      </div>
                      <div v-else class="text-danger">Preview</div>
                      <span class="text-danger" v-if="v$.audio.$error">
                        {{ v$.audio.$errors[0].$message }}*
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Image</label>

                    <div class="input-group">
                      <div class="input-group-text">
                        <div v-if="imageURL">
                          <img :src="imageURL" alt="" style="width: 80px" />
                        </div>
                        <div v-else class="text-danger">Preview</div>
                      </div>
                      <div class="upload-block ms-4">
                        <input
                          type="file"
                          class="form-control-file"
                          id="image-file"
                          accept="image/*"
                          hidden="hidden"
                        />
                        <button
                          class="btn btn-primary form-control"
                          type="button"
                          id="custom-button"
                          @click="imageFile()"
                        >
                          <span>Image <i class="bi bi-upload" aria-hidden="true"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Description</label>
                    <textarea
                      class="form-control"
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      v-model="storyUpload.description"
                    ></textarea>
                  </div> -->
                  <div class="col-md-6">
                    <label for="" class="form-label">Parent Story</label>
                    <VueMultiselect
                      v-if="isbnStories"
                      v-model="storyUpload.parent"
                      :options="isbnStories"
                      :close-on-select="true"
                      :clear-on-select="false"
                      placeholder="Select Parent Story"
                      trackBy="isbn"
                      label="title"
                    />
                  </div>
                </div>
                <div class="container text-end mt-4">
                  <button @click="uploadStory()" class="btn btn-outline-success me-2">Add</button>
                  <router-link to="/stories" type="button" class="btn btn-outline-danger"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import {
  required, email, helpers, between,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import StoryService from '@/services/StoryService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const storyService = new StoryService();

let audioFileBtn;
let imageFileBtn;

export default {
  name: 'About',
  props: [],
  components: {
    DashboardTemplate,
    Loader,
    // VuejsPaginateNext,
    VueMultiselect,
    ErrorDisplay,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);
    const imageURL = ref('');
    const audioURL = ref('');
    const storyId = ref('');
    const searchStory = ref('');
    const searchStoryType = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const perPageStory = ref(0);
    const perPageUser = ref(0);
    const pageCount = ref(0);
    const page = ref(1);
    const routePath = ref('');
    const searchLanguage = ref('');
    const langPerPage = ref(0);
    routePath.value = route.path;
    const owner = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const storyPerPage = ref(0);
    const isbnStories = ref([]);

    const storyUpload = reactive({
      title: '',
      description: '',
      type: '',
      isbn: '',
      owner: '',
      image: '',
      audio: '',
      parent: '',
      language: '',

    });

    // isLoading.value = true;

    const storyData = {
      searchStoryType,
      currentOffset,
      perPageStory,
    };

    store.dispatch('fetchAllStoryType', storyData);

    const rules = {
      title: { required: helpers.withMessage('Please Enter the title', required) },
      isbn: { required: helpers.withMessage('Please Enter the isbn', required) },
      type: { required: helpers.withMessage('Please select a type', required) },
      language: { required: helpers.withMessage('Please select a language', required) },
      audio: { required: helpers.withMessage('Please select a audio', required) },

    };

    const v$ = useVuelidate(rules, storyUpload);

    store.dispatch('fetchStoryShieldStories').then((response) => {
      isbnStories.value = response.data.items.filter((item) => item.isbn !== null);
      pageCount.value = Math.ceil(response.data.total / perPage.value);

      isLoading.value = false;
    });

    const searchUser = ref('');

    const searchUserData = {
      searchUser,
      currentOffset,
      perPageUser,
    };

    store.dispatch('fetchAllUsers', searchUserData);

    const langData = {
      searchLanguage,
      currentOffset,
      langPerPage,
    };

    store.dispatch('fetchAllLanguages', langData);

    const storyById = computed(() => store.getters.getStoryById);
    const stories = computed(() => store.getters.getAllStoriesWithOutLimt);
    const user = computed(() => store.getters.getAllUsers);
    const storyType = computed(() => store.getters.getAllStoryType);
    const language = computed(() => store.getters.getLanguage);

    onMounted(() => {
      audioFileBtn = document.getElementById('audio-file');
      imageFileBtn = document.getElementById('image-file');
    });

    const audioListener = () => {
      if (audioFileBtn.value) {
        const file = audioFileBtn.files[0];
        storyUpload.audio = file;
        const blob = new Blob([file], { type: file.type });
        audioURL.value = URL.createObjectURL(blob);
      } else {
        console.log('no file selected');
      }
    };

    const imageListener = () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.files[0];
        storyUpload.image = file;
        const blob = new Blob([file], { type: file.type });
        imageURL.value = URL.createObjectURL(blob);
      } else {
        console.log('no file selected');
      }
    };

    const audioFile = () => {
      audioFileBtn.click();
      audioFileBtn.addEventListener('change', audioListener);
    };

    const imageFile = () => {
      imageFileBtn.click();
      imageFileBtn.addEventListener('change', imageListener);
    };

    const uploadStory = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        const formData = new FormData();
        formData.append('title', storyUpload.title);
        // formData.append('description', storyUpload.description);
        formData.append('type', storyUpload.type);
        if (storyUpload.owner.id) {
          formData.append('owner', storyUpload.owner.id);
        }

        formData.append('image', storyUpload.image);
        formData.append('file', storyUpload.audio);
        if (storyUpload.isbn) {
          formData.append('isbn', storyUpload.isbn);
        }
        if (storyUpload.parent) {
          formData.append('parent', storyUpload.parent.isbn);
        }
        formData.append('language', storyUpload.language);
        storyService.storyUpload(formData).then((res) => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          successMsg.value = ' Story Has Been Added Successfully';
          setTimeout(() => {
            successMsg.value = '';
            router.push('/stories').then(() => { router.go(); });
          }, 3000);
          owner.value = '';
          isLoading.value = false;
        })
          .catch((error) => {
            isLoading.value = false;
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };

    return {
      storyType,
      stories,
      user,
      store,
      audioFile,
      imageFile,
      storyUpload,
      uploadStory,
      isLoading,
      storyById,
      imageURL,
      audioURL,
      storyId,
      searchStory,
      currentOffset,
      perPage,
      pageCount,
      page,
      language,
      routePath,
      owner,
      successMsg,
      errorMsg,
      v$,
      isbnStories,
    };
  },

};
</script>
