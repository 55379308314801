<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1>FirmWare</h1>
            </div>
            <!-- <div class="col text-end">

            </div> -->
            <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
              <div class="row justify-content-end">
                <div class="col-auto">
                  <label for="inputPassword2" class="visually-hidden">Search</label>
                  <input
                    type="text"
                    class="form-control rounded-5"
                    placeholder="Search"
                    v-model="searchFirm"
                    v-on:keyup.enter="searchClick()"
                  />
                </div>
                <div class="col-auto tooltip-wrap">
                  <button
                    type="submit"
                    class="btn btn-outline-primary mb-3 rounded-5"
                    @click="searchClick()"
                  >
                    <i class="bi bi-search"></i>
                    <span class="tooltip">Search Here</span>
                  </button>
                </div>
                <div class="col-auto tooltip-wrap">
                  <router-link
                    to="/add-firmware"
                    class="btn btn-outline-success me-3 rounded-5"
                    ><i class="bi bi-database-add"></i>
                    <span class="tooltip">Add New</span>
                  </router-link>

                </div>
              </div>
            </div>
            <div class="card shadow-sm">
              <div class="card-body">
                <div class="card-text">
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>UUID</th>
                          <th>Version</th>
                          <th>File</th>
                          <th>Selected Latest</th>
                          <th>Edit</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="(item, index) in firmWareDetails .items" :key="index">
                          <td>{{ item.id }}</td>
                          <td>{{ item.version }}</td>
                          <td><a :href=item.file>{{ item.file }}</a></td>
                          <td>{{ item.selected_latest }}</td>
                          <td>
                          <button
                            class="btn btn-outline-primary bi bi-pencil-square rounded-5"
                            @click="goToDetails(item.id)"
                          ></button>
                        </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <VuejsPaginateNext
                v-model="page"
                :page-count="pageCount"
                :click-handler="pageClick"
                :margin-pages="2"
                :page-range="3"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
              ></VuejsPaginateNext>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script>
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import FirmWareService from '@/services/CmsPageService';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';
import { useRouter } from 'vue-router';

const firmWareService = new FirmWareService();

export default {
  name: 'FirmWare',
  components: {
    DashboardTemplate,
    VuejsPaginateNext,

  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const searchFirm = ref('');
    const currentOffset = ref(0);
    const pageCount = ref(0);
    const perPage = ref(10);
    const page = ref(1);
    const SearchData = {
      searchFirm,
      currentOffset,
      perPage,
    };
    store.dispatch('fetchAllFirmWarelists', SearchData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / perPage.value);
    });
    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchFirm,
        currentOffset,
        perPage,
      };
      store.dispatch('fetchAllFirmWarelists', search).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);
      });
    };
    const pageClick = (pageNum) => {
      currentOffset.value = perPage.value * (pageNum - 1);
      const Data = {
        searchFirm,
        currentOffset,
        perPage,
      };
      store.dispatch('fetchAllFirmWarelists', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);
      });
    };
    const goToDetails = (id) => {
      store.dispatch('fetchFirmById', id).then(() => {
        router.push({ name: 'FirmWareDetail', params: { id } });
      });
    };
    const firmWareDetails = computed(() => store.getters.getFirmWareLists);
    const firmwareListDetailsById = computed(() => store.getters.getFirmWareListsById);

    return {
      firmWareDetails,
      firmwareListDetailsById,
      searchFirm,
      goToDetails,
      searchClick,
      pageClick,
      page,
      pageCount,
    };
  },
};
</script>
