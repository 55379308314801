<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-shield-check"></i> Add New Shield</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to add shield"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="shieldHash" class="form-label">Shield Hash</label>
                    <input
                      type="text"
                      class="form-control"
                      id="shieldHash"
                      v-model="shieldUpload.shieldHash"
                    />
                    <span class="text-danger" v-if="v$.shieldHash.$error">
                      {{ v$.shieldHash.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="inputType" class="form-label">Type</label>

                    <select id="inputType" class="form-select" v-model="shieldUpload.type">
                      <!-- <option value="">select the Type</option> -->
                      <option
                        v-for="(type, index) in shieldTypes.items"
                        :key="index"
                        :value="type.slug"
                      >
                        {{ type.slug }}
                      </option>
                    </select>
                    <span class="text-danger" v-if="v$.type.$error">
                      {{ v$.type.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="inputPlaylist" class="form-label">Playlist</label>
                    <!-- <select id="inputPlaylist" class="form-select" v-model="shieldUpload.playlist">
                      <option
                        v-for="(playlist, index) in playlists.items"
                        :key="index"
                        :value="playlist.id"
                      >
                        {{ playlist.title }} ({{ playlist.id }})
                      </option>
                    </select> -->
                    <VueMultiselect
                      v-if="playlists.items"
                      v-model="shieldUpload.playlist"
                      :options="playlists.items"
                      :close-on-select="true"
                      :clear-on-select="false"
                      placeholder="Select one playlist"
                      :custom-label="customLabel"
                      track-by="id"
                    />
                    <span class="text-danger" v-if="v$.playlist.$error">
                      {{ v$.playlist.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="inputOwner" class="form-label">Owner</label>

                    <VueMultiselect
                      v-if="users.items"
                      v-model="shieldUpload.owner"
                      :options="users.items"
                      :close-on-select="true"
                      :clear-on-select="false"
                      placeholder="Select one owner"
                      label="email"
                      track-by="email"
                    />

                  </div>
                </div>
                <div class="container text-end mt-4 mb-3">
                  <button
                    type="button"
                    @click="createShield()"
                    class="btn btn-outline-success me-2"
                  >
                    Add
                  </button>
                  <router-link to="/shields" type="button" class="btn btn-outline-danger"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import {
  required, email, helpers, between,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';

import ShieldService from '@/services/ShieldService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const shieldService = new ShieldService();

export default {
  name: 'AddNewShield',
  props: [],
  components: {
    Loader,
    DashboardTemplate,
    VueMultiselect,
    ErrorDisplay,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false);
    const getId = ref('');
    const searchShield = ref('');
    const searchPlaylist = ref('');
    const searchShieldType = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const perPageShield = ref(0);
    const perPageUser = ref(0);
    const pageCount = ref(0);
    const page = ref(1);
    const routePath = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const playlistPerPage = ref(0);

    routePath.value = route.path;

    const owner = ref('');
    const shieldUpload = reactive({
      shieldHash: '',
      type: '',
      owner: '',
      playlist: '',

    });

    const rules = {
      shieldHash: { required: helpers.withMessage('Please enter shield hash', required) },
      type: { required: helpers.withMessage('Please select a type', required) },
      playlist: { required: helpers.withMessage('Please select a playlist', required) },
    };

    const v$ = useVuelidate(rules, shieldUpload);

    const SearchData = {
      searchShield,
      currentOffset,
      perPage,
    };
    store.dispatch('fetchAllShields', SearchData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / perPage.value);

      isLoading.value = false;
    });

    const shieldData = {
      searchShieldType,
      currentOffset,
      perPageShield,
    };

    store.dispatch('fetchAllShieldType', shieldData);

    const searchUser = ref('');

    const searchUserData = {
      searchUser,
      currentOffset,
      perPageUser,
    };

    store.dispatch('fetchAllUsers', searchUserData);

    const PlaylistData = {
      searchPlaylist,
      currentOffset,
      playlistPerPage,
    };
    store.dispatch('fetchAllPlaylists', PlaylistData);

    const shields = computed(() => store.getters.getAllShield);

    const shieldTypes = computed(() => store.getters.getAllShieldType);

    const users = computed(() => store.getters.getAllUsers);

    const playlists = computed(() => store.getters.getPlaylists);

    const shieldById = computed(() => store.getters.getShieldById);

    const createShield = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;

        const Data = {
          shield_hash: shieldUpload.shieldHash,
          type: shieldUpload.type,
          playlist: shieldUpload.playlist.id,
          owner: shieldUpload.owner.id,
        };
        shieldService.createShield(Data).then(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          isLoading.value = false;
          successMsg.value = ' Shield Has Been Added Successfully';
          setTimeout(() => {
            successMsg.value = '';
            router.push('/shields');
          }, 3000);
        })
          .catch((error) => {
            isLoading.value = false;
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };

    const customLabel = ({ title, gtin }) => `${title} – ${gtin}`;

    return {
      isLoading,
      shields,
      shieldUpload,
      shieldTypes,
      users,
      playlists,
      createShield,
      shieldById,
      getId,
      searchShield,
      searchPlaylist,
      currentOffset,
      perPage,
      pageCount,
      page,
      routePath,
      owner,
      v$,
      successMsg,
      errorMsg,
      customLabel,
    };
  },
};
</script>
