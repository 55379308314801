<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-headphones"></i> HeadPhones</h1>
            </div>
            <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
              <div class="row justify-content-end">
                <div class="col">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Select Deleted or Active Items Here"
                  >
                    <router-link
                      to="/headphones"
                      class="btn btn-outline-success rounded-start-5 tooltip-wrap"
                      :class="{ active: routePath === '/headphones' }"
                    >
                      <i class="bi bi-radioactive"></i>
                      Active
                      <span class="tooltip">Active Items</span>
                    </router-link>
                    <router-link
                      to="/deleted-headphone"
                      class="btn btn-outline-danger rounded-end-5 tooltip-wrap"
                      :class="{ active: routePath === '/deleted-headphone' }"
                    >
                      <i class="bi bi-trash-fill"></i>
                      Deleted
                      <span class="tooltip">Deleted Items</span>
                    </router-link>
                  </div>
                </div>
                <div class="col-auto">
                  <label for="inputPassword2" class="visually-hidden">Search</label>
                  <input
                    type="text"
                    class="form-control rounded-5"
                    placeholder="Search"
                    v-model="searchStoryPhone"
                    v-on:keyup.enter="searchClick()"
                  />
                </div>
                <div class="col-auto tooltip-wrap">
                  <button
                    type="submit"
                    class="btn btn-outline-primary mb-3 rounded-5"
                    @click="searchClick()"
                  >
                    <i class="bi bi-search"></i>
                    <span class="tooltip">Search Here</span>
                  </button>
                </div>
                <div class="col-auto">
                  <router-link
                    to="/add-headphone"
                    class="btn btn-outline-success me-3 rounded-5 tooltip-wrap"
                  >
                    <i class="bi bi-database-add"></i>
                    <span class="tooltip">Add New</span>
                  </router-link>
                  <a
                    href="#"
                    class="btn btn-outline-secondary me-3 rounded-5 tooltip-wrap"
                    data-bs-toggle="modal"
                    data-bs-target="#HeadPhoneUploadModal"
                    ><i class="bi bi-upload"></i>
                    <span class="tooltip">Bulk Upload</span>
                  </a>
                  <a
                    href="#"
                    @click="downloadCSV"
                    class="btn btn-outline-secondary me-1 rounded-5 tooltip-wrap"
                    ><i class="bi bi-download"></i>
                    <span class="tooltip">Download CSV</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="something went wrong"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>UUID</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Device ID</th>
                        <th>Owner</th>
                        <th>Model</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="storyPhones?.total === 0">
                        <td scope="col" colspan="9" class="text-center text-primary pt-3 pb-3">
                          Not Found
                        </td>
                      </tr>

                      <tr v-else v-for="(storyPhone, index) in storyPhones.items" :key="index">
                        <td>{{ storyPhone.id }}</td>
                        <td v-if="storyPhone.details">
                          <img :src="storyPhone.details.model_image" style="width: 50px" alt="" />
                        </td>
                        <td v-else>No Image</td>
                        <td>{{ storyPhone.name }}</td>
                        <td>{{ storyPhone.device_id }}</td>
                        <td v-if="storyPhone.owner">
                          {{ storyPhone.owner.email }}
                        </td>
                        <td v-else>Not Assigned</td>
                        <td>{{ storyPhone.details?.model_name || 'No Model Name' }}</td>

                        <td>
                          <button
                            class="btn btn-outline-primary bi bi-pencil-square rounded-5"
                            @click="getStoryPhoneById(storyPhone.id)"
                          ></button>
                        </td>
                        <td>
                          <button
                            class="btn btn-outline-danger bi bi-trash rounded-5"
                            data-bs-toggle="modal"
                            data-bs-target="#storyPhoneDeleteModal"
                            @click="deletePopUp(storyPhone.id)"
                          ></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <VuejsPaginateNext
              v-model="page"
              :page-count="pageCount"
              :click-handler="pageClick"
              :margin-pages="2"
              :page-range="3"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
            ></VuejsPaginateNext>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>

  <!-- delete Modal -->
  <div
    class="modal fade"
    id="storyPhoneDeleteModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="storyPhoneDeleteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="storyPhoneDeleteModalLabel">Are You Sure ?</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">Do you want to delete? It can be retrieved.</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
            @click="storyPhoneDelete()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- upload Modal -->
  <div
    class="modal fade"
    id="HeadPhoneUploadModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="HeadPhoneUploadModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="HeadPhoneUploadModalLabel">Upload File</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="clearUpload"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container text-end">
            <a
              href="/csv/Sample_Headphone_Data.csv"
              class="btn btn-outline-primary"
              download="Sample_Headphone_Data.csv"
            >
              Sample CSV
            </a>
          </div>
          <label class="form-label"> {{ fileName }}</label>
          <div class="custom-file mt-4">
            <input
              type="file"
              class="form-control"
              id="upload-file"
              accept=".csv"
              hidden="hidden"
            />
            <button
              class="btn btn-primary form-control"
              type="button"
              id="custom-button"
              @click="fileUpload()"
            >
              <span>Upload <i class="bi bi-upload" aria-hidden="true"></i></span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="clearUpload"
          >
            Cancel
          </button>
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
            @click="headPhoneBulkUpload()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';

import { formatUTC } from '@/helper/CommonHelper';

import UserService from '@/services/UserService';
import StoryPhoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const storyPhoneService = new StoryPhoneService();
const userService = new UserService();

let imageFileBtn;
let editImageFileBtn;
let fileUploadBtn;

export default {
  name: 'StoryPhone',
  props: [],
  components: {
    Loader,
    DashboardTemplate,
    VuejsPaginateNext,
    ErrorDisplay,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const routePath = ref('');
    const router = useRouter();
    routePath.value = route.path;
    const isLoading = ref(false);
    const owner = ref('');
    const searchStoryPhone = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const delErrorMsg = ref('');
    const storyPhoneId = ref('');
    const uploadFile = ref('');
    const fileName = ref('');
    const currentOffset = ref(0);
    const perPageStoryphones = ref(10);
    const perPageUser = ref(0);
    const pageCount = ref(0);
    const page = ref(1);
    isLoading.value = true;
    const SearchData = {
      searchStoryPhone,
      currentOffset,
      perPageStoryphones,
    };
    store.dispatch('fetchAllStoryPhone', SearchData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / perPageStoryphones.value);

      isLoading.value = false;
    });

    // userService.getUserDetails().then((res) => {
    //   user.value = res.data;
    // });
    const searchUser = ref('');

    // const searchUserData = {
    //   searchUser,
    //   currentOffset,
    //   perPageUser,
    // };
    // store.dispatch('fetchAllUsers', searchUserData);

    // const user = computed(() => store.getters.getAllUsers);
    const storyPhones = computed(() => store.getters.getAllStoryPhone);
    const storyPhonesDetals = computed(() => store.getters.getStoryPhoneById);

    const getStoryPhoneById = (id) => {
      router.push({ name: 'edit-headphone', params: { id } });
    };

    const deletePopUp = (id) => {
      storyPhoneId.value = id;
    };

    const storyPhoneDelete = () => {
      isLoading.value = true;
      storyPhoneService.storyPhoneDelete(storyPhoneId.value).then((res) => {
        store.dispatch('fetchAllStoryPhone', SearchData).then((response) => {
          pageCount.value = Math.ceil(response.data.total / perPageStoryphones.value);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          successMsg.value = res?.data?.message;
          setTimeout(() => {
            successMsg.value = '';
          }, 3000);
          isLoading.value = false;
        });
      }).catch((error) => {
        isLoading.value = false;
        errorMsg.value = error?.data;
        setTimeout(() => {
          errorMsg.value = '';
        }, 3000);
      });
    };

    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchStoryPhone,
        currentOffset,
        perPageStoryphones,
      };
      isLoading.value = true;
      store.dispatch(
        'fetchAllStoryPhone',
        search,
      ).then((response) => {
        isLoading.value = false;
        pageCount.value = Math.ceil(response.data.total / perPageStoryphones.value);
      });
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;

      currentOffset.value = perPageStoryphones.value * (pageNum - 1);
      const Data = {
        searchStoryPhone,
        currentOffset,
        perPageStoryphones,
      };
      store.dispatch('fetchAllStoryPhone', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPageStoryphones.value);

        isLoading.value = false;
      });
    };

    onMounted(() => {
      fileUploadBtn = document.getElementById('upload-file');
    });

    const downloadCSV = () => {
      isLoading.value = true;
      storyPhoneService.storyPhoneDownload().then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'HeadPhoneData.csv';
        link.click();
        window.URL.revokeObjectURL(url);
        isLoading.value = false;
      });
    };
    const clearUpload = () => {
      fileName.value = '';
      uploadFile.value = '';
    };

    const uploadListener = () => {
      if (fileUploadBtn.value) {
        const file = fileUploadBtn.files[0];
        fileName.value = file.name;
        uploadFile.value = file;
        fileUploadBtn.value = '';
      } else {
        console.log('no file selected');
      }
    };
    const fileUpload = () => {
      fileUploadBtn = document.getElementById('upload-file');
      fileUploadBtn.click();
      fileUploadBtn.addEventListener('change', uploadListener);
    };

    const headPhoneBulkUpload = () => {
      if (uploadFile.value) {
        isLoading.value = true;
        const formData = new FormData();
        formData.append('csv_file', uploadFile.value);
        storyPhoneService.headPhoneBulkUpload(formData).then((res) => {
          store.dispatch('fetchAllStoryPhone', SearchData).then((response) => {
            pageCount.value = Math.ceil(response.data.total / perPageStoryphones.value);
            uploadFile.value = '';
            isLoading.value = false;
            fileName.value = '';
            successMsg.value = res.data.message;
            setTimeout(() => {
              successMsg.value = '';
            }, 3000);
          });
        }).catch((error) => {
          console.log(error);
          isLoading.value = false;
          errorMsg.value = error?.data;
          fileName.value = '';
          uploadFile.value = '';
          setTimeout(() => {
            errorMsg.value = '';
          }, 3000);
        });
      } else {
        console.log('');
      }
    };

    return {
      isLoading,
      storyPhones,
      // user,
      getStoryPhoneById,
      storyPhoneId,
      deletePopUp,
      storyPhoneDelete,
      storyPhonesDetals,
      formatUTC,
      searchStoryPhone,
      searchClick,
      currentOffset,
      pageCount,
      page,
      pageClick,
      routePath,
      owner,
      successMsg,
      errorMsg,
      delErrorMsg,
      downloadCSV,
      clearUpload,
      uploadFile,
      fileName,
      fileUpload,
      headPhoneBulkUpload,
    };
  },
};
</script>
