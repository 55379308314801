import CmsStoryService from '@/services/CmsStoryService';
import * as types from './mutation-types';

const ent = require('ent');

const cmsStoryService = new CmsStoryService();

export const fetchStoryTypes = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cmsStoryService
      .getStoryTypes(Data)
      .then((response) => {
        commit(types.STORY_TYPES, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchCmsAllStories = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cmsStoryService
      .getAllCmsStories(Data)
      .then((response) => {
        commit(types.STORIES, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

// export const fetchStoryById = ({ commit }, Data) => new Promise(
//   (resolve, reject) => {
//     cmsStoryService
//       .getStoryDetailsById(Data)
//       .then((response) => {
//         commit(types.STORY_BY_ID, response.data);
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   },
// );
