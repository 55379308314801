/* eslint-disable indent */
/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const getAllUsers = (state) => isObj(state, 'user', []);
export const getUser = (state) => isObj(state, 'singleUser', []);
export const getUserGroups = (state) => isObj(state, 'userGroups', []);
export const getUserProfile = (state) => isObj(state, 'userProfile', []);
export const getDeletedUsers = (state) => isObj(state, 'deletedUser', []);
