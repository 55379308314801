/* eslint-disable no-undef */
import * as types from './mutation-types';

const mutations = {
  [types.CMSLIST_DETAILS](state, cmsLists) {
    state.cmsLists = cmsLists;
  },
  [types.CMSLIST_DETAILS_BY_ID](state, cmslistdetailsById) {
    state.cmslistdetailsById = cmslistdetailsById;
  },

};
export default mutations;
