<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-shield-check"></i> Shields</h1>
            </div>
            <!-- <div class="col text-end">
              <router-link to="/shields" class="text-danger"
                ><i class="bi bi-trash-fill"> </i>Deleted Shields</router-link
              >
            </div> -->
            <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
              <div class="row justify-content-end">
                <div class="col">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Select Deleted or Active Items Here"
                  >
                    <router-link
                      to="/shields"
                      class="btn btn-outline-success rounded-start-5 tooltip-wrap"
                      :class="{ active: routePath === '/shields' }"
                    >
                      <i class="bi bi-radioactive"></i>
                      Active
                      <span class="tooltip">Active Items</span>
                    </router-link>
                    <router-link
                      to="/deleted-shield"
                      class="btn btn-outline-danger rounded-end-5 tooltip-wrap"
                      :class="{ active: routePath === '/deleted-shield' }"
                    >
                      <i class="bi bi-trash-fill"></i>
                      Deleted
                      <span class="tooltip">Deleted Items</span>
                    </router-link>
                  </div>
                </div>
                <div class="col-auto">
                  <label for="inputPassword2" class="visually-hidden">Search</label>
                  <input
                    type="text"
                    class="form-control rounded-5"
                    placeholder="Search"
                    v-model="searchShield"
                    v-on:keyup.enter="searchClick()"
                  />
                </div>
                <div class="col-auto tooltip-wrap">
                  <button
                    type="submit"
                    class="btn btn-outline-primary mb-3 rounded-5"
                    @click="searchClick()"
                  >
                    <i class="bi bi-search"></i>
                    <span class="tooltip">Search Here</span>
                  </button>
                </div>
                <div class="col-auto">
                  <router-link
                    to="/add-shield"
                    class="btn btn-outline-success me-3 rounded-5 tooltip-wrap"
                  >
                    <i class="bi bi-database-add"></i>
                    <span class="tooltip">Add Shield</span>
                  </router-link>

                  <a
                    href="#"
                    class="btn btn-outline-secondary me-3 rounded-5 tooltip-wrap"
                    data-bs-toggle="modal"
                    data-bs-target="#ShieldUploadModal"
                    ><i class="bi bi-upload"></i>
                    <span class="tooltip">Bulk Upload</span>
                  </a>
                  <a
                    href="#"
                    @click="downloadCSV"
                    class="btn btn-outline-secondary me-1 rounded-5 tooltip-wrap"
                    ><i class="bi bi-download"></i>
                    <span class="tooltip">Download CSV</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to add shield"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>UUID</th>
                        <th>Shield Hash</th>
                        <!-- <th>title</th> -->
                        <!-- <th>Completed Assignment</th> -->
                        <th>Type</th>
                        <th>Playlist</th>
                        <th>Owner</th>
                        <!-- <th>line_item</th> -->
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="shields?.total === 0">
                        <td scope="col" colspan="8" class="text-center text-primary pt-3 pb-3">
                          Not Found
                        </td>
                      </tr>

                      <tr v-else v-for="(shield, index) in shields.items" :key="index">
                        <td>{{ shield.id }}</td>
                        <td>{{ shield.shield_hash }}</td>
                        <!-- <td>{{ shield.title }}</td> -->
                        <!-- <td>{{ shield.completed_assignment }}</td> -->
                        <td v-if="shield.type">{{ shield.type.slug }}</td>
                        <td v-else>Not Assigned</td>
                        <!-- <td>{{ shield.playlist }}</td> -->
                        <td v-if="shield.playlist">{{ shield.playlist.title }}</td>
                        <td v-else>Not Assigned</td>
                        <td v-if="shield.owner">{{ shield.owner.email }}</td>
                        <td v-else>Not Assigned</td>
                        <!-- <td>{{ shield.line_item }}</td> -->
                        <td>
                          <button
                            class="btn bi bi-pencil-square btn-outline-primary rounded-5"
                            @click="getShieldData(shield.id)"
                          ></button>
                        </td>
                        <td>
                          <button
                            class="btn btn-outline-danger bi bi-trash rounded-5"
                            data-bs-toggle="modal"
                            data-bs-target="#ShieldDeleteModal"
                            @click="deletePopUp(shield.id)"
                          ></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <VuejsPaginateNext
              v-model="page"
              :page-count="pageCount"
              :click-handler="pageClick"
              :margin-pages="2"
              :page-range="3"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
            ></VuejsPaginateNext>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>

  <!-- delete Modal -->
  <div
    class="modal fade"
    id="ShieldDeleteModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ShieldDeleteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ShieldDeleteModalLabel">Are You Sure ?</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">Do you Want to Delete? It can be retrieved.</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
            @click="deleteShield()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- upload Modal -->
  <div
    class="modal fade"
    id="ShieldUploadModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ShieldUploadModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ShieldUploadModalLabel">Upload File</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="clearUpload"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container text-end">
            <a
              href="/csv/Sample_Shield_Data.csv"
              class="btn btn-outline-primary"
              download="Sample_Shield_Data.csv"
            >
              Sample CSV
            </a>
          </div>
          <div class="col-md-6">
            <label for="inputType" class="form-label">Type</label>
            <select id="inputType" class="form-select" v-model="uploadShieldType">
              <option value="">select the Type</option>
              <option
                v-for="(type, index) in shieldTypes.items"
                :key="index"
                :value="type.slug"
              >
                {{ type.slug }}
              </option>
            </select>
            <span class="text-danger" v-if="v$.uploadShieldType.$error">
              {{ v$.uploadShieldType.$errors[0].$message }}*
            </span>
          </div>
          <label v-if="fileName" class="form-label mt-4"> {{ fileName }}</label>
          <div class="custom-file mt-4">
            <input
              type="file"
              class="form-control"
              id="upload-file"
              accept=".csv"
              hidden="hidden"
            />
            <button
              class="btn btn-primary form-control"
              type="button"
              id="custom-button"
              @click="fileUpload()"
            >
              <span>Upload <i class="bi bi-upload" aria-hidden="true"></i></span>
            </button>
            <span class="text-danger" v-if="v$.uploadFile.$error">
              {{ v$.uploadFile.$errors[0].$message }}*
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="clearUpload"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="shieldBulkUpload()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';

import Loader from '@/components/commons/Loader.vue';
import ShieldService from '@/services/ShieldService';
import {
  required, helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { Modal } from 'bootstrap';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const shieldService = new ShieldService();

let fileUploadBtn;
export default {

  name: 'About',
  props: [],
  components: {
    Loader,
    DashboardTemplate,
    VuejsPaginateNext,
    ErrorDisplay,
    // VueMultiselect,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false);
    const getId = ref('');
    const searchShield = ref('');
    const searchPlaylist = ref('');
    const searchShieldType = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const perPageShield = ref(0);
    const perPageUser = ref(0);
    const pageCount = ref(0);
    const page = ref(1);
    const routePath = ref('');
    const uploadFile = ref('');
    const uploadShieldType = ref('');
    const fileName = ref('');
    routePath.value = route.path;
    const successMsg = ref('');
    const errorMsg = ref('');
    onMounted(() => {

    });

    const rules = computed(() => ({
      uploadShieldType: { required: helpers.withMessage('Please select a type', required) },
      uploadFile: { required: helpers.withMessage('Please upload a csv file', required) },
    }));
    const shieldUpload = {
      uploadShieldType,
      uploadFile,
    };
    const v$ = useVuelidate(rules, shieldUpload);

    isLoading.value = true;
    const SearchData = {
      searchShield,
      currentOffset,
      perPage,
    };
    store.dispatch('fetchAllShields', SearchData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / perPage.value);

      isLoading.value = false;
    });
    const shieldTypeData = {
      searchShieldType,
      currentOffset,
      perPageShield,
    };
    store.dispatch('fetchAllShieldType', shieldTypeData);

    const shields = computed(() => store.getters.getAllShield);
    const shieldTypes = computed(() => store.getters.getAllShieldType);

    const getShieldData = (id) => {
      router.push({ name: 'edit-shield', params: { id } });
    };

    const deletePopUp = (id) => {
      getId.value = id;
    };

    const deleteShield = () => {
      isLoading.value = true;
      shieldService.shieldDelete(getId.value).then((res) => {
        store.dispatch('fetchAllShields', SearchData).then((response) => {
          pageCount.value = Math.ceil(response.data.total / perPage.value);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          successMsg.value = res?.data?.message;
          setTimeout(() => {
            successMsg.value = '';
          }, 3000);
          isLoading.value = false;
          getId.value = '';
        });
      }).catch((error) => {
        isLoading.value = false;
        errorMsg.value = error?.data;
        setTimeout(() => {
          errorMsg.value = '';
        }, 3000);
      });
    };

    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchShield,
        currentOffset,
        perPage,
      };
      isLoading.value = true;
      store.dispatch('fetchAllShields', search).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);

        isLoading.value = false;
      });
    };
    const pageClick = (pageNum) => {
      isLoading.value = true;

      currentOffset.value = perPage.value * (pageNum - 1);
      const Data = {
        searchShield,
        currentOffset,
        perPage,
      };

      store.dispatch('fetchAllShields', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);

        isLoading.value = false;
      });
    };

    const uploadListener = () => {
      if (fileUploadBtn.value) {
        const file = fileUploadBtn.files[0];
        fileName.value = file.name;
        uploadFile.value = file;
        fileUploadBtn.value = '';
      } else {
        console.log('no file selected');
      }
    };
    const fileUpload = () => {
      fileUploadBtn = document.getElementById('upload-file');
      fileUploadBtn.click();
      fileUploadBtn.addEventListener('change', uploadListener);
    };

    const shieldBulkUpload = async () => {
      const result = await v$.value.$validate();
      if (!result) return;
      const modal = Modal.getOrCreateInstance(document.getElementById('ShieldUploadModal'));
      modal.hide();
      v$.value.$reset();
      isLoading.value = true;
      const formData = new FormData();
      formData.append('csv_file', uploadFile.value);
      formData.append('type', uploadShieldType.value);
      shieldService.shieldBulkUpload(formData).then((res) => {
        store.dispatch('fetchAllShields', SearchData).then((response) => {
          pageCount.value = Math.ceil(response.data.total / perPage.value);
          uploadFile.value = '';
          uploadShieldType.value = '';
          isLoading.value = false;
          fileName.value = '';
          successMsg.value = res.data.message;
          setTimeout(() => {
            successMsg.value = '';
          }, 3000);
        });
      }).catch((error) => {
        isLoading.value = false;
        errorMsg.value = error?.data;
        fileName.value = '';
        uploadFile.value = '';
        uploadShieldType.value = '';
        setTimeout(() => {
          errorMsg.value = '';
        }, 3000);
      });
    };

    const clearUpload = () => {
      fileName.value = '';
      uploadFile.value = '';
      uploadShieldType.value = '';
      v$.value.$reset();
    };

    const downloadCSV = () => {
      isLoading.value = true;
      shieldService.downloadShieldCSV().then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'ShieldsData.csv';
        link.click();
        window.URL.revokeObjectURL(url);
        isLoading.value = false;
      });
    };

    return {
      isLoading,
      shields,
      getShieldData,
      getId,
      deletePopUp,
      deleteShield,
      searchShield,
      searchClick,
      searchPlaylist,
      currentOffset,
      perPage,
      pageCount,
      page,
      pageClick,
      routePath,
      uploadFile,
      fileUpload,
      shieldBulkUpload,
      successMsg,
      errorMsg,
      clearUpload,
      downloadCSV,
      fileName,
      shieldTypes,
      uploadShieldType,
      v$,
    };
  },
};
</script>
