import StoryService from '@/services/StoryService';
import * as types from './mutation-types';

const ent = require('ent');

const storyService = new StoryService();

export const fetchStoryTypes = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    storyService
      .getStoryTypes(Data)
      .then((response) => {
        commit(types.STORY_TYPES, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchAllStories = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    storyService
      .getAllStories(Data)
      .then((response) => {
        commit(types.STORIES, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchStoryById = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    storyService
      .getStoryDetailsById(Data)
      .then((response) => {
        commit(types.STORY_BY_ID, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchDeletedStories = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    storyService
      .getAllDeletedStories(Data)
      .then((response) => {
        commit(types.DELETED_STORIES, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchStoriesWithOutlimit = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    storyService
      .getAllWithOutLimtStories(Data)
      .then((response) => {
        commit(types.STORY_WITH_OUT_LIMIT, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchStoryShieldStories = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    storyService
      .getStoryShieldStories(Data)
      .then((response) => {
        commit(types.STORY_WITH_OUT_LIMIT, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
