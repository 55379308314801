/* eslint-disable import/extensions */
import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

export default {
  state() {
    return {
      storyType: [],
      stories: [],
      story: [],
      deletedStories: [],
      storyWithOutLimit: [],
    };
  },
  mutations,
  actions,
  getters,
};
