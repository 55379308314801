/* eslint-disable indent */
import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });

export default class ShieldService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getAllShields(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.searchShield.value) {
         apiEndPoint = `${this.baseURL}v1/shields/for-dashboard/?search=${term.searchShield.value}&orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}`;
      } else {
         apiEndPoint = `${this.baseURL}v1/shields/for-dashboard/?orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}`;
      }
      // const apiEndPoint = `${this.baseURL}v1/shields/?limit=10&orderBy=-updated_at`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  createShield(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/shields/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getShieldById(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/shields/${id}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  updateShield(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/shields/${id}`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  shieldDelete(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/shields/${id}/`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getAllDeletedShields(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.searchShield.value) {
         apiEndPoint = `${this.baseURL}v1/shields/for-dashboard/?search=${term.searchShield.value}&orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}&deleted_only=true`;
      } else {
         apiEndPoint = `${this.baseURL}v1/shields/for-dashboard/?orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}&deleted_only=true`;
      }
      // const apiEndPoint = `${this.baseURL}v1/shields/?limit=10&orderBy=-updated_at`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  restoreShield(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/shields/restore/${id}`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  shieldBulkUpload(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/shields/bulk-upload`;
      this.http
      .post(apiEndPoint, data, {
        headers: {
          // eslint-disable-next-line no-underscore-dangle
          'Content-Type': 'multipart/form-data;',
          Authorization: `Bearer ${this.getUserToken()}`,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  downloadShieldCSV(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/shields/csv-download`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
}
