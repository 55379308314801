import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });

export default class AuthenticationService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  login(credentials) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/login/admin`;
      this.http
        .post(apiEndPoint, credentials)
        .then((response) => {
          cookies.set('storyPhoneAdminCookie', {
            access_token: response.data.accessToken,
          });
          cookies.set('storyPhoneAdminRefreshCookie', {
            refresh_token: response.data.refreshToken,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  verifyToken(credentials) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/jwt-verify`;
      this.http
        .post(apiEndPoint, credentials)
        .then((response) => {
          // cookies.set('storyPhoneAdminCookie', {
          //   access_token: response.data.accessToken,
          // });
          // cookies.set('storyPhoneAdminRefreshCookie', {
          //   refresh_token: response.data.refreshToken,
          // });
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
}
