/* eslint-disable indent */
import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });

export default class TypesService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getAllPlaylistTypes(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.searchPlaylistType.value) {
         apiEndPoint = `${this.baseURL}v1/playlist-types/?search=${term.searchPlaylistType.value}&orderBy=-updated_at&limit=${term.perPagePlaylist.value}&offset=${term.currentOffset.value}`;
      } else {
         apiEndPoint = `${this.baseURL}v1/playlist-types/?orderBy=-updated_at&limit=${term.perPagePlaylist.value}&offset=${term.currentOffset.value}`;
      }
      // const apiEndPoint = `${this.baseURL}v1/playlist-types/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  playlistTypeUpload(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/playlist-types/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  playlistTypeDetailsById(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/playlist-types/${id}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  playlistTypeUpdate(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/playlist-types/${id}/`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  playlistTypeDelete(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/playlist-types/${id}/`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getAllStoriesTypes(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.searchStoryType.value) {
         apiEndPoint = `${this.baseURL}v1/story-types/?search=${term.searchStoryType.value}&orderBy=-updated_at&limit=${term.perPageStory.value}&offset=${term.currentOffset.value}`;
      } else {
         apiEndPoint = `${this.baseURL}v1/story-types/?orderBy=-updated_at&limit=${term.perPageStory.value}&offset=${term.currentOffset.value}`;
      }
      // const apiEndPoint = `${this.baseURL}v1/story-types/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  storyTypeUpload(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/story-types/`;
      // console.log(data);
      this.http
        .post(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  storyTypeDetailsById(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/story-types/${id}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  storyTypeUpdate(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/story-types/${id}/`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  storyTypeDelete(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/story-types/${id}/`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getAllShieldTypes(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.searchShieldType.value) {
         apiEndPoint = `${this.baseURL}v1/shield-types/?search=${term.searchShieldType.value}&orderBy=-updated_at&limit=${term.perPageShield.value}&offset=${term.currentOffset.value}`;
      } else {
         apiEndPoint = `${this.baseURL}v1/shield-types/?orderBy=-updated_at&limit=${term.perPageShield.value}&offset=${term.currentOffset.value}`;
      }
      // const apiEndPoint = `${this.baseURL}v1/shield-types/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  shieldTypeUpload(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/shield-types/`;
      // console.log(data);
      this.http
        .post(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  shieldTypeDetailsById(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/shield-types/${id}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  shieldTypeUpdate(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/shield-types/${id}/`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  shieldTypeDelete(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/shield-types/${id}/`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
}
