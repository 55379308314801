<!-- <template lang="html">
  <div class="container">
    <QuillEditor theme="snow" :toolbar="toolbarOptions" v-model:content="data" contentType="html" />
  </div>
  <div class="container">
    <button class="btn btn-primary" @click="demo">demo</button>
  </div>
  <div class="container text-center">
    <div class="card">
      {{ res.description }} -->
<!-- {{ Detals }}
      <div v-html="Detals"></div>
    </div>
  </div>
</template> -->
<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1>Cms Single Page</h1>
            </div>
            <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to add page"/>
            <!-- <div class="col text-end">

            </div> -->

            <div class="card shadow-sm">
              <div class="card-body">
                <div class="card-text">
                  <div class="row g-3">
                  <div class="col-md-6">
                    <label for="title" class="form-label">Page Name</label>
                    <input type="text" class="form-control" v-model="cmslistUpload.page_name" />
                    <span class="text-danger" v-if="v$.page_name.$error">
                      {{ v$.page_name.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="title" class="form-label">Slug</label>
                    <input type="text" class="form-control" v-model="cmslistUpload.slug" />
                    <span class="text-danger" v-if="v$.slug.$error">
                      {{ v$.slug.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="lang" class="form-label">language</label>
                    <form action="">
                      <select
                        id="lang"
                        name="lang"
                        class="select form-select"
                        v-model="cmslistUpload.language"
                      >
                        <option
                          v-for="(item, index) in language.items"
                          :key="index"
                          :value="item.id"
                        >
                          {{ item.language }}
                        </option>
                      </select>
                      <span class="text-danger" v-if="v$.language.$error">
                        {{ v$.language.$errors[0].$message }}*
                      </span>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <label for="status" class="form-label">publish_status</label>
                    <form action="">
                      <select
                        id="status"
                        name="status"
                        class="select form-select"
                        v-model="cmslistUpload.publish_status"
                      >
                        <option value="draft">draft</option>
                        <option value="published">published</option>
                        <option value="deleted">deleted</option>
                      </select>
                      <span class="text-danger" v-if="v$.publish_status.$error">
                        {{ v$.publish_status.$errors[0].$message }}*
                      </span>
                    </form>
                  </div>
                  </div>
                  <div class="container mt-4">
                    <QuillEditor
                      theme="snow"
                      :toolbar="toolbarOptions"
                      v-model:content="cmslistUpload.html_description"
                      contentType="html"
                    />
                  </div>
                  <!-- Page Image Section -->
                  <div class="col-md-12 border mt-5">
                      <h5 class="mt-4">Page Images</h5>
                      <form class="p-5">
                        <div class="form-group row">
                          <label for="title" class="form-label col-md-2">Image</label>
                          <div class="col-md-6 mb-4">
                            <input
                              type="file"
                              class="form-control-file"
                              id="edit-image-file"
                              accept="image/*"
                              hidden="hidden"
                            />
                            <button
                              class="btn btn-primary form-control mt-3"
                              type="button"
                              id="custom-button"
                              @click="uploadFile()"
                            >
                              <span>Add image <i class="bi bi-upload" aria-hidden="true"></i></span>
                            </button>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="title" class="form-label col-md-2">Preview:</label>
                          <div class="col-md-6">
                            <ul>
                              <li v-for="(img, index) in imageName" :key="index">
                                <img style="width: 100px" :src="img" alt="" class="mt-2 me-2" />
                                <button
                                  type="button"
                                  class="btn btn-outline-danger rounded-5 bi bi-trash"
                                  @click="deleteImageFile(index)"
                                ></button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </form>
                    </div>

                    <!-- Page Video Section -->
                    <div class="col-md-12 border mt-5">
                      <h5 class="mt-4">Page Video</h5>
                      <form class="p-5">
                        <div class="form-group row">
                          <label for="title" class="form-label col-md-2">Video</label>
                          <div class="col-md-6 mb-4">
                            <input
                              type="file"
                              class="form-control-file"
                              id="video-file"
                              accept="video/*"
                              hidden="hidden"
                            />
                            <button
                              class="btn btn-primary form-control"
                              type="button"
                              id="custom-button"
                              @click="editVideoFile"
                            >
                              <span>Add Video <i class="bi bi-upload" aria-hidden="true"></i></span>
                            </button>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="title" class="form-label col-md-2">Preview:</label>
                          <div class="col-md-6">
                            <ul>
                              <li v-show="video != ''">
                                <video style="width: 500px;" id="video-preview" controls/>
                                <button
                                  type="button"
                                  class="btn btn-outline-danger rounded-5 bi bi-trash"
                                  @click="deleteVideoFile(index)"
                                ></button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="mt-5">
                      <div
                        class="col-md-12 border"
                        v-for="(data, counter) in detail"
                        v-bind:key="counter"
                      >
                        <div class="container text-end mt-3">
                          <span @click="deleteDetail(counter)">x</span>
                        </div>
                        <h5>Details</h5>
                        <form class="p-5">
                          <label for="title" class="form-label mx-2">Title</label>
                          <input
                            type="text"
                            v-model.lazy="data.title"
                            required
                            class="form-control"
                          />
                          <label for="title" class="form-label mx-2 mt-2">Description</label>
                          <input
                            type="text"
                            v-model.lazy="data.description"
                            required
                            class="form-control mt-2"
                          />
                          <label for="title" class="form-label mx-2 mt-2">sort order</label>
                          <input
                            type="number"
                            min="0"
                            class="form-control mt-2"
                            v-model.lazy="data.sort_order"
                          />
                        </form>
                      </div>
                      <button @click="add()" class="btn btn-primary mt-4">Add more details</button>
                    </div>
                </div>
                <div class="container text-end mt-3">
                  <button class="btn btn-primary" @click="createSinglePage">Add</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script lang="js">
// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from 'dompurify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { QuillEditor, Quill } from '@vueup/vue-quill';
// eslint-disable-next-line import/no-extraneous-dependencies
import BlotFormatter from 'quill-blot-formatter';
import {
  ref, computed, onMounted, reactive,
} from 'vue';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import Loader from '@/components/commons/Loader.vue';

import CmsPageService from '@/services/CmsPageService';
import { useRouter } from 'vue-router';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const cmsPageService = new CmsPageService();

let imageFileBtn;
let editVideoFileBtn;

export default {
  components: {
    QuillEditor,
    DashboardTemplate,
    Loader,
    ErrorDisplay,
  },
  setup(props) {
    const router = useRouter();
    // const modules = {
    //   name: 'blotFormatter',
    //   module: BlotFormatter,
    //   options: {/* options */},
    // };
    const data = ref('');
    const language = ref([]);
    const image = ref([]);
    const imageName = ref([]);
    const video = ref('');

    const cmslistUpload = reactive({
      page_name: '',
      slug: '',
      html_description: '',
      language: '',
      publish_status: '',

    });

    const detail = ref([
      {
        title: '',
        description: '',
        sort_order: '',
      },
    ]);

    const add = () => {
      detail.value.push({
        title: '',
        description: '',
        sort_order: '',
      });
    };

    const deleteDetail = (counter) => {
      detail.value.splice(counter, 1);
    };

    const isLoading = ref(false);
    const successMsg = ref('');
    const errorMsg = ref('');
    cmsPageService.getCmsLanguage().then((response) => {
      language.value = response.data;
    });
    const toolbarOptions = [
      ['bold', 'italic', 'underline'], // toggled buttons

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      //   [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      //   [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{
        color: [
          '#605dc7',
          '#000000',
          '#e60000',
          '#ff9900',
          '#ffff00',
          '#008a00',
          '#0066cc',
          '#9933ff',
          '#ffffff',
          '#facccc',
          '#ffebcc',
          '#ffffcc',
          '#cce8cc',
          '#cce0f5',
          '#ebd6ff',
          '#bbbbbb',
          '#f06666',
          '#ffc266',
          '#ffff66',
          '#66b966',
          '#66a3e0',
          '#c285ff',
          '#888888',
          '#a10000',
          '#b26b00',
          '#b2b200',
          '#006100',
          '#0047b2',
          '#6b24b2',
          '#444444',
          '#5c0000',
          '#663d00',
          '#666600',
          '#003700',
          '#002966',
          '#3d1466',
          '#f8f6f3',
          '#3e3e3e',
        ],
      },
      {
        background: [
          '#605dc7',
          '#000000',
          '#e60000',
          '#ff9900',
          '#ffff00',
          '#008a00',
          '#0066cc',
          '#9933ff',
          '#ffffff',
          '#facccc',
          '#ffebcc',
          '#ffffcc',
          '#cce8cc',
          '#cce0f5',
          '#ebd6ff',
          '#bbbbbb',
          '#f06666',
          '#ffc266',
          '#ffff66',
          '#66b966',
          '#66a3e0',
          '#c285ff',
          '#888888',
          '#a10000',
          '#b26b00',
          '#b2b200',
          '#006100',
          '#0047b2',
          '#6b24b2',
          '#444444',
          '#5c0000',
          '#663d00',
          '#666600',
          '#003700',
          '#002966',
          '#3d1466',
          '#f8f6f3',
          '#3e3e3e',
        ],
      }], // dropdown with defaults from theme
      //   [{ font: [] }],
      //   [{ align: [] }],
      ['link', 'image'],
      ['clean'], // remove formatting button
    ];

    const rules = {
      page_name: { required: helpers.withMessage('Please enter a Page Name', required) },
      slug: { required: helpers.withMessage('Please enter a slug', required) },
      language: { required: helpers.withMessage('Please select a language', required) },
      publish_status: {
        required: helpers.withMessage('Please select a publish language', required),
      },
    };

    const v$ = useVuelidate(rules, cmslistUpload);

    // const Detals = computed(() => DOMPurify.sanitize(res.value.description));

    onMounted(() => {
      imageFileBtn = document.getElementById('edit-image-file');
      editVideoFileBtn = document.getElementById('video-file');
    });

    const imageListener = async () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.files[0];
        cmslistUpload.images = file;
        const url = await URL.createObjectURL(file);
        imageName.value.push(url);
        image.value.push(file);
      } else {
        console.log('no file selected');
      }
    };

    const uploadFile = () => {
      imageFileBtn.click();
      imageFileBtn.addEventListener('change', imageListener);
    };

    const deleteImageFile = (img, index) => {
      image.value.splice(index, 1);
      imageName.value.splice(index, 1);
    };

    const previewVideo = () => {
      const videoSrc = document.getElementById('video-preview');
      const reader = new FileReader();

      reader.readAsDataURL(video.value);
      reader.addEventListener('load', () => {
        videoSrc.src = reader.result;
      });
    };

    const editVideoListener = () => {
      if (editVideoFileBtn.value) {
        const file = editVideoFileBtn.files[0];
        video.value = file;
        previewVideo();
      } else {
        console.log('no file selected');
      }
    };

    const editVideoFile = () => {
      editVideoFileBtn = document.getElementById('video-file');
      editVideoFileBtn.click();
      editVideoFileBtn.addEventListener('change', editVideoListener);
    };

    const createSinglePage = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        const formData = new FormData();
        formData.append('page_name', cmslistUpload.page_name);
        formData.append('slug', cmslistUpload.slug);
        formData.append('html_description', cmslistUpload.html_description);
        formData.append('language', cmslistUpload.language);
        formData.append('publish_status', cmslistUpload.publish_status);
        image.value.forEach((item) => formData.append('images', item));
        image.value.forEach((item, index) => formData.append(`image_sort_orders[${index}]`, index));
        formData.append('video_sort_orders[0]', 0);
        formData.append('videos', video.value.videos);
        cmsPageService
          .cmslistUpload(formData)
          .then(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            isLoading.value = false;
            successMsg.value = 'Page Has Been Added Successfully';
            setTimeout(() => {
              successMsg.value = '';
              router.push('/cms-page');
            }, 3000);
          })
          .catch((error) => {
            isLoading.value = false;
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };

    return {
      // modules,
      data,
      toolbarOptions,
      cmslistUpload,
      image,
      imageName,
      v$,
      uploadFile,
      createSinglePage,
      language,
      imageFileBtn,
      deleteImageFile,
      editVideoFile,
      video,
      detail,
      add,
      deleteDetail,

    };
  },

};
</script>

<style></style>
