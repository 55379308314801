<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-music-note-list"></i> Playlists</h1>
            </div>
            <!-- <div class="col text-end">
              <router-link to="/deleted-playlist" class="text-danger"
                ><i class="bi bi-trash-fill"> </i>Deleted Playlists</router-link
              >
            </div> -->
            <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
              <div class="row justify-content-end">
                <div class="col">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Select Deleted or Active Items Here"
                  >
                    <router-link
                      to="/playlists"
                      class="btn btn-outline-success rounded-start-5 tooltip-wrap"
                      :class="{ active: routePath === '/playlists' }"
                    >
                      <i class="bi bi-radioactive"></i>
                      Active
                      <span class="tooltip">Active Items</span>
                    </router-link>
                    <router-link
                      to="/deleted-playlist"
                      class="btn btn-outline-danger rounded-end-5 tooltip-wrap"
                      :class="{ active: routePath === '/deleted-playlist' }"
                    >
                      <i class="bi bi-trash-fill"></i>
                      Deleted
                      <span class="tooltip">Deleted Items</span>
                    </router-link>
                  </div>
                </div>
                <div class="col-auto">
                  <label for="inputPassword2" class="visually-hidden">Search</label>
                  <input
                    type="text"
                    class="form-control rounded-5"
                    placeholder="Search"
                    v-model="searchPlaylist"
                    v-on:keyup.enter="searchClick()"
                  />
                </div>
                <div class="col-auto tooltip-wrap">
                  <button
                    type="submit"
                    class="btn btn-outline-primary mb-3 rounded-5"
                    @click="searchClick()"
                  >
                    <i class="bi bi-search"></i>
                    <span class="tooltip">Search Here</span>
                  </button>
                </div>
                <div class="col-auto tooltip-wrap">
                  <router-link to="/add-playlist" class="btn btn-outline-success me-3 rounded-5"
                    ><i class="bi bi-database-add"></i>
                    <span class="tooltip">Add New</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="something went wrong"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered text-center">
                    <thead>
                      <tr>
                        <th>UUID</th>
                        <th>Name</th>
                        <th>Image</th>
                        <th>Type</th>
                        <th>Stories</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="playlists?.total === 0">
                        <td scope="col" colspan="7" class="text-center text-primary pt-3 pb-3">
                          Not Found
                        </td>
                      </tr>

                      <tr v-else v-for="(playlist, index) in playlists.items" :key="index">
                        <td>{{ playlist.id }}</td>
                        <td>{{ playlist.title }}</td>
                        <!-- <td>{{ playlist.description }}</td> -->
                        <td v-if="playlist.thumbnail">
                          <img :src="playlist.thumbnail" alt="" style="width: 50px" />
                        </td>
                        <td v-else>No Image</td>
                        <td>{{ playlist?.type?.slug }}</td>
                        <!-- <td>{{ playlist.owner }}</td> -->
                        <td>
                          <button
                            class="btn bi bi-file-earmark-music btn-outline-info rounded-5"
                            data-bs-toggle="modal"
                            data-bs-target="#playlistStoryDetails"
                            @click="getDetailsofPlaylist(playlist.id)"
                          ></button>
                        </td>
                        <!-- <td>{{ playlist.language }}</td> -->
                        <td>
                          <router-link
                            :to="`/edit-playlist/${playlist.id}`"
                            class="btn btn-outline-primary bi bi-pencil-square rounded-5"
                          ></router-link>
                        </td>
                        <td>
                          <button
                            class="btn bi bi-trash btn-outline-danger rounded-5"
                            data-bs-toggle="modal"
                            data-bs-target="#playlistDeleteModal"
                            @click="deletePopUp(playlist.id)"
                          ></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <VuejsPaginateNext
              v-model="page"
              :page-count="pageCount"
              :click-handler="pageClick"
              :margin-pages="2"
              :page-range="3"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
            ></VuejsPaginateNext>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>

  <!-- details modal -->
  <!-- <div class="modal-wrapper" :key=" playlistDetails?.id"> -->
  <div
    class="modal fade modal-xl"
    id="playlistStoryDetails"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="playlistStoryDetailsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="playlistStoryDetailsLabel">{{ playlistDetails.title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="modelClose()"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="isLoading"><Loader /></div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <!-- <th scope="col">id</th> -->
                  <th scope="col">Title</th>
                  <th scope="col">Image</th>
                  <th scope="col">File</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>

              <!-- {{ playlistDetails.story_count }} -->
              <tbody>

                <tr v-if="playlistDetails?.story_count === 0">
                  <td scope="col" colspan="6" class="text-center text-primary pt-3 pb-3">
                    No Stories Added
                  </td>
                </tr>
                <tr v-else v-for="(story, index) in playlistDetails.stories" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <!-- <td>{{ story.id }}</td> -->
                  <td>{{ story.story.title }}</td>
                  <td v-if="story.story.thumbnail ?? story.story.image">
                    <img :src="story.story.thumbnail ?? story.story.image" alt="" style="width: 50px" />
                  </td>
                  <td v-else>No Image</td>
                  <td v-if="story.story.file">
                    <audio controls>
                      <source :src="story.story.file" type="audio/mpeg" />
                    </audio>
                  </td>
                  <td v-else>No Audio</td>

                  <td>{{ story.story.type }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->

  <!-- delete Modal -->
  <div
    class="modal fade"
    id="playlistDeleteModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="playlistDeleteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="playlistDeleteModalLabel">Are You Sure ?</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">Do you Want to Delete? It can be retrieved.</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
            @click="deleteplaylist()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- story selection Modal -->
  <div
    class="modal fade modal-lg"
    id="storySelectionModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="storySelectionModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="storySelectionModalLabel">Select the Stories</h5>
        </div>
        <StorySelectionModal :modal-data="modalName" />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';

import PlaylistService from '@/services/PlaylistService';
import AddAndDeleteStory from '@/components/partials/AddAndDeleteStory.vue';
import StorySelectionModal from '@/components/partials/StorySelectionModal.vue';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';

import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const playlistService = new PlaylistService();

let imageFileBtn;
let editImageFileBtn;

export default {
  name: 'About',
  props: [],
  components: {
    Loader,
    // AddAndDeleteStory,
    DashboardTemplate,
    VuejsPaginateNext,
    StorySelectionModal,
    ErrorDisplay,
    // VueMultiselect,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(false);
    const playlistsId = ref('');
    const modalName = ref('');
    const routePath = ref('');
    const owner = ref('');
    const searchPlaylist = ref('');
    const searchPlaylistType = ref('');
    const searchStory = ref('');
    const currentOffset = ref(0);
    const playlistPerPage = ref(10);
    const perPagePlaylist = ref(0);
    const perPageUser = ref(0);
    const pageCount = ref(0);
    const page = ref(1);
    const successMsg = ref('');
    const errorMsg = ref('');
    routePath.value = route.path;

    isLoading.value = true;

    const SearchData = {
      searchPlaylist,
      currentOffset,
      playlistPerPage,
    };
    store.dispatch('fetchAllPlaylists', SearchData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / playlistPerPage.value);

      isLoading.value = false;
    });

    const playlistDetails = computed(() => store.getters.getStoryByPlaylistId);

    const playlists = computed(() => store.getters.getPlaylists);

    const getDetailsofPlaylist = (id) => {
      isLoading.value = true;
      store.dispatch('fetchStoryPlaylistById', id).then(() => {
        isLoading.value = false;
      });
    };

    const deletePopUp = (id) => {
      playlistsId.value = id;
    };

    const deleteplaylist = () => {
      isLoading.value = true;
      playlistService.playlistDelete(playlistsId.value).then((res) => {
        store.dispatch('fetchAllPlaylists', SearchData).then((response) => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          pageCount.value = Math.ceil(response.data.total / playlistPerPage.value);
          playlistsId.value = '';
          successMsg.value = res?.data?.message;
          setTimeout(() => {
            successMsg.value = '';
          }, 3000);
          isLoading.value = false;
        });
      }).catch((error) => {
        isLoading.value = false;
        errorMsg.value = error?.data;
        setTimeout(() => {
          errorMsg.value = '';
        }, 3000);
      });
    };

    const modelClose = () => {
      playlistDetails.value = '';
    };

    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchPlaylist,
        currentOffset,
        playlistPerPage,
      };
      isLoading.value = true;
      store.dispatch(
        'fetchAllPlaylists',
        search,
      ).then((response) => {
        pageCount.value = Math.ceil(response.data.total / playlistPerPage.value);
        isLoading.value = false;
      });
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;

      currentOffset.value = playlistPerPage.value * (pageNum - 1);
      const Data = {
        searchPlaylist,
        currentOffset,
        playlistPerPage,
      };

      store.dispatch('fetchAllPlaylists', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / playlistPerPage.value);

        isLoading.value = false;
      });
    };

    const changeModal = (val) => {
      modalName.value = val;
    };

    return {
      isLoading,
      playlists,
      getDetailsofPlaylist,
      playlistDetails,
      playlistsId,
      deletePopUp,
      deleteplaylist,
      searchPlaylist,
      searchClick,
      searchStory,
      searchPlaylistType,
      currentOffset,
      playlistPerPage,
      pageCount,
      page,
      pageClick,
      changeModal,
      modalName,
      routePath,
      successMsg,
      errorMsg,
      modelClose,
    };
  },
};
</script>
