<!-- eslint-disable vue/valid-v-model -->
<!-- eslint-disable no-shadow -->
<!-- eslint-disable no-undef -->
<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class=""></i> Add New Widget</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to add widget"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="title" class="form-label">Widget Name</label>
                    <input type="text" class="form-control" v-model="cmsWidgetUpload.widgetName" />
                    <span class="text-danger" v-if="v$.widgetName.$error">
                      {{ v$.widgetName.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="title" class="form-label">Title</label>
                    <input type="text" class="form-control" v-model="cmsWidgetUpload.title" />

                  </div>
                  <div class="col-md-6">
                    <label for="title" class="form-label">Content</label>
                    <input type="text" class="form-control" v-model="cmsWidgetUpload.content" />
                  </div>
                  <div class="col-md-6">
                    <label for="parent" class="form-label">Parent</label>
                    <form action="">
                      <select
                        id="parent"
                        name="parent"
                        class="select form-select"
                        v-model="cmsWidgetUpload.parent"
                      >
                        <option
                          v-for="(item, index) in cmsListDetails.items"
                          :key="index"
                          :value="item.id"
                        >
                          {{ item.page_name }}
                        </option>
                      </select>
                      <span class="text-danger" v-if="v$.parent.$error">
                      {{ v$.parent.$errors[0].$message }}*
                    </span>
                    </form>
                  </div>

                  <div class="col-md-6">
                    <label for="lang" class="form-label">language</label>
                    <form action="">
                      <select
                        id="lang"
                        name="lang"
                        class="select form-select"
                        v-model="cmsWidgetUpload.language"
                      >
                        <option
                          v-for="(item, index) in language.items"
                          :key="index"
                          :value="item.id"
                        >
                          {{ item.language }}
                        </option>
                      </select>
                      <span class="text-danger" v-if="v$.language.$error">
                      {{ v$.language.$errors[0].$message }}*
                    </span>
                    </form>
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="status" class="form-label">publish_status</label>
                    <form action="">
                      <select
                        id="status"
                        name="status"
                        class="select form-select"
                        v-model="cmsWidgetUpload.publish_status"
                      >
                        <option value="draft">draft</option>
                        <option value="published">published</option>
                        <option value="deleted">deleted</option>
                      </select>
                      <span class="text-danger" v-if="v$.publish_status.$error">
                      {{ v$.publish_status.$errors[0].$message }}*
                    </span>
                    </form>
                  </div> -->
                  <!-- Widget Image Section-->
                  <div class="col-md-12 border">
                    <h5 class="mt-4">Widget Images</h5>
                    <form class="p-5">
                      <div class="form-group row">
                        <label for="title" class="form-label col-md-2">Image</label>
                        <div class="col-md-6 mb-4">
                          <input
                            type="file"
                            class="form-control-file"
                            id="image-file"
                            accept="image/*"
                            hidden="hidden"
                          />
                          <button
                            class="btn btn-primary form-control"
                            type="button"
                            id="custom-button"
                            @click="uploadFile()"
                          >
                            <span>Add images <i class="bi bi-upload" aria-hidden="true"></i></span>
                          </button>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="title" class="form-label col-md-2">Image Preview:</label>
                        <div class="col-md-6">
                          <ul>
                              <li v-for="(img, index) in imageName" :key="index">
                                <img style="width: 100px" :src="img" alt="" class="mt-2 me-4"/>
                                <button class="btn btn-outline-danger rounded-5 bi bi-trash" @click="deleteImageFile(img, index)"></button>
                              </li>
                            </ul>
                        </div>
                      </div>
                    </form>
                  </div>

                  <!-- Details Section -->

                  <div
                    class="col-md-12 border"
                    v-for="(item, counter) in newDetails"
                    v-bind:key="counter"
                  >
                    <div class="container text-end mt-3">
                      <span @click="deleteDetailsButton(counter)">x</span>
                    </div>
                    <h5>Details</h5>
                    <form class="p-5">
                      <div class="form-group row">
                        <label for="title" class="form-label col-md-2">Title</label>
                        <div class="col-md-12 mb-4">
                          <!-- <input type="text" class="form-control" v-model.lazy="item.title" /> -->
                          <QuillEditor
                            theme="snow"
                            :toolbar="toolbarOptions"
                            v-model:content="item.title"
                            contentType="html"
                          />
                        </div>
                      </div>
                      <div class="form-group mt-5 row">
                        <label for="title" class="form-label col-md-2">Description</label>
                        <div class="col-md-12 mb-4">
                          <!-- <textarea
                            rows="10"
                            cols="30"
                            name=""
                            id=""
                            type="text"
                            class="form-control"
                            v-model.lazy="item.description"
                          ></textarea> -->
                          <QuillEditor
                            theme="snow"
                            :toolbar="toolbarOptions"
                            v-model:content="item.description"
                            contentType="html"
                          />
                        </div>
                      </div>
                      <div class="form-group mt-5 row">
                        <label for="title" class="form-label col-md-2">Image</label>
                        <div class="col-md-6 mb-4">
                          <input
                            type="file"
                            class="form-control-file"
                            id="widget-image-file"
                            accept="image/*"
                            hidden="hidden"
                          />
                          <button
                            class="btn btn-primary form-control"
                            type="button"
                            id="custom-button"
                            @click="widgetImageFile(counter)"
                          >
                            <span>Add image <i class="bi bi-upload" aria-hidden="true"></i></span>
                          </button>
                        </div>
                      </div>
                      <div v-if="item.image"  class="form-group row">
                          <label for="title" class="form-label col-md-2">Image Preview:</label>
                          <div class="col-md-6">
                            <ul>
                              <li>
                                <img style="width: 100px;" :src="item.image" alt="" class="mt-2 me-4"/>
                                <button type="button" class="btn btn-outline-danger rounded-5 bi bi-trash" @click="deleteWidgetImage(counter)"></button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      <div class="form-group row">
                        <label for="title" class="form-label col-md-2">Sort Order</label>
                        <div class="col-md-6 mb-4">
                          <input type="number" min="0" class="form-control" v-model.lazy="item.sortOrder" />
                        </div>
                      </div>
                    </form>
                  </div>
                  <button @click="newDetailsButton()" class="btn btn-primary mt-4">Add more details</button>
                </div>
              </div>
            </div>
            <button class="btn btn-primary mt-4" @click="createCmslist">Upload</button>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script>
import { useStore } from 'vuex';
import {
  ref, reactive, onMounted, computed,
} from 'vue';

import {
  required, helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { QuillEditor, Quill } from '@vueup/vue-quill';

import CmsWidgetService from '@/services/CmsWidgetService';
import CmsPageService from '@/services/CmsPageService';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const cmsWidgetService = new CmsWidgetService();
const cmsPageService = new CmsPageService();

let imageFileBtn;
let widgetImageFileBtn;

export default {
  name: 'AddCmsWidget',
  components: {
    DashboardTemplate,
    Loader,
    QuillEditor,
    ErrorDisplay,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const Details = ref([]);
    const searchCms = ref('');
    const currentOffset = ref(0);
    const perPage = ref(0);
    const input = ref([]);
    const language = ref([]);
    const cmsList = ref([]);
    const image = ref([]);
    const imageName = ref([]);
    const video = ref([]);
    const widgetImage = ref([]);
    const count = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const isLoading = ref(false);
    const newDetails = reactive([
      {
        title: '',
        description: '',
        image: '',
        sortOrder: '',
      },
    ]);

    const deleteWidgetImage = (counter) => {
      newDetails[counter].image = null;
      widgetImage.value[counter] = '';
    //   widgetImage.value.splice(counter, 1);
    };

    const deleteImageFile = (img, index) => {
      image.value.splice(index, 1);
      imageName.value.splice(index, 1);
    };

    const newDetailsButton = () => {
      newDetails.push({
        title: '',
        description: '',
        sortOrder: '',
      });
    };

    const deleteDetailsButton = (counter) => {
      newDetails.splice(counter, 1);
    };

    const cmsWidgetUpload = reactive({
      widgetName: '',
      title: '',
      content: '',
      parent: '',
      language: '',
      publish_status: '',
      details: [],
      images: [],
    });

    const rules = {
      widgetName: { required: helpers.withMessage('Please enter a Widet Name', required) },
      parent: { required: helpers.withMessage('Please select a parent', required) },
      language: { required: helpers.withMessage('Please select a language', required) },
    };

    const v$ = useVuelidate(rules, cmsWidgetUpload);

    onMounted(() => {
      imageFileBtn = document.getElementById('image-file');
      widgetImageFileBtn = document.getElementById('widget-image-file');
    });

    const imageListener = async () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.files[0];
        cmsWidgetUpload.images = file;
        const url = await URL.createObjectURL(file);
        imageName.value.push(url);
        image.value.push(file);
      } else {
        console.log('no file selected');
      }
    };

    const uploadFile = () => {
      imageFileBtn.click();
      imageFileBtn.addEventListener('change', imageListener);
    };

    const widgetImageListener = async () => {
      if (widgetImageFileBtn.value) {
        const file = widgetImageFileBtn.files[0];
        const url = await URL.createObjectURL(file);
        widgetImage.value[count.value] = file;
        newDetails[count.value].image = url;
      } else {
        console.log('no file selected');
      }
    };

    const widgetImageFile = (counter) => {
      widgetImageFileBtn.click();
      count.value = counter;
      widgetImageFileBtn.addEventListener('change', widgetImageListener);
    };

    const detail = (del, sort) => {
      input.value.push(del);
    };

    const isDetailsEmpty = (obj) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key) && (obj[key] !== null && obj[key] !== '')) {
          return false;
        }
      }
      return true;
    };

    const createCmslist = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        const formData = new FormData();
        formData.append('widget_name', cmsWidgetUpload.widgetName);
        formData.append('title', cmsWidgetUpload.title);
        formData.append('content', cmsWidgetUpload.content);
        formData.append('parent', cmsWidgetUpload.parent);
        // formData.append('publish_status', cmsWidgetUpload.publish_status);
        formData.append('language', cmsWidgetUpload.language);
        if (image.value) {
          image.value.forEach((item, index) => formData.append(`image_sort_orders[${index}]`, index));
          image.value.forEach((item) => formData.append('images', item));
        }
        const details = newDetails.filter((obj) => !isDetailsEmpty(obj));

        if (details.length > 0) {
          details.forEach((item, index) => {
            formData.append(`details[${index}][title]`, item.title);
            formData.append(`details[${index}][description]`, item.description);
            formData.append(`details[${index}][sort_order]`, item.sortOrder);
            formData.append('details_images', widgetImage.value[index]);
          });
        }

        cmsWidgetService
          .addCmsWidget(formData)
          .then((res) => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            isLoading.value = false;
            successMsg.value = ' Widget Has Been Added Successfully';
            setTimeout(() => {
              successMsg.value = '';
              router.push('/cms-widgets');
            }, 3000);
          })
          .catch((error) => {
            isLoading.value = false;
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };
    const SearchData = {
      searchCms,
      currentOffset,
      perPage,
    };

    store.dispatch('fetchAllCmslists', SearchData);
    const cmsListDetails = computed(() => store.getters.getCmsLists);
    cmsPageService.getCmsLanguage().then((res) => {
      language.value = res.data;
    });

    return {
      cmsWidgetUpload,
      createCmslist,
      uploadFile,
      image,
      video,
      cmsList,
      detail,
      Details,
      input,
      language,
      cmsListDetails,
      newDetailsButton,
      deleteDetailsButton,
      newDetails,
      deleteImageFile,
      imageName,
      widgetImageFile,
      widgetImage,
      v$,
      isLoading,
      successMsg,
      errorMsg,
      deleteWidgetImage,
    };
  },
};
</script>
