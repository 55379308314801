import { useCookies } from 'vue3-cookies';
import CLayerBaseService from './CLayerBaseService';

const cLayerBaseService = new CLayerBaseService();
const { cookies } = useCookies({ secure: true });

export default class CLayerService extends CLayerBaseService {
  getShippingCategories(term) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.cLayerBaseURL}/api/shipping_categories/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            // Accept: 'application/json',
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getClayerAdminToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          if (error.response.status === 401) {
            cLayerBaseService.createAdminToken();
            window.location.reload();
          }
        });
    });
  }

  getPriceLists(term) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.cLayerBaseURL}/api/price_lists/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            // Accept: 'application/json',
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getClayerAdminToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          if (error.response.status === 401) {
            cLayerBaseService.createAdminToken();
            window.location.reload();
          }
        });
    });
  }

  getStockLocations(term) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.cLayerBaseURL}/api/stock_locations/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            // Accept: 'application/json',
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getClayerAdminToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          if (error.response.status === 401) {
            cLayerBaseService.createAdminToken();
            window.location.reload();
          }
        });
    });
  }

  createSkus(Data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.cLayerBaseURL}/api/skus/`;
      this.http
        .post(apiEndPoint, Data, {
          headers: {
            // Accept: 'application/json',
            'Content-Type': 'application/vnd.api+json',
            Authorization: `Bearer ${this.getClayerAdminToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createPrices(Data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.cLayerBaseURL}/api/prices/`;
      this.http
        .post(apiEndPoint, Data, {
          headers: {
            // Accept: 'application/json',
            'Content-Type': 'application/vnd.api+json',
            Authorization: `Bearer ${this.getClayerAdminToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createStockItems(Data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.cLayerBaseURL}/api/stock_items/`;
      this.http
        .post(apiEndPoint, Data, {
          headers: {
            // Accept: 'application/json',
            'Content-Type': 'application/vnd.api+json',
            Authorization: `Bearer ${this.getClayerAdminToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
