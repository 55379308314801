<!-- eslint-disable no-undef -->
<!-- eslint-disable vue/no-parsing-error -->
<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class=""></i> Page Details</h1>
            </div>
            <div
              v-if="successMsg"
              class="card text-success text-center border-success rounded-5 mb-3"
            >
              <div class="card-body">{{ successMsg }}</div>
            </div>
            <ErrorDisplay :error="errorMsg" message="Unable to add Page"/>
            <div class="card shadow-sm">
              <div class="card-body">
                <div class="card-text">
                  <div class="row g-3">
                    <div class="col-md-6">
                      <label for="title" class="form-label">Page Name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="cmsList.page_name"
                        disabled
                      />
                    </div>
                    <div class="col-md-6">
                      <label for="title" class="form-label">Slug</label>
                      <input type="text" class="form-control" v-model="cmsList.slug" />
                    </div>
                    <div class="col-md-6">
                      <label for="title" class="form-label">Title</label>
                      <input type="text" class="form-control" v-model="cmsList.title" />
                    </div>
                    <div class="col-md-6">
                      <label for="title" class="form-label">Description:</label>
                      <textarea
                        rows="10"
                        cols="30"
                        name=""
                        id=""
                        type="text"
                        class="form-control"
                        v-model="cmsList.description"
                      ></textarea>
                      <!-- <input type="text" class="form-control" v-model="cmsList.description" /> -->
                    </div>
                    <!-- <div class="col-md-6">
                      <label for="title" class="form-label">publish_status</label>
                      <form action="">
                        <select
                          id="status"
                          name="status"
                          class="select form-select"
                          v-model="cmsList.publish_status"
                        >
                          <option value="draft">draft</option>
                          <option value="published">published</option>
                          <option value="deleted">deleted</option>
                        </select>
                      </form>
                    </div> -->
                    <div class="col-md-6">
                      <label for="inputType" class="form-label">Language</label>
                      <select name="" id="inputType" class="form-select" v-model="lang">
                        <option value="">select the Language</option>
                        <option
                          v-for="(type, index) in language.items"
                          :key="index"
                          :value="type.language"
                        >
                          {{ type.language }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-12 mt-4 mb-4">
                      <QuillEditor
                        theme="snow"
                        :toolbar="toolbarOptions"
                        v-model:content="cmsList.html_description"
                        contentType="html"
                      />
                    </div>
                    <!-- <div class="col-md-6">
                      <label for="parent" class="form-label">Parent</label>
                      <form action="">
                        <select
                          id="parent"
                          name="parent"
                          class="select form-select"
                          v-model="parent"
                        >
                          <option
                            v-for="(item, index) in cmsListDetails.items"
                            :key="index"
                            :value="item.page_name"
                          >
                            {{ item.page_name }}
                          </option>
                        </select>
                      </form>
                    </div> -->

                    <!-- <div class="col-md-6">
                      <label for="title" class="form-label">images</label>
                      <ul>
                        <li v-for="(img, index) in imageName" :key="index">
                          <img style="width: 100px" :src="img" alt="" />
                          <button @click="deleteImageFile(img, index)">del</button>
                        </li>
                      </ul>
                      <div class="upload-block">
                        <input
                          type="file"
                          class="form-control-file"
                          id="edit-image-file"
                          accept="image/*"
                          hidden="hidden"
                        />
                        <button
                          class="btn btn-primary form-control mt-3"
                          type="button"
                          id="custom-button"
                          @click="editImageFile()"
                        >
                          <span>Add image <i class="bi bi-upload" aria-hidden="true"></i></span>
                        </button>
                      </div>
                    </div> -->

                    <!-- Page Image Section -->
                    <div class="col-md-12 border mt-5">
                      <h5 class="mt-4">Page Images</h5>
                      <form class="p-5">
                        <div class="form-group row">
                          <label for="title" class="form-label col-md-2">Image</label>
                          <div class="col-md-6 mb-4">
                            <input
                              type="file"
                              class="form-control-file"
                              id="edit-image-file"
                              accept="image/*"
                              hidden="hidden"
                            />
                            <button
                              class="btn btn-primary form-control mt-3"
                              type="button"
                              id="custom-button"
                              @click="editImageFile()"
                            >
                              <span>Add image <i class="bi bi-upload" aria-hidden="true"></i></span>
                            </button>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="title" class="form-label col-md-2">Preview:</label>
                          <div class="col-md-6">
                            <ul>
                              <li v-for="(img, index) in imageName" :key="index">
                                <img style="width: 100px" :src="img" alt="" class="mt-2 me-2" />
                                <button
                                  type="button"
                                  class="btn btn-outline-danger rounded-5 bi bi-trash"
                                  @click="deleteImageFile(index)"
                                ></button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </form>
                    </div>

                    <!-- Page Video Section -->
                    <div class="col-md-12 border mt-5">
                      <h5 class="mt-4">Page Video</h5>
                      <form class="p-5">
                        <div class="form-group row">
                          <label for="title" class="form-label col-md-2">Video</label>
                          <div class="col-md-6 mb-4">
                            <input
                              type="file"
                              class="form-control-file"
                              id="video-file"
                              accept="video/*"
                              hidden="hidden"
                            />
                            <button
                              class="btn btn-primary form-control"
                              type="button"
                              id="custom-button"
                              @click="editVideoFile"
                            >
                              <span>Add Video <i class="bi bi-upload" aria-hidden="true"></i></span>
                            </button>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="title" class="form-label col-md-2">Preview:</label>
                          <div class="col-md-6">
                            <ul>
                              <li v-show="video != ''">
                                <video style="width: 500px;" id="video-preview" controls/>
                                <button
                                  type="button"
                                  class="btn btn-outline-danger rounded-5 bi bi-trash"
                                  @click="deleteVideoFile(index)"
                                ></button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </form>
                    </div>
                    <!-- Details Section -->
                    <div>
                      <div
                        class="col-md-12 border"
                        v-for="(data, counter) in detail"
                        v-bind:key="counter"
                      >
                        <div class="container text-end mt-3">
                          <span @click="deleteDetail(counter)">x</span>
                        </div>
                        <h5>Details</h5>
                        <form class="p-5">
                          <label for="title" class="form-label mx-2">Title</label>
                          <input
                            type="text"
                            v-model.lazy="data.title"
                            required
                            class="form-control"
                          />
                          <label for="title" class="form-label mx-2 mt-2">Description</label>
                          <input
                            type="text"
                            v-model.lazy="data.description"
                            required
                            class="form-control mt-2"
                          />
                          <label for="title" class="form-label mx-2 mt-2">sort order</label>
                          <input
                            type="number"
                            min="0"
                            class="form-control mt-2"
                            v-model.lazy="data.sort_order"
                          />
                        </form>
                      </div>
                      <button @click="add()" class="btn btn-primary mt-4">Add more details</button>
                    </div>
                  </div>
                </div>
                <div class="text-end mt-5">
                  <button class="btn btn-primary mx-3" @click="editCmslist()">Update</button>
                  <!-- <button class="btn btn-primary" @click="deletecmslist()">Delete</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script>
import { useStore } from 'vuex';
import {
  ref, reactive, onMounted, computed,
} from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from 'dompurify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { QuillEditor, Quill } from '@vueup/vue-quill';
import { useRoute, useRouter } from 'vue-router';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import CmsPageService from '@/services/CmsPageService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

let imageFileBtn;
let editImageFileBtn;
let videoFileBtn;
let editVideoFileBtn;

const cmsPageService = new CmsPageService();

export default {
  name: 'CmsDetails',
  components: {
    DashboardTemplate,
    Loader,
    QuillEditor,
    ErrorDisplay,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const searchCms = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const id = ref('');
    const lang = ref('');
    const language = ref([]);
    id.value = route.params.id;
    const cmsListDetails = computed(() => store.getters.getCmsLists);
    const cmsList = ref({});
    const image = ref([]);
    const parent = ref('');
    const imageName = ref([]);
    const video = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const isLoading = ref(false);
    const selectedLang = ref('');
    const detail = ref([
      {
        title: '',
        description: '',
        sort_order: '',
      },
    ]);

    const add = () => {
      detail.value.push({
        title: '',
        description: '',
        sort_order: '',
      });
      cmsList.value.details = detail;
    };

    const deleteDetail = (counter) => {
      detail.value.splice(counter, 1);
    };

    const convertImageUrlToFile = async (url) => {
      try {
        if (url === null) {
          image.value.push(null);
        } else {
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', {
            type: blob.type,
          });
          image.value.push(file);
        }
      } catch (error) {
        console.error('Error converting image URL to file:', error);
      }
    };

    const convertVideoURLtoFile = async (url) => {
      const videoSrc = document.getElementById('video-preview');
      try {
        if (url[0]?.video_url) {
          if (url[0]?.video_url === null) {
            cmsList.value.videos = null;
            video.value = '';
          } else {
            const response = await fetch(url[0]?.video_url);
            const blob = await response.blob();
            const file = new File([blob], 'video.mp4', {
              type: 'video/mp4',
            });
            cmsList.value.videos = file;
            video.value = URL.createObjectURL(blob);
            videoSrc.src = video.value;
          }
        } else {
          video.value = '';
        }
      } catch (error) {
        console.error('Error converting image URL to file:', error);
      }
    };

    const SearchData = {
      searchCms,
      currentOffset,
      perPage,
      selectedLang,
    };

    cmsPageService.getCmsLanguage().then((res) => {
      language.value = res.data;
    });

    store.dispatch('fetchAllCmslists', SearchData);

    store.dispatch('fetchCmslistdetailsById', id.value).then((res) => {
      cmsList.value = res.data;
      res.data.images.forEach((item) => {
        imageName.value.push(item.image_url);
        convertImageUrlToFile(item.image_url);
      });
      convertVideoURLtoFile(res?.data?.videos);
      detail.value = res.data.details;

      lang.value = res.data.language.language;
      // res.data.images.forEach((item, index) => {
      //   convertImageUrlToFile(item.image_url);
      // });
    });

    const cmsListDetailsById = computed(() => store.getters.getCmslistdetailsById);

    const editCmslist = () => {
      isLoading.value = true;
      let parentSelected = '';
      let languageSelected = '';
      cmsListDetails.value.items.forEach((item) => {
        if (item.page_name === parent.value) {
          parentSelected = item.id;
        }
      });

      language.value.items.forEach((item) => {
        if (item.language === lang.value) {
          languageSelected = item.id;
        }
      });

      const formData = new FormData();
      formData.append('page_name', cmsList.value.page_name);
      formData.append('slug', cmsList.value.slug);
      formData.append('title', cmsList.value.title);
      formData.append('description', cmsList.value.description);
      // formData.append('publish_status', cmsList.value.publish_status);
      formData.append('html_description', cmsList.value.html_description);

      // formData.append('parent', parentSelected);
      formData.append('language', languageSelected);

      detail.value.forEach((item, index) => {
        if (item.title !== null) {
          formData.append(`details[${index}][title]`, item.title);
        }
        if (item.description !== null) {
          formData.append(`details[${index}][description]`, item.description);
        }
        if (item.sort_order !== null) {
          formData.append(`details[${index}][sort_order]`, item.sort_order);
        }
      });

      image.value.forEach((item) => formData.append('images', item));
      image.value.forEach((item, index) => formData.append(`image_sort_orders[${index}]`, index));
      // video.value.forEach((item, index) => formData.append(`video_sort_orders[${index}]`, index));
      // video.value.forEach((item) => formData.append('videos', item));
      formData.append('video_sort_orders[0]', 0);
      formData.append('videos', cmsList.value.videos);

      cmsPageService
        .cmslistUpdate(cmsList.value.id, formData)
        .then((res) => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          isLoading.value = false;
          successMsg.value = ' Page Has Been Updated Successfully';
          setTimeout(() => {
            successMsg.value = '';
            router.push('/cms-page');
          }, 3000);
        })
        .catch((error) => {
          isLoading.value = false;
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          errorMsg.value = error?.data;
          setTimeout(() => {
            errorMsg.value = '';
          }, 3000);
        });
    };

    const data = reactive({
      images: '',
      videos: '',
    });

    onMounted(() => {
      imageFileBtn = document.getElementById('image-file');
      editImageFileBtn = document.getElementById('edit-image-file');
      editVideoFileBtn = document.getElementById('video-file');
    });

    const editImageListener = async () => {
      if (editImageFileBtn.value) {
        const file = editImageFileBtn.files[0];
        cmsList.value.images.push(file);
        const url = await URL.createObjectURL(file);
        imageName.value.push(url);
        image.value.push(file);
      } else {
        console.log('no file selected');
      }
    };
    const editImageFile = () => {
      editImageFileBtn.click();
      editImageFileBtn.addEventListener('change', editImageListener);
    };

    const deleteImageFile = (index) => {
      image.value.splice(index, 1);
      imageName.value.splice(index, 1);
    };

    const previewVideo = () => {
      const videoSrc = document.getElementById('video-preview');
      const reader = new FileReader();

      reader.readAsDataURL(video.value);
      reader.addEventListener('load', () => {
        videoSrc.src = reader.result;
      });
    };

    const editVideoListener = () => {
      if (editVideoFileBtn.value) {
        const file = editVideoFileBtn.files[0];
        cmsList.value.videos = file;
        video.value = file;
        previewVideo();
      } else {
        console.log('no file selected');
      }
    };

    const editVideoFile = () => {
      editVideoFileBtn = document.getElementById('video-file');
      editVideoFileBtn.click();
      editVideoFileBtn.addEventListener('change', editVideoListener);
    };

    const deleteVideoFile = () => {
      video.value = '';
      cmsList.value.videos = '';
    };

    const deletecmslist = () => {
      isLoading.value = true;
      cmsPageService
        .cmslistDelete(id.value)
        .then(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          isLoading.value = false;
          successMsg.value = ' Page Has Been Deleted Successfully';
          setTimeout(() => {
            successMsg.value = '';
            router.push('/cms-page');
          }, 3000);
        })
        .catch((error) => {
          isLoading.value = false;
          errorMsg.value = error?.data;
          setTimeout(() => {
            errorMsg.value = '';
          }, 3000);
        });
    };
    const toolbarOptions = [
      ['bold', 'italic', 'underline'], // toggled buttons

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      //   [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      //   [{ direction: 'rtl' }], // text direction

      // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [
        {
          color: [
            '#605dc7',
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
            '#f8f6f3',
            '#3e3e3e',
          ],
        },
        {
          background: [
            '#605dc7',
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
            '#f8f6f3',
            '#3e3e3e',
          ],
        },
      ], // dropdown with defaults from theme
      //   [{ font: [] }],
      //   [{ align: [] }],
      ['link'],
      ['clean'], // remove formatting button
    ];

    const createSinglePage = async () => {
      let languageSelected = '';
      language.value.items.forEach((item) => {
        if (item.language === lang.value) {
          languageSelected = item.id;
        }
      });
      // const result = await v$.value.$validate();
      // if (result) {
      isLoading.value = true;
      const formData = new FormData();
      formData.append('page_name', cmsList.value.page_name);
      formData.append('slug', cmsList.value.slug);
      formData.append('html_description', cmsList.value.html_description);
      formData.append('language', languageSelected);
      formData.append('publish_status', cmsList.value.publish_status);

      cmsPageService
        .cmslistUpdate(cmsList.value.id, formData)
        .then(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          isLoading.value = false;
          successMsg.value = 'Page Has Been Updated Successfully';
          setTimeout(() => {
            successMsg.value = '';
            router.push('/cms-page');
          }, 3000);
        })
        .catch((error) => {
          isLoading.value = false;
          errorMsg.value = error?.data;
          setTimeout(() => {
            errorMsg.value = '';
          }, 3000);
        });
      // }
    };
    return {
      cmsList,
      cmsListDetails,
      cmsListDetailsById,
      editCmslist,
      deletecmslist,
      data,
      editVideoFile,
      editVideoListener,
      editImageListener,
      editImageFile,
      searchCms,
      currentOffset,
      perPage,
      SearchData,
      add,
      deleteDetail,
      detail,
      image,
      video,
      errorMsg,
      successMsg,
      deleteImageFile,
      deleteVideoFile,
      imageName,
      lang,
      language,
      parent,
      isLoading,
      toolbarOptions,
      createSinglePage,
    };
  },
};
</script>
