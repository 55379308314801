<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-people"></i> Edit User</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to update user"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="" class="form-label">Name</label>
                    <input class="form-control" type="text" v-model="userDetails.name" />
                    <span class="text-danger" v-if="v$.name.$error">
                      {{ v$.name.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Email</label>
                    <input class="form-control" type="text" v-model="userDetails.email" />
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">DOB</label>
                    <input
                      class="form-control"
                      placeholder="DD/MM/YYYY"
                      type="text"
                      v-model="userDetails.dob"
                    />
                  </div> -->
                  <div class="col-md-6">
                    <div class="form-check form-switch mt-3 ms-3">
                      <label class="form-check-label">Active User </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="userDetails.isActive"
                        :true-value="true"
                        :false-value="false"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="form-check form-switch mt-3 ms-3">
                      <label class="form-check-label">Staff User </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="userDetails.isStaff"
                        :true-value="true"
                        :false-value="false"
                      />
                    </div>
                  </div> -->
                  <div class="col-md-6">
                    <div class="form-check form-switch mt-3 ms-3">
                      <label class="form-check-label">Admin User </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="userDetails.isAdmin"
                        :true-value="true"
                        :false-value="false"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="form-check form-switch mt-3 ms-3">
                      <label class="form-check-label">OnBoarding Completed </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="userDetails.onboardingCompleted"
                        :true-value="true"
                        :false-value="false"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-check form-switch mt-3 ms-3">
                      <label class="form-check-label">Account at Commercelayer </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="userDetails.accAtClayer"
                        :true-value="true"
                        :false-value="false"
                      />
                    </div>
                  </div> -->
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Story Download language update count</label>
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      v-model="userDetails.storyDownload"
                    />
                  </div> -->
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-text">
                        <img v-if="imageURL" :src="imageURL" alt="image" style="width: 60px" />
                        <div v-else>No Image</div>
                      </div>
                      <div class="upload-block ms-3 mt-4">
                        <input
                          type="file"
                          class="form-control-file"
                          id="user-image-file"
                          accept="image/*"
                          hidden="hidden"
                        />
                        <button
                          class="btn btn-primary form-control"
                          type="button"
                          id="custom-button"
                          @click="imageFile()"
                        >
                          <span>Image <i class="bi bi-upload" aria-hidden="true"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Last LoggedIn</label>
                    <input class="form-control" type="text" v-model="userDetails.lastLoggedIn" />
                  </div> -->
                  <!-- <div class="col-md-6">
                    <div class="form-check form-switch mt-3 ms-3">
                      <label class="form-check-label">Is Registered </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="userDetails.isRegistered"
                        :true-value="true"
                        :false-value="false"
                      />
                    </div>
                  </div> -->
                  <div class="col-md-6">
                    <label for="" class="form-label">Story Download Language</label>
                    <!-- <input class="form-control" type="text" v-model="userDetails.storyLanguage" /> -->
                    <select name="" id="" class="form-select" v-model="storyLang">
                      <option value="">select the Language</option>
                      <option
                        v-for="(lang, index) in language.items"
                        :key="index"
                        :value="lang.language"
                      >
                        {{ lang?.language }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Primary Language</label>
                    <!-- <input type="text" class="form-control" v-model="userDetails.primaryLanguage" /> -->
                    <select name="" id="" class="form-select" v-model="primaryLang">
                      <option value="">select the Language</option>
                      <option
                        v-for="(lang, index) in language.items"
                        :key="index"
                        :value="lang.language"
                      >
                        {{ lang?.language }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Groups</label>
                    <VueMultiselect
                      v-if="userGroups.items"
                      v-model="userDetails.groups"
                      :options="userGroups.items"
                      :multiple="true"
                      trackBy="title"
                      label="title"
                      :taggable="true"
                    >
                    </VueMultiselect>
                  </div>
                </div>
                <div class="container text-end mt-4">
                  <button type="button" @click="updateUser()" class="btn btn-outline-success me-1">
                    Update
                  </button>
                  <router-link to="/users" type="button" class="btn btn-outline-danger"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import {
  ref, computed, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import { useCookies } from 'vue3-cookies';
import {
  required, email, helpers, between,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import UserService from '@/services/UserService';
import Loader from '@/components/commons/Loader.vue';
import { formatUTC } from '@/helper/CommonHelper';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const { cookies } = useCookies({ secure: true });

const userService = new UserService();

let imageFileBtn;
export default {
  name: 'AddNewUser',
  components: {
    DashboardTemplate,
    VueMultiselect,
    Loader,
    ErrorDisplay,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);
    const selectedGroups = ref([]);
    const convertedImage = ref('');
    const routeId = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const imageURL = ref('');
    const langPerPage = ref(0);
    const searchLanguage = ref('');
    const currentOffset = ref(0);
    const storyLang = ref('');
    const primaryLang = ref('');

    const langData = {
      searchLanguage,
      currentOffset,
      langPerPage,
    };

    store.dispatch('fetchAllLanguages', langData);

    const userDetails = reactive({
      name: '',
      email: '',
      password: '',
      image: '',
      dob: '',
      isActive: false,
      isAdmin: false,
      storyLanguage: '',
      primaryLanguage: '',
      storyDownload: 0,
      groups: [],
      // isStaff: false,
      // onboardingCompleted: false,
      // accAtClayer: false,
      lastLoggedIn: '',
      // isRegistered: false,
    });
    routeId.value = route.params.id;
    isLoading.value = true;

    const rules = {
      name: { required: helpers.withMessage('Please enter a name', required) },
    };

    const v$ = useVuelidate(rules, userDetails);

    const convertImageUrlToFile = async (url) => {
      try {
        if (!url) {
          userDetails.image = '';
        } else {
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', {
            type: blob.type,
          });
          userDetails.image = file;
        }
      } catch (error) {
        console.error('Error converting image URL to file:', error);
      }
    };

    store.dispatch('fetchUserById', routeId.value)
      .then((res) => {
        userDetails.name = res.data.name;
        userDetails.email = res.data.email;
        convertImageUrlToFile(res.data.image);
        imageURL.value = res.data.image;
        if (res.data.dob) {
          userDetails.dob = formatUTC(res.data.dob, 'DD/MM/YYYY');
        } else {
          userDetails.dob = res.data.dob;
        }
        userDetails.isActive = res.data.is_active;
        userDetails.isStaff = res.data.is_staff;
        userDetails.isAdmin = res.data.is_admin;
        userDetails.onboardingCompleted = res.data.onboarding_completed;
        userDetails.accAtClayer = res.data.account_created_at_commercelayer;
        userDetails.storyDownload = res.data.story_download_language_updated_count;
        userDetails.lastLoggedIn = res.data.last_logged_in;
        userDetails.isRegistered = res.data.is_registered;
        userDetails.storyLanguage = res.data.story_download_language;
        userDetails.primaryLanguage = res.data.primary_language;
        primaryLang.value = res.data.primary_language?.language;
        storyLang.value = res.data.story_download_language?.language;
        userDetails.groups = res.data.groups;
        isLoading.value = false;
      });

    store.dispatch('fetchUserGroups');

    const userProfile = computed(() => store.getters.getUser);
    const userImage = computed(() => store.getters.getUser);
    const userGroups = computed(() => store.getters.getUserGroups);

    const imageListener = () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.files[0];
        userDetails.image = file;
        const blob = new Blob([file], { type: file.type });
        imageURL.value = URL.createObjectURL(blob);
      } else {
        console.log('no file selected');
      }
    };

    const imageFile = () => {
      imageFileBtn = document.getElementById('user-image-file');

      imageFileBtn.click();
      imageFileBtn.addEventListener('change', imageListener);
    };

    const language = computed(() => store.getters.getLanguage);

    const updateUser = async () => {
      const result = await v$.value.$validate();
      if (result) {
        let storyLanguageSelected = '';
        let primaryLanguageSelected = '';

        language.value.items.forEach((item) => {
          if (item.language === storyLang.value) {
            storyLanguageSelected = item.code;
          }
          if (item.language === primaryLang.value) {
            primaryLanguageSelected = item.code;
          }
        });

        isLoading.value = true;
        const formData = new FormData();
        formData.append('name', userDetails.name);
        formData.append('email', userDetails.email);
        formData.append('image', userDetails.image);
        formData.append('is_active', userDetails.isActive);
        formData.append('is_admin', userDetails.isAdmin);
        formData.append('story_download_language', storyLanguageSelected);
        formData.append('primary_language', primaryLanguageSelected);
        if (Array.isArray(userDetails.groups) && !userDetails.groups.length) {
          formData.set('groups', JSON.stringify([]));
        }
        userDetails.groups.forEach((item) => item?.slug && formData.append('groups[]', item?.slug));

        userService
          .updateUser(routeId.value, formData)
          .then((response) => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            successMsg.value = ' User Has Been Updated Successfully';

            setTimeout(() => {
              successMsg.value = '';
              router.push('/users');
            }, 3000);
            isLoading.value = false;
          })
          .catch((error) => {
            isLoading.value = false;
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };

    return {
      userGroups,
      userDetails,
      // addUser,
      imageFile,
      selectedGroups,
      isLoading,
      userProfile,
      formatUTC,
      convertedImage,
      updateUser,
      routeId,
      userImage,
      errorMsg,
      successMsg,
      imageURL,
      language,
      storyLang,
      primaryLang,
      v$,
    };
  },
};
</script>

<style></style>
