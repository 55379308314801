<template>
  <div id="sidebar-wrapper">
    <PageSidebar />
  </div>
  <div id="content-wrapper">
    <TopNavBar />
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 fade-in">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import TopNavBar from '@/components/commons/TopNavBar.vue';
import PageSidebar from '@/components/commons/Sidebar.vue';

export default {
  name: 'About',
  props: [],
  components: {
    TopNavBar,
    PageSidebar,
  },
};
</script>
