<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-headphones"></i> Edit Headphone</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to update headphone"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="" class="form-label">Name</label>
                    <input type="text" class="form-control" v-model="editStoryPhone.name" />
                    <span class="text-danger" v-if="v$.name.$error">
                      {{ v$.name.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Model Name</label>
                    <select
                        id="parent"
                        name="parent"
                        class="select form-select"
                        v-model="model"
                      >
                        <option
                          v-for="(item, index) in storyPhoneModels.items"
                          :key="index"
                          :value="item?.model_name"
                        >
                          {{ item?.model_name }}
                        </option>
                      </select>

                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Device Id</label>
                    <input type="text" class="form-control" v-model="editStoryPhone.deviceId" disabled />
                    <span class="text-danger" v-if="v$.deviceId.$error">
                      {{ v$.deviceId.$errors[0].$message }}*
                    </span>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="form-check form-switch mt-5 ms-3">
                      <label class="form-check-label" for="flexSwitchCheckChecked">For kids</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="editStoryPhone.forKids"
                        id="flexSwitchCheckChecked"
                        :true-value="true"
                        :false-value="false"
                      />
                    </div>
                  </div> -->
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Paried</label>
                    <input type="date" class="form-control" v-model="editStoryPhone.paired" />
                  </div> -->
                  <div class="col-md-6">
                    <label for="inputOwner" class="form-label">Owner</label>
                    <VueMultiselect
                      v-if="user.items"
                      v-model="editStoryPhone.owner"
                      :close-on-select="true"
                      :clear-on-select="false"
                      placeholder="Select one owner"
                      label="email"
                      track-by="email"
                      :options="user?.items"
                    />
                  </div>

                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Kid Birthday</label>
                    <input type="date" class="form-control" v-model="editStoryPhone.kidBirthday" />
                  </div> -->
                  <div class="col-md-6">
                    <label for="" class="form-label">Remote Id</label>
                    <input type="text" class="form-control" v-model="editStoryPhone.remoteId" />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Max Volume DB</label>
                    <select class="form-select" id="dropdown" v-model="editStoryPhone.maxVolumeDb">
                      <option value=75>75</option>
                      <option value=85>85</option>
                      <option value=94>94</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Mac Address</label>
                    <input type="text" class="form-control" v-model="editStoryPhone.macAddress" />
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Last Downloaded</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="editStoryPhone.lastDownloaded"
                    />
                  </div> -->
                </div>
                <div class="container text-end mt-4 mb-3">
                  <button
                    type="button"
                    @click="editUploadStoryPhone()"
                    class="btn btn-outline-success me-1"
                  >
                    Update
                  </button>
                  <router-link to="/headphones" type="button" class="btn btn-outline-danger"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import {
  required, email, helpers, between,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';

import { formatUTC } from '@/helper/CommonHelper';

import StoryPhoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const storyPhoneService = new StoryPhoneService();

export default {
  name: 'EditHeadphone',
  props: [],
  components: {
    Loader,
    DashboardTemplate,
    VueMultiselect,
    ErrorDisplay,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const routePath = ref('');
    routePath.value = route.path;
    const isLoading = ref(false);
    const router = useRouter();
    const addModel = ref(false);
    const owner = ref('');
    const searchStoryPhone = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const model = ref('');
    const delErrorMsg = ref('');
    const storyPhoneId = ref('');

    storyPhoneId.value = route.params.id;

    const editStoryPhone = reactive({
      paired: '',
      name: '',
      deviceId: '',
      forKids: false,
      kidBirthday: '',
      lastDownloaded: '',
      maxVolumeDb: 0,
      macAddress: '',
      remoteId: '',
      model_name: '',
      owner: '',

    });

    store.dispatch('fetchStoryPhoneModels');

    const storyPhoneModels = computed(() => store.getters.getstoryPhoneModels);

    const getStoryPhoneById = async () => {
      isLoading.value = true;
      store.dispatch('fetchStoryPhoneById', storyPhoneId.value).then(async (res) => {
        // editStoryPhone.paired = res.data.paired;
        editStoryPhone.name = res.data.name;
        editStoryPhone.deviceId = res.data.device_id;
        editStoryPhone.forKids = res.data.for_kids;
        editStoryPhone.maxVolumeDb = res.data.max_volume_db;
        editStoryPhone.macAddress = res.data.mac_address;
        editStoryPhone.remoteId = res.data.remote_id;
        editStoryPhone.model_name = res.data.details;
        model.value = res.data.details?.model_name;
        editStoryPhone.owner = res.data.owner;

        isLoading.value = false;
      });
    };
    getStoryPhoneById();

    const rules = {
      name: { required: helpers.withMessage('Please enter a name', required) },
      deviceId: { required: helpers.withMessage('Please enter a device Id', required) },
    };

    const v$ = useVuelidate(rules, editStoryPhone);

    const currentOffset = ref(0);
    const perPageStoryphones = ref(10);
    const perPageUser = ref(0);
    const pageCount = ref(0);
    const page = ref(1);
    isLoading.value = true;

    const SearchData = {
      searchStoryPhone,
      currentOffset,
      perPageStoryphones,
    };
    store.dispatch('fetchAllStoryPhone', SearchData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / perPageStoryphones.value);

      isLoading.value = false;
    });

    const searchUser = ref('');

    const searchUserData = {
      searchUser,
      currentOffset,
      perPageUser,
    };
    store.dispatch('fetchAllUsers', searchUserData);

    const user = computed(() => store.getters.getAllUsers);

    const storyPhones = computed(() => store.getters.getAllStoryPhone);
    const storyPhonesDetals = computed(() => store.getters.getStoryPhoneById);

    const editUploadStoryPhone = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        const Data = {
          name: editStoryPhone.name,
          device_id: editStoryPhone.deviceId,
          max_volume_db: editStoryPhone.maxVolumeDb,
          mac_address: editStoryPhone.macAddress,
          remote_id: editStoryPhone.remoteId,
          owner: editStoryPhone?.owner?.id,
          details: model.value,
        };
        storyPhoneService.storyPhoneUpdate(storyPhoneId.value, Data).then((res) => {
          isLoading.value = false;
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          successMsg.value = ' HeadPhone Has Been Updated Successfully';
          setTimeout(() => {
            successMsg.value = '';
            router.push('/headphones');
          }, 3000);
          owner.value = '';
        }).catch((error) => {
          isLoading.value = false;
          errorMsg.value = error?.data;
          setTimeout(() => {
            errorMsg.value = '';
          }, 3000);
        });
      }
    };

    return {
      isLoading,
      storyPhones,
      user,
      editStoryPhone,
      getStoryPhoneById,
      editUploadStoryPhone,
      storyPhoneId,
      storyPhonesDetals,
      formatUTC,
      searchStoryPhone,
      currentOffset,
      pageCount,
      page,
      routePath,
      owner,
      successMsg,
      errorMsg,
      delErrorMsg,
      v$,
      addModel,
      storyPhoneModels,
      model,
    };
  },
};
</script>
