import * as types from './mutation-types';

const mutations = {
  [types.PLAYLIST_GENRES](state, playlistGenres) {
    state.playlistGenres = playlistGenres;
  },
  [types.BRANDS](state, brand) {
    state.brand = brand;
  },
  [types.PRODUCT_TYPE](state, productType) {
    state.productType = productType;
  },
  [types.PRODUCT_SKU](state, productBySku) {
    state.productBySku = productBySku;
  },
  [types.SHIPPING_CATEGORIES](state, shippingCategories) {
    state.shippingCategories = shippingCategories;
  },
  [types.PRICE_LISTS](state, priceLists) {
    state.priceLists = priceLists;
  },
  [types.STOCK_LOCATIONS](state, stockLocations) {
    state.stockLocations = stockLocations;
  },
  [types.REMOVE_PRODUCT_SKU](state) {
    state.productBySku = [];
  },
};

export default mutations;
