<!-- eslint-disable vue/valid-v-model -->
<!-- eslint-disable no-shadow -->
<!-- eslint-disable no-undef -->
<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>

    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class=""></i> Edit Widget</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to add widget"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="title" class="form-label">Widget Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="cmsWidgetEdit.widgetName"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="title" class="form-label">Title</label>
                    <input type="text" class="form-control" v-model="cmsWidgetEdit.title" />
                  </div>
                  <div class="col-md-6">
                    <label for="title" class="form-label">Description</label>
                    <textarea
                      rows="10"
                      cols="30"
                      name=""
                      id=""
                      type="text"
                      class="form-control"
                      v-model="cmsWidgetEdit.content"
                    ></textarea>
                  </div>
                  <div class="col-md-6">
                    <label for="parent" class="form-label">Parent</label>
                    <form action="">
                      <input type="text" class="form-control" v-model="parent" disabled/>
                      <!-- <select id="parent" disable name="parent" class="select form-select" v-model="parent">
                        <option
                          v-for="(item, index) in cmsListDetails.items"
                          :key="index"
                          :value="item.page_name"
                        >
                          {{ item.page_name }}
                        </option>
                      </select> -->
                    </form>
                  </div>

                  <!-- <div class="col-md-6">
                    <label for="inputType" class="form-label">Language</label>
                    <select name="" id="inputType" class="form-select" v-model="lang">
                      <option value="">select the Language</option>
                      <option
                        v-for="(type, index) in language.items"
                        :key="index"
                        :value="type.language"
                      >
                        {{ type.language }}
                      </option>
                    </select>
                  </div> -->
                  <!-- <div class="col-md-6">
                    <label for="status" class="form-label">publish_status</label>
                    <form action="">
                      <select
                        id="status"
                        name="status"
                        class="select form-select"
                        v-model="cmsWidgetEdit.publish_status"
                      >
                        <option value="draft">draft</option>
                        <option value="published">published</option>
                        <option value="deleted">deleted</option>
                      </select>
                    </form>
                  </div> -->
                  <!-- Widget Image Section-->
                  <div class="col-md-12 border">
                    <h5 class="mt-4">Widget Images</h5>
                    <form class="p-5">
                      <div class="form-group row">
                        <label for="title" class="form-label col-md-2">Image</label>
                        <div class="col-md-6 mb-4">
                          <input
                            type="file"
                            class="form-control-file"
                            id="image-file"
                            accept="image/*"
                            hidden="hidden"
                          />
                          <button
                            class="btn btn-primary form-control"
                            type="button"
                            id="custom-button"
                            @click="uploadFile()"
                          >
                            <span>Add image <i class="bi bi-upload" aria-hidden="true"></i></span>
                          </button>
                        </div>
                      </div>
                      <!-- <div class="form-group row">
                          <label for="title" class="form-label col-md-2">Sort Order</label>
                          <div class="col-md-6 mb-4">
                            <input type="number" class="form-control" />
                          </div>
                        </div> -->
                      <div class="form-group row">
                        <label for="title" class="form-label col-md-2">Image Names:</label>
                        <div class="col-md-6">
                          <ul>
                            <li v-for="(img, index) in imageName" :key="index">
                              <img style="width: 100px" :src="img" alt="" class="mt-2 me-4" />
                              <button
                                type="button"
                                class="btn btn-outline-danger rounded-5 bi bi-trash"
                                @click="deleteImageFile(img, index)"
                              ></button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- Details Section -->
                  <div
                    class="col-md-12 border"
                    v-for="(item, counter) in newDetails"
                    v-bind:key="counter"
                  >
                    <div class="container text-end mt-3">
                      <span @click="deleteDetailsButton(counter)">x</span>
                    </div>
                    <h5>Details</h5>
                    <form class="p-5">
                      <div class="form-group row">
                        <label for="title" class="form-label col-md-2">Title</label>
                        <div class="col-md-12 mb-4">
                          <!-- <input type="text" class="form-control" v-model.lazy="item.title" /> -->
                          <QuillEditor
                            theme="snow"
                            :toolbar="toolbarOptions"
                            v-model:content="item.title"
                            contentType="html"
                          />
                        </div>
                      </div>
                      <div class="form-group mt-5 row">
                        <label for="title" class="form-label col-md-2">Description</label>
                        <div class="col-md-12 mb-4">
                          <QuillEditor
                            theme="snow"
                            :toolbar="toolbarOptions"
                            v-model:content="item.description"
                            contentType="html"
                          />
                          <!-- <input type="text" class="form-control" v-model.lazy="item.description" /> -->
                        </div>
                      </div>
                      <div class="form-group mt-5 row">
                        <label for="title" class="form-label col-md-2">Image</label>
                        <div class="col-md-6 mb-4">
                          <input
                            type="file"
                            class="form-control-file"
                            id="detail-image-file"
                            accept="image/*"
                            hidden="hidden"
                          />
                          <button
                            class="btn btn-primary form-control"
                            type="button"
                            id="custom-button"
                            @click="widgetImageFile(counter)"
                          >
                            <span
                              >Edit existing image <i class="bi bi-upload" aria-hidden="true"></i
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div v-if="item.image" class="form-group row">
                        <label for="title" class="form-label col-md-2">Image Preview:</label>
                        <div class="col-md-6">
                          <ul>
                            <li>
                              <img
                                style="width: 100px"
                                :src="item.image"
                                alt=""
                                class="mt-2 me-4"
                              />
                              <button
                                type="button"
                                class="btn btn-outline-danger rounded-5 bi bi-trash"
                                @click="deleteWidgetImage(counter)"
                              ></button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="title" class="form-label col-md-2">Sort Order</label>
                        <div class="col-md-6 mb-4">
                          <input
                            type="number"
                            min="0"
                            class="form-control"
                            v-model.lazy="item.sort_order"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <button @click="newDetailsButton()" class="btn btn-primary mt-4">Add more details</button>
                </div>
              </div>
              <div class="text-end mt-5">
                <button class="btn btn-primary mx-3" @click="createCmslist()">Update</button>
                <!-- <button class="btn btn-primary" @click="deletecmslist()">Delete</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script>
import { useStore } from 'vuex';
import {
  ref, reactive, onMounted, computed,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { QuillEditor, Quill } from '@vueup/vue-quill';
import CmsWidgetService from '@/services/CmsWidgetService';
import CmsPageService from '@/services/CmsPageService';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const cmsWidgetService = new CmsWidgetService();
const cmsPageService = new CmsPageService();
let imageFileBtn;
let widgetImageFileBtn;

export default {
  name: 'AddCmsWidget',
  components: {
    DashboardTemplate,
    Loader,
    QuillEditor,
    ErrorDisplay,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const Details = ref([]);
    const imageUploader = ref(null);
    const isLoading = ref(false);
    const searchCms = ref('');
    const currentOffset = ref(0);
    const perPage = ref(0);
    const input = ref([]);
    const language = ref([]);
    const cmsList = ref([]);
    const image = ref([]);
    const imageName = ref([]);
    const parent = ref('');
    const video = ref([]);
    const widgetId = ref('');
    const lang = ref('');
    const widgetImage = ref([]);
    const count = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const selectedLang = ref('');
    const newImage = ref([
      {
        image: '',
        sortOrder: '',
        imageName: '',
      },
    ]);

    const newDetails = ref([
      {
        title: '',
        description: '',
        image: '',
        sort_order: null,
        detailImage: '',
      },
    ]);

    const newDetailsButton = () => {
      newDetails.value.push({
        title: '',
        description: '',
        image: '',
        sort_order: null,
        detailImage: ',',
      });
      widgetImage.value.push(null);
    };

    widgetId.value = route.params.id;

    const convertImageUrlToFile = async (url, index) => {
      try {
        if (url === null) {
          widgetImage.value.push(null);
          newDetails.value[index].detailImage = url;
        } else {
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', {
            type: blob.type,
          });
          widgetImage.value.push(file);
          newDetails.value[index].detailImage = file;
        }
      } catch (error) {
        console.error('Error converting image URL to file:', error);
      }
    };

    const convertWdigetImageToFile = async (url) => {
      try {
        if (url === null) {
          image.value.push(null);
        } else {
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', {
            type: blob.type,
          });
          image.value.push(file);
        }
      } catch (error) {
        console.error('Error converting image URL to file:', error);
      }
    };

    const detailImageListener = async () => {
      if (widgetImageFileBtn.value) {
        const file = widgetImageFileBtn.files[0];
        const url = await URL.createObjectURL(file);
        newDetails.value[count.value].image = url;
        newDetails.value[count.value].detailImage = file;
        widgetImage.value[count.value] = file;
        widgetImageFileBtn.value = '';
      } else {
        console.log('no file selected');
      }
    };

    const widgetImageFile = (counter) => {
      widgetImageFileBtn.click();
      count.value = counter;
      widgetImageFileBtn.addEventListener('change', detailImageListener);
    };

    const cmsWidgetEdit = reactive({
      widgetName: '',
      title: '',
      content: '',
      parent: '',
      language: '',
      publish_status: '',
      details: [],
      images: [],
    });

    store.dispatch('fetchCmsWidgetdetailsById', widgetId.value).then((response) => {
      cmsWidgetEdit.widgetName = response.data.widget_name;
      cmsWidgetEdit.title = response.data.title;
      cmsWidgetEdit.content = response.data.content;
      cmsWidgetEdit.parent = response.data.parent;
      parent.value = response.data.parent.page_name;
      cmsWidgetEdit.language = response.data.language;
      lang.value = response.data.language.language;
      cmsWidgetEdit.publish_status = response.data.publish_status;
      cmsWidgetEdit.details = response.data.details;
      newDetails.value = response.data.details;
      response.data.images.forEach((item) => {
        imageName.value.push(item.image_url);
        convertWdigetImageToFile(item.image_url);
      });
      response.data.details.forEach((item, index) => {
        convertImageUrlToFile(item.image, index);
      });
      cmsWidgetEdit.images = response.data.images;
    });

    const newImageButton = () => {
      newImage.value.push({
        image: '',
        sortOrder: '',
        imageName: '',
      });
    };

    const deleteWidgetSection = (counter) => {
      newImage.value.splice(counter, 1);
    };

    const deleteWidgetImage = (counter) => {
      newDetails.value[counter].image = null;
      newDetails.value[counter].detailImage = null;
      widgetImage.value[counter] = '';
    };

    const deleteDetailsButton = (counter) => {
      newDetails.value.splice(counter, 1);
      widgetImage.value[counter] = null;
    };

    const cmsWidgetDetails = computed(() => store.getters.getCmsWidgetDetailsById);

    onMounted(() => {
      imageFileBtn = document.getElementById('image-file');
      widgetImageFileBtn = document.getElementById('detail-image-file');
    });

    const deleteImageFile = (img, index) => {
      image.value.splice(index, 1);
      imageName.value.splice(index, 1);
    };

    const imageListener = async () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.files[0];
        cmsWidgetEdit.images = file;
        const url = await URL.createObjectURL(file);
        imageName.value.push(url);
        image.value.push(file);
        imageFileBtn.value = '';
      } else {
        console.log('no file selected');
      }
    };

    const uploadFile = () => {
      imageFileBtn.click();
      imageFileBtn.addEventListener('change', imageListener);
    };

    const SearchData = {
      searchCms,
      currentOffset,
      perPage,
      selectedLang,
    };
    store.dispatch('fetchAllCmslists', SearchData);

    const cmsListDetails = computed(() => store.getters.getCmsLists);

    const createCmslist = async () => {
      isLoading.value = true;
      let parentSelected = '';
      let languageSelected = '';

      const sortedArray = computed(() => [...newDetails.value].sort((a, b) => {
        if (a?.image && !b?.image) {
          return -1; // a comes before b
        }
        if (!a?.image && b?.image) {
          return 1; // b comes before a
        }
        return 0; // maintain original order
      }));

      cmsListDetails.value.items.forEach((item) => {
        if (item.page_name === parent.value) {
          parentSelected = item.id;
        }
      });

      language.value.items.forEach((item) => {
        if (item.language === lang.value) {
          languageSelected = item.id;
        }
      });

      const formData = new FormData();
      formData.append('widget_name', cmsWidgetEdit.widgetName);
      formData.append('title', cmsWidgetEdit.title);
      // formData.append('content', cmsWidgetEdit.content);
      formData.append('parent', parentSelected);
      // formData.append('publish_status', cmsWidgetEdit.publish_status);
      // formData.append('language', languageSelected);
      image.value.forEach((item, index) => formData.append(`image_sort_orders[${index}]`, index));
      image.value.forEach((item) => formData.append('images', item));
      sortedArray.value.forEach(async (item, index) => {
        if (item.title) {
          formData.append(`details[${index}][title]`, item.title);
        }
        if (item.description) {
          formData.append(`details[${index}][description]`, item.description);
        }
        if (item.sort_order) {
          formData.append(`details[${index}][sort_order]`, item.sort_order);
        }
        if (item.detailImage) {
          formData.append('details_images', item.detailImage);
        }

        // formData.append('details_images', sortedImage.value[index]);
      });
      cmsWidgetService
        .editCmsWidget(widgetId.value, formData)
        .then((res) => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          isLoading.value = false;
          successMsg.value = ' Widget Has Been Updated Successfully';
          setTimeout(() => {
            successMsg.value = '';
            router.push('/cms-widgets');
          }, 3000);
        })
        .catch((error) => {
          isLoading.value = false;
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          errorMsg.value = error?.data;
          setTimeout(() => {
            errorMsg.value = '';
          }, 3000);
        });
    };

    const deletecmslist = () => {
      isLoading.value = true;
      cmsWidgetService
        .deleteCmsWidget(widgetId.value)
        .then(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          isLoading.value = false;
          successMsg.value = ' Widget Has Been Deleted Successfully';
          setTimeout(() => {
            successMsg.value = '';
            router.push('/cms-widgets');
          }, 3000);
        })
        .catch((error) => {
          isLoading.value = false;
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          errorMsg.value = error?.data;
          setTimeout(() => {
            errorMsg.value = '';
          }, 3000);
        });
    };

    cmsPageService.getCmsLanguage().then((res) => {
      language.value = res.data;
    });

    const toolbarOptions = [
      ['bold', 'italic', 'underline'], // toggled buttons

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      //   [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      //   [{ direction: 'rtl' }], // text direction

      // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [
        {
          color: [
            '#605dc7',
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',

            '#607080',
            '#f8f9fa',
            '#e9ecef',
            '#dee2e6',
            '#ced4da',
            '#adb5bd',
            '#6c757d',
            '#495057',
            '#343a40',
            '#212529',
            '#435ebe',
            '#6610f2',
            '#6f42c1',
            '#d63384',
            '#dc3545',
            '#fd7e14',
            '#ffc107',
            '#198754',
            '#20c997',
            '#0dcaf0',
            '#25396f',
            '#1b1b28',
            '#187de4',
          ],
        },
        {
          background: [
            '#605dc7',
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
            '#607080',
            '#f8f9fa',
            '#e9ecef',
            '#dee2e6',
            '#ced4da',
            '#adb5bd',
            '#6c757d',
            '#495057',
            '#343a40',
            '#212529',
            '#435ebe',
            '#6610f2',
            '#6f42c1',
            '#d63384',
            '#dc3545',
            '#fd7e14',
            '#ffc107',
            '#198754',
            '#20c997',
            '#0dcaf0',
            '#25396f',
            '#1b1b28',
            '#187de4',
          ],
        },
      ], // dropdown with defaults from theme
      //   [{ font: [] }],
      //   [{ align: [] }],
      ['link'],
      ['clean'], // remove formatting button
    ];
    return {
      createCmslist,
      uploadFile,
      image,
      video,
      cmsList,
      Details,
      input,
      language,
      cmsListDetails,
      newImage,
      newImageButton,
      deleteWidgetSection,
      deleteWidgetImage,
      newDetailsButton,
      deleteDetailsButton,
      cmsWidgetDetails,
      cmsWidgetEdit,
      newDetails,
      widgetImageFile,
      imageName,
      deleteImageFile,
      lang,
      widgetImage,
      parent,
      isLoading,
      deletecmslist,
      successMsg,
      errorMsg,
      toolbarOptions,
      imageUploader,
    };
  },
};
</script>
