<template>
  <DashboardTemplate>
    <div class="col pt-3 fade-in">

    <div class="row mt-4">
      <div class="col-md-4 mb-3 mb-sm-0">
        <div class="card shadow-sm">
          <div class="card-body">
            <h5 class="card-title">Onboarded Headphones</h5>
            <p class="card-text">
              With supporting text below as a natural lead-in to additional content.
            </p>
            <a @click="test()" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-3 mb-sm-0">
        <div class="card shadow-sm">
          <div class="card-body">
            <h5 class="card-title">Number of Users</h5>
            <p class="card-text">
              With supporting text below as a natural lead-in to additional content.
            </p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-3 mb-sm-0">
        <div class="card shadow-sm">
          <div class="card-body">
            <h5 class="card-title">Total Playlists</h5>
            <p class="card-text">
              With supporting text below as a natural lead-in to additional content.
            </p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
      <div class="col-md-12 pt-4">
        <div class="card shadow-sm">
          <div class="card-body">
            <h5 class="card-title">Total Shields</h5>
            <p class="card-text">
              With supporting text below as a natural lead-in to additional content.
            </p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
    </div>
    </div>
  </DashboardTemplate>
</template>

<script lang="js">
import { useCookies } from 'vue3-cookies';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';

const axios = require('axios');

const { cookies } = useCookies({ secure: true });

export default {
  name: 'DashBoard',
  props: [],
  components: {
    DashboardTemplate,
  },
  setup() {
    // const test = () => {
    //   cookies.remove('storyPhoneAdminCookie');
    // };
    return { };
  },
};
</script>
