<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <!-- <div class="col">
              <h1>
                <router-link to="/headphones"><i class="bi bi-arrow-left"></i></router-link>
              </h1>
            </div> -->
            <div class="col">
              <h1><i class="bi bi-headphones"></i>Deleted HeadPhones</h1>
            </div>

            <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
              <div class="row justify-content-end">
                <div class="col">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Select Deleted or Active Items Here"
                  >
                    <router-link
                      to="/headphones"
                      class="btn btn-outline-success rounded-start-5 tooltip-wrap"
                      :class="{ active: routePath === '/headphones' }"
                    >
                      <i class="bi bi-radioactive"></i>
                      Active
                      <span class="tooltip">Active Items</span>
                    </router-link>
                    <router-link
                      to="/deleted-headphone"
                      class="btn btn-outline-danger rounded-end-5 tooltip-wrap"
                      :class="{ active: routePath === '/deleted-headphone' }"
                    >
                      <i class="bi bi-trash-fill"></i>
                      Deleted
                      <span class="tooltip">Deleted Items</span>
                    </router-link>
                  </div>
                </div>
                <div class="col-auto">
                  <label for="inputPassword2" class="visually-hidden">Search</label>
                  <input
                    type="text"
                    class="form-control rounded-5"
                    placeholder="Search"
                    v-model="searchStoryPhone"
                    v-on:keyup.enter="searchClick()"
                  />
                </div>
                <div class="col-auto tooltip-wrap">
                  <button
                    type="submit"
                    class="btn btn-outline-primary mb-3 rounded-5"
                    @click="searchClick()"
                  >
                    <i class="bi bi-search"></i>
                    <span class="tooltip">Search Here</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="successMsg" class="card text-success text-center border-success rounded-5 mb-3">
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Something went wrong"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>UUID</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Device ID</th>
                        <th>Owner</th>
                        <th>Model</th>
                        <th>Restore</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="storyPhones?.total === 0">
                        <td scope="col" colspan="9" class="text-center text-primary pt-3 pb-3">
                          Not Found
                        </td>
                      </tr>

                      <tr v-else v-for="(storyPhone, index) in storyPhones.items" :key="index">
                        <td>{{ storyPhone.id }}</td>
                        <td v-if ="storyPhone.details" ><img :src="storyPhone.details.model_image" style="width: 50px" alt="" /></td>
                        <td v-else>No Image</td>
                        <td>{{ storyPhone.name }}</td>
                        <td>{{ storyPhone.device_id }}</td>
                        <td v-if="storyPhone.owner">
                          {{ storyPhone.owner.email }}
                        </td>
                        <td v-else>Not Assigned</td>
                        <td>{{ storyPhone?.details?.model_name }}</td>

                        <td class="text-center">
                          <button
                            class="btn btn-outline-primary bi bi-arrow-clockwise rounded-5"
                            @click="restoreHeadPhone(storyPhone.id)"
                          ></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <VuejsPaginateNext
              v-model="page"
              :page-count="pageCount"
              :click-handler="pageClick"
              :margin-pages="2"
              :page-range="3"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
            ></VuejsPaginateNext>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';

import { formatUTC } from '@/helper/CommonHelper';

import UserService from '@/services/UserService';
import StoryPhoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const storyPhoneService = new StoryPhoneService();
const userService = new UserService();

let imageFileBtn;
let editImageFileBtn;

export default {
  name: 'StoryPhone',
  props: [],
  components: {
    Loader,
    DashboardTemplate,
    VuejsPaginateNext,
    ErrorDisplay,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(false);
    const searchStoryPhone = ref('');
    const routePath = ref('');
    routePath.value = route.path;
    const perPageUser = ref(0);
    const storyPhoneId = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const pageCount = ref(0);
    const page = ref(1);
    isLoading.value = true;

    const SearchData = {
      searchStoryPhone,
      currentOffset,
      perPage,
    };
    store.dispatch('fetchDeletedStoryPhone', SearchData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / perPage.value);

      isLoading.value = false;
    });

    // userService.getUserDetails().then((res) => {
    //   user.value = res.data;
    // });
    const searchUser = ref('');

    const searchUserData = {
      searchUser,
      currentOffset,
      perPageUser,
    };
    store.dispatch('fetchAllUsers', searchUserData);

    const user = computed(() => store.getters.getAllUsers);
    const storyPhones = computed(() => store.getters.getDeletedStoryPhone);

    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchStoryPhone,
        currentOffset,
        perPage,
      };
      isLoading.value = true;
      store.dispatch(
        'fetchDeletedStoryPhone',
        search,
      ).then((response) => {
        isLoading.value = false;
        pageCount.value = Math.ceil(response.data.total / perPage.value);
      });
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;

      currentOffset.value = perPage.value * (pageNum - 1);
      const Data = {
        searchStoryPhone,
        currentOffset,
        perPage,
      };
      store.dispatch('fetchDeletedStoryPhone', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);

        isLoading.value = false;
      });
    };

    const restoreHeadPhone = (id) => {
      isLoading.value = true;
      storyPhoneService.restoreStoryPhone(id).then((res) => {
        store.dispatch('fetchDeletedStoryPhone', SearchData).then((response) => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          pageCount.value = Math.ceil(response.data.total / perPage.value);
          successMsg.value = res?.data?.success;
          isLoading.value = false;
          setTimeout(() => {
            successMsg.value = '';
          }, 3000);
        });
      }).catch((error) => {
        isLoading.value = false;
        errorMsg.value = error?.data;
        setTimeout(() => {
          errorMsg.value = '';
        }, 3000);
      });
    };

    return {
      isLoading,
      storyPhones,
      storyPhoneId,
      formatUTC,
      searchStoryPhone,
      searchClick,
      currentOffset,
      perPage,
      pageCount,
      page,
      pageClick,
      routePath,
      restoreHeadPhone,
      successMsg,
      errorMsg,
    };
  },
};
</script>
