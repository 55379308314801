import * as types from './mutation-types';

const mutations = {
  [types.STORY_TYPES](state, storyType) {
    state.storyType = storyType;
  },
  [types.STORIES](state, stories) {
    state.stories = stories;
  },
  [types.STORY_BY_ID](state, story) {
    state.story = story;
  },
  [types.DELETED_STORIES](state, deletedStories) {
    state.deletedStories = deletedStories;
  },
  [types.STORY_WITH_OUT_LIMIT](state, storyWithOutLimit) {
    state.storyWithOutLimit = storyWithOutLimit;
  },

};

export default mutations;
