import UserService from '@/services/UserService';
import * as types from './mutation-types';

const ent = require('ent');

const userService = new UserService();

export const fetchAllUsers = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    userService
      .getUserDetails(Data)
      .then((response) => {
        commit(types.USER, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchUserById = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    userService
      .getUserById(Data)
      .then((response) => {
        commit(types.SINGLE_USER, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchUserGroups = ({ commit }) => new Promise(
  (resolve, reject) => {
    userService
      .getUserGroups()
      .then((response) => {
        commit(types.USER_GROUPS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchUserProfile = ({ commit }) => new Promise(
  (resolve, reject) => {
    userService
      .getUserProfile()
      .then((response) => {
        commit(types.USER_PROFILE, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchDeletedUser = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    userService
      .getAllDeletedUser(Data)
      .then((response) => {
        commit(types.DELETED_USER, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
