/* eslint-disable no-undef */
import * as types from './mutation-types';

const mutations = {
  [types.FIRMWARELIST_DETAILS](state, firmwareLists) {
    state.firmwareLists = firmwareLists;
  },
  [types.FIRMWARELIST_DETAILS_BY_ID](state, firmwareListsById) {
    state.firmwareListsById = firmwareListsById;
  },

};
export default mutations;
