<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <DashboardTemplate>
    <div class="row p-3">
      <div class="col-md-12">
        <div class="row">
          <!-- <div class="col">
            <h1>
              <router-link to="/users"><i class="bi bi-arrow-left"></i></router-link>
            </h1>
          </div> -->
          <div class="col">
            <h1><i class="bi bi-people"></i>Deleted Users</h1>
          </div>

          <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
            <div class="row justify-content-end">
              <div class="col">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Select Deleted or Active Items Here"
                >
                  <router-link
                    to="/users"
                    class="btn btn-outline-success rounded-start-5 tooltip-wrap"
                    :class="{ active: routePath === '/users' }"
                  >
                    <i class="bi bi-radioactive"></i>
                    Active
                    <span class="tooltip">Active Items</span>
                  </router-link>
                  <router-link
                    to="/deleted-user"
                    class="btn btn-outline-danger rounded-end-5 tooltip-wrap"
                    :class="{ active: routePath === '/deleted-user' }"
                  >
                    <i class="bi bi-trash-fill"></i>
                    Deleted
                    <span class="tooltip">Deleted Items</span>
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <label for="inputPassword2" class="visually-hidden">Search</label>
                <input
                  type="text"
                  class="form-control rounded-5"
                  placeholder="Search"
                  v-model="searchUser"
                  v-on:keyup.enter="searchClick()"
                />
              </div>
              <div class="col-auto tooltip-wrap">
                <button
                  type="submit"
                  class="btn btn-outline-primary mb-3 rounded-5"
                  @click="searchClick()"
                >
                  <i class="bi bi-search"></i>
                  <span class="tooltip">Search Here</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="successMsg" class="card text-success text-center border-success rounded-5 mb-3">
          <div class="card-body">{{ successMsg }}</div>
        </div>
        <ErrorDisplay :error="errorMsg" message="Something went wrong"/>
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="card-text">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>UUID</th>
                      <th>Email Address</th>
                      <th>Primary Language</th>
                      <th>Story Download Language</th>
                      <th>Restore</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="allUsers?.total === 0">
                      <td scope="col" colspan="9" class="text-center text-primary pt-3 pb-3">
                        Not Found
                      </td>
                    </tr>
                    <tr v-else v-for="(item, index) in allUsers.items" :key="index">
                      <td>{{item.id}}</td>
                      <td>
                        {{ item.email }}
                      </td>
                      <td>{{ item.primary_language }}</td>
                      <td>{{ item.story_download_language }}</td>
                      <td class="text-center">
                        <button
                          class="btn bi bi-arrow-clockwise btn-outline-primary rounded-5"
                          @click="restoreUser(item.id)"
                        ></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <VuejsPaginateNext
            v-model="page"
            :page-count="pageCount"
            :click-handler="pageClick"
            :margin-pages="2"
            :page-range="5"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'prev-item'"
            :prev-link-class="'page-link prev-link-item'"
            :next-class="'next-item'"
            :next-link-class="'page-link next-link-item'"
            :break-view-class="'break-view'"
            :break-view-link-class="'break-view-link'"
          ></VuejsPaginateNext>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
// import VueMultiselect from 'vue-multiselect';

import Loader from '@/components/commons/Loader.vue';
import UserService from '@/services/UserService';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const userService = new UserService();
let imageFileBtn;

export default {
  name: 'About',
  props: [],
  components: {
    DashboardTemplate,
    Loader,
    // VueMultiselect,
    VuejsPaginateNext,
    ErrorDisplay,

  },
  setup(props) {
    onMounted(() => {
      imageFileBtn = document.getElementById('edit-user-image-file');
    });
    const store = useStore();
    const route = useRoute();
    const routePath = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    routePath.value = route.path;

    const userDetails = ref({});
    const userId = ref('');
    const uploadedImage = ref('');
    const isLoading = ref(false);
    const currentOffset = ref(0);
    const perPage = ref(10);
    const pageCount = ref(0);
    const page = ref(1);
    const searchUser = ref('');
    const convertedImage = ref('');
    const selectedGroups = ref([]);

    const searchData = {
      searchUser,
      currentOffset,
      perPage,
    };
    isLoading.value = true;
    store.dispatch('fetchDeletedUser', searchData)
      .then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);

        isLoading.value = false;
      });

    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchUser,
        currentOffset,
        perPage,
      };
      isLoading.value = true;
      store.dispatch(
        'fetchDeletedUser',
        search,
      ).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);

        isLoading.value = false;
      });
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;

      currentOffset.value = perPage.value * (pageNum - 1);
      const Data = {
        searchUser,
        currentOffset,
        perPage,
      };
      store.dispatch('fetchDeletedUser', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);

        isLoading.value = false;
      });
    };

    const allUsers = computed(() => store.getters.getDeletedUsers);

    const restoreUser = (id) => {
      isLoading.value = true;
      userService.restoreUser(id).then((res) => {
        store.dispatch('fetchDeletedUser', searchData).then((response) => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          pageCount.value = Math.ceil(response.data.total / perPage.value);
          successMsg.value = res?.data?.success;
          isLoading.value = false;
          setTimeout(() => {
            successMsg.value = '';
          }, 3000);
        });
      }).catch((error) => {
        isLoading.value = false;
        errorMsg.value = error?.data;
        setTimeout(() => {
          errorMsg.value = '';
        }, 3000);
      });
    };

    return {
      allUsers,
      userDetails,
      isLoading,
      searchClick,
      searchUser,
      currentOffset,
      perPage,
      pageCount,
      page,
      pageClick,
      selectedGroups,
      routePath,
      successMsg,
      errorMsg,
      restoreUser,
    };
  },
};
</script>
