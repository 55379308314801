<template lang="html">
  <div v-if="isLoading"><Loader /></div>

  <div class="modal-header justify-content-end">
    <div class="row">
      <div class="col-auto">
        <label for="inputPassword2" class="visually-hidden">Search</label>
        <input
          type="text"
          class="form-control rounded-5"
          placeholder="Search"
          v-model="searchStory"
          v-on:keyup.enter="searchClick()"
        />
      </div>
      <div class="col-auto tooltip-wrap">
        <button type="submit" class="btn btn-outline-primary mb-3 rounded-5" @click="searchClick()">
          <i class="bi bi-search"></i>
          <span class="tooltip">Search Here</span>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <!-- <ul class="">
      <li
        class="d-flex mb-2"
        v-for="(story, index) in stories.items"
        :key="index"
        :value="story.id"
      >
        <span class="dropdown-item"> {{ story.title }} </span>
      </li>
    </ul> -->
    <label>Select the stories from the Dropdown</label>
    <VueMultiselect
      v-if="stories.items"
      v-model="selectedGroups"
      :options="stories.items"
      :multiple="true"
      trackBy="id"
      label="title"
    >
    </VueMultiselect>
    <div class="container text-end mt-4">
      <button
        @click="addStory(selectedGroups)"
        type="button"
        class="btn btn-outline-primary"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <!-- data-bs-toggle="modal"
        :data-bs-target="`#playlist${this.modalData}Modal`" -->
        Confirm
      </button>
    </div>

    <!-- <AddAndDeleteStory :story-data="selectedGroups" /> -->
  </div>
  <div class="modal-footer justify-content-center">
    <div class="">
      <VuejsPaginateNext
        v-model="page"
        :page-count="pageCount"
        :click-handler="pageClick"
        :margin-pages="2"
        :page-range="3"
        :container-class="'pagination'"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
        :prev-class="'prev-item'"
        :prev-link-class="'page-link prev-link-item'"
        :next-class="'next-item'"
        :next-link-class="'page-link next-link-item'"
        :break-view-class="'break-view'"
        :break-view-link-class="'break-view-link'"
      ></VuejsPaginateNext>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import {
  ref, computed, onMounted, reactive,
} from 'vue';
import Loader from '@/components/commons/Loader.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';
import AddAndDeleteStory from '@/components/partials/AddAndDeleteStory.vue';
import VueMultiselect from 'vue-multiselect';

export default {
  name: 'About',
  props: ['modalData'],
  components: {
    // Loader,
    VuejsPaginateNext,
    // AddAndDeleteStory,
    VueMultiselect,
  },
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const searchStory = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const pageCount = ref(0);
    const page = ref(1);
    const selectedGroups = ref([]);
    const storyPerPage = ref(10);

    const SearchData = {
      searchStory,
      currentOffset,
      storyPerPage,
    };
    store.dispatch('fetchAllStories', SearchData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / perPage.value);

      isLoading.value = false;
    });
    const stories = computed(() => store.getters.getAllStories);
    const getStoryId = computed(() => store.getters.getStoryIds);

    const searchClick = () => {
      isLoading.value = true;
      store.dispatch(
        'fetchAllStories',
        SearchData,
      ).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);

        isLoading.value = false;
      });
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;
      currentOffset.value = perPage.value * (pageNum - 1);
      const Data = {
        searchStory,
        currentOffset,
        perPage,
      };
      store.dispatch('fetchAllStories', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);

        isLoading.value = false;
      });
    };
    const addStory = (id) => {
      // isAdded.value = false;
      //   storyIds.value.push(id);
      store.dispatch('addStoryIds', id).then(() => {
        selectedGroups.value = '';
      });
    };
    return {
      isLoading,
      searchStory,
      currentOffset,
      perPage,
      pageCount,
      page,
      stories,
      searchClick,
      pageClick,
      selectedGroups,
      getStoryId,
      addStory,
    };
  },
};
</script>
