<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-people"></i> Add User</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to create user"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="" class="form-label">Name</label>
                    <input type="text" class="form-control" v-model="userDetails.name" />
                    <span class="text-danger" v-if="v$.name.$error">
                      {{ v$.name.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Email</label>
                    <input type="text" class="form-control" v-model="userDetails.email" />
                    <span class="text-danger" v-if="v$.email.$error">
                      {{ v$.email.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Password</label>
                    <input type="text" class="form-control" v-model="userDetails.password" />
                    <span class="text-danger" v-if="v$.password.$error">
                      {{ v$.password.$errors[0].$message }}*
                    </span>
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">dob</label>
                    <input type="text" placeholder="DD/MM/YYYY" class="form-control" v-model="userDetails.dob" />
                  </div> -->
                  <div class="col-md-6">
                    <label for="" class="form-label">Image</label>
                    <div class="input-group">
                      <div class="input-group-text">
                        <div v-if="imageURL">
                          <img :src="imageURL" alt="" style="width: 80px" />
                        </div>
                        <div v-else class="text-danger">Preview</div>
                      </div>
                    <input
                      type="file"
                      class="form-control-file"
                      id="user-image-file"
                      accept="image/*"
                      hidden="hidden"
                    />
                    <button
                      class="btn btn-primary form-control"
                      type="button"
                      id="custom-button"
                      @click="imageFile()"
                    >
                      <span>Upload <i class="bi bi-upload" aria-hidden="true"></i></span>
                    </button>
                    </div>
                  </div>

                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Primary Language</label>
                    <input type="text" class="form-control" v-model="userDetails.primaryLanguage" />
                    <span class="text-danger" v-if="v$.primaryLanguage.$error">
                      {{ v$.primaryLanguage.$errors[0].$message }}*
                    </span>
                  </div> -->

                  <div class="col-md-6">
                    <label for="" class="form-label">Primary Language</label>
                    <select name="" id="" class="form-select" v-model="userDetails.primaryLanguage">
                      <option value="">Select the Language</option>
                      <option
                        v-for="(lang, index) in language.items"
                        :key="index"
                        :value="lang.code"
                      >
                        {{ lang.language }}
                      </option>
                    </select>
                    <span class="text-danger" v-if="v$.primaryLanguage.$error">
                      {{ v$.primaryLanguage.$errors[0].$message }}*
                    </span>
                  </div>

                  <div class="col-md-6">
                    <div class="form-check form-switch mt-3">
                      <label class="form-check-label" for="flexSwitchActive">Active </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="userDetails.isActive"
                        id="flexSwitchActive"
                        :true-value="true"
                        :false-value="false"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Story Download Language</label>
                    <input type="text" class="form-control" v-model="userDetails.storyLanguage" />
                    <span class="text-danger" v-if="v$.storyLanguage.$error">
                      {{ v$.storyLanguage.$errors[0].$message }}*
                    </span>
                  </div> -->

                  <div class="col-md-6">
                    <label for="" class="form-label">Story Download Language</label>
                    <select name="" id="" class="form-select" v-model="userDetails.storyLanguage">
                      <option value="">Select the Language</option>
                      <option
                        v-for="(lang, index) in language.items"
                        :key="index"
                        :value="lang.code"
                      >
                        {{ lang.language }}
                      </option>
                    </select>
                    <span class="text-danger" v-if="v$.storyLanguage.$error">
                      {{ v$.storyLanguage.$errors[0].$message }}*
                    </span>
                  </div>

                  <div class="col-md-6">
                    <div class="form-check form-switch mt-3">
                      <label class="form-check-label" for="flexSwitchAdmin">Admin </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="userDetails.isAdmin"
                        id="flexSwitchAdmin"
                        :true-value="true"
                        :false-value="false"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Story Download Language Update Count</label>
                    <input
                      type="number"
                      class="form-control"
                      min="0"
                      v-model="userDetails.storyDownload"
                    />
                  </div> -->
                  <div class="col-md-6">
                    <label for="" class="form-label">Groups</label>
                    <VueMultiselect
                      v-if="userGroups.items"
                      v-model="selectedGroups"
                      :options="userGroups.items"
                      :multiple="true"
                      trackBy="title"
                      label="title"
                    >
                    </VueMultiselect>
                  </div>
                </div>
                <div class="container text-end">
                  <button type="button" @click="addUser()" class="btn btn-outline-success me-1">
                    Add
                  </button>
                  <router-link to="/users" type="button" class="btn btn-outline-danger"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>

<script>
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import {
  ref, computed, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import { useCookies } from 'vue3-cookies';
import {
  required, email, helpers, between, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import UserService from '@/services/UserService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const { cookies } = useCookies({ secure: true });

const userService = new UserService();
let imageFileBtn;
export default {
  name: 'AddNewUser',
  components: {
    DashboardTemplate,
    VueMultiselect,
    Loader,
    ErrorDisplay,
  },

  setup() {
    onMounted(() => {
      imageFileBtn = document.getElementById('user-image-file');
    });

    const store = useStore();
    const router = useRouter();
    store.dispatch('fetchUserGroups');
    const isLoading = ref(false);
    const selectedGroups = ref([]);
    const successMsg = ref('');
    const errorMsg = ref('');
    const imageURL = ref('');
    const langPerPage = ref(0);
    const searchLanguage = ref('');
    const currentOffset = ref(0);

    const userDetails = reactive({
      name: '',
      email: '',
      password: '',
      image: '',
      dob: '',
      isActive: false,
      isAdmin: false,
      storyLanguage: '',
      primaryLanguage: '',
      storyDownload: 0,
      groups: [],
    });

    const langData = {
      searchLanguage,
      currentOffset,
      langPerPage,
    };

    store.dispatch('fetchAllLanguages', langData);

    const rules = {
      name: { required: helpers.withMessage('Please enter a name', required) },
      email: { required: helpers.withMessage('Please enter a email', required) },
      password: {
        required: helpers.withMessage('You must enter a password', required),
        min: minLength(8),
        containsPasswordRequirement: helpers.withMessage(
          () => 'The password requires an uppercase, lowercase, number',
          (value) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value),
        ),
      },
      storyLanguage: { required: helpers.withMessage('Please select a story language', required) },
      primaryLanguage: { required: helpers.withMessage('Please select a primary language', required) },
    };

    const v$ = useVuelidate(rules, userDetails);

    const addUser = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        selectedGroups.value.forEach((element) => {
          userDetails.groups.push(element.slug);
        });
        const formData = new FormData();
        formData.append('name', userDetails.name);
        formData.append('email', userDetails.email);
        formData.append('password', userDetails.password);
        formData.append('image', userDetails.image);
        // if (userDetails.dob) {
        //   const dateString = userDetails.dob;
        //   const dateParts = dateString.split('/');
        //   console.log(dateParts, 'old');

        //   const Data = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
        //   console.log(Data, 'new');

        //   const dateData = Data.toISOString();
        //   formData.append('dob', dateData);
        // }
        formData.append('is_active', userDetails.isActive);
        formData.append('is_admin', userDetails.isAdmin);
        formData.append('story_download_language', userDetails.storyLanguage);
        formData.append('primary_language', userDetails.primaryLanguage);
        // formData.append('story_download_language_updated_count', userDetails.storyDownload);
        userDetails.groups.forEach((item) => formData.append('groups[]', item));
        userService
          .createUser(formData)
          .then((response) => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            successMsg.value = 'User Has Been Added Successfully';
            setTimeout(() => {
              successMsg.value = '';
              router.push('/users');
            }, 3000);
            isLoading.value = false;
          })
          .catch((error) => {
            isLoading.value = false;
            errorMsg.value = error?.data;
            userDetails.groups = [];
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };

    const imageListener = () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.files[0];
        userDetails.image = file;
        const blob = new Blob([file], { type: file.type });
        imageURL.value = URL.createObjectURL(blob);
      } else {
        console.log('no file selected');
      }
    };

    const imageFile = () => {
      imageFileBtn = document.getElementById('user-image-file');
      imageFileBtn.click();
      imageFileBtn.addEventListener('change', imageListener);
    };

    const userGroups = computed(() => store.getters.getUserGroups);
    const language = computed(() => store.getters.getLanguage);

    return {
      userGroups,
      userDetails,
      addUser,
      imageFile,
      selectedGroups,
      isLoading,
      errorMsg,
      successMsg,
      v$,
      imageURL,
      language,
    };
  },
};
</script>

<style></style>
