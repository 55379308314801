import * as types from './mutation-types';

const mutations = {
  [types.PLAYLIST_TYPE](state, PlaylistType) {
    state.PlaylistType = PlaylistType;
  },
  [types.PLAYLIST_TYPE_DETAILS](state, PlaylistTypeDetails) {
    state.PlaylistTypeDetails = PlaylistTypeDetails;
  },
  [types.STORY_TYPE](state, storyType) {
    state.storyType = storyType;
  },
  [types.STORY_TYPE_DETAILS](state, storyTypeDetails) {
    state.storyTypeDetails = storyTypeDetails;
  },
  [types.SHIELD_TYPE](state, shieldType) {
    state.shieldType = shieldType;
  },
  [types.SHIELD_TYPE_DETAILS](state, shieldTypeDetails) {
    state.shieldTypeDetails = shieldTypeDetails;
  },
};

export default mutations;
