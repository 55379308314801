<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <!-- <div class="col">
              <h1>
                <router-link to="/stories"><i class="bi bi-arrow-left"></i></router-link>
              </h1>
            </div> -->
            <div class="col">
              <h1><i class="bi bi-file-music"></i>Deleted Stories</h1>
            </div>

            <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
              <div class="row justify-content-end">
                <div class="col">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Select Deleted or Active Items Here"
                  >
                    <router-link
                      to="/stories"
                      class="btn btn-outline-success rounded-start-5 tooltip-wrap"
                      :class="{ active: routePath === '/stories' }"
                    >
                      <i class="bi bi-radioactive"></i>
                      Active
                      <span class="tooltip">Active Items</span>
                    </router-link>
                    <router-link
                      to="/deleted-story"
                      class="btn btn-outline-danger rounded-end-5 tooltip-wrap"
                      :class="{ active: routePath === '/deleted-story' }"
                    >
                      <i class="bi bi-trash-fill"></i>
                      Deleted
                      <span class="tooltip">Deleted Items</span>
                    </router-link>
                  </div>
                </div>
                <div class="col-auto">
                  <label for="inputPassword2" class="visually-hidden">Search</label>
                  <input
                    type="text"
                    class="form-control rounded-5"
                    placeholder="Search"
                    v-model="searchStory"
                    v-on:keyup.enter="searchClick()"
                  />
                </div>
                <div class="col-auto tooltip-wrap">
                  <button
                    type="submit"
                    class="btn btn-outline-primary mb-3 rounded-5"
                    @click="searchClick()"
                  >
                    <i class="bi bi-search"></i>
                    <span class="tooltip">Search Here</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Something went wrong"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>UUID</th>
                        <th>Title</th>
                        <th>Image</th>
                        <th>Audio</th>
                        <th>Language</th>
                        <th>Parent</th>
                        <th>Restore</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="stories?.total === 0">
                        <td scope="col" colspan="9" class="text-center text-primary pt-3 pb-3">
                          Not Found
                        </td>
                      </tr>
                      <tr v-else v-for="(item, index) in stories.items" :key="index">
                        <td>{{ item.id }}</td>
                        <td>{{ item.title }}</td>
                        <td v-if="item.image"><img :src="item.image" alt="" style="width: 50px" /></td>
                        <td v-else> No Image </td>
                        <td v-if="item.file">
                          <audio controls>
                            <source :src="item.file" type="audio/mpeg" />
                          </audio>
                        </td>
                        <td v-else> No Audio </td>
                        <td>{{ item.language }}</td>
                        <td>{{ item.parent }}</td>
                        <td>
                          <button
                            class="btn btn-outline-primary bi bi-arrow-clockwise rounded-5"
                            @click="restoreStory(item.id)"
                          ></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <VuejsPaginateNext
              v-model="page"
              :page-count="pageCount"
              :click-handler="pageClick"
              :margin-pages="2"
              :page-range="3"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
            ></VuejsPaginateNext>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import {
  ref, computed, onMounted, reactive,
} from 'vue';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';

import StoryService from '@/services/StoryService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const storyService = new StoryService();

export default {
  name: 'About',
  props: [],
  components: {
    DashboardTemplate,
    Loader,
    VuejsPaginateNext,
    ErrorDisplay,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(false);
    const searchStory = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const perPageUser = ref(0);
    const pageCount = ref(0);
    const page = ref(1);
    const routePath = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    routePath.value = route.path;

    isLoading.value = true;

    const SearchData = {
      searchStory,
      currentOffset,
      perPage,
    };
    store.dispatch('fetchDeletedStories', SearchData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / perPage.value);

      isLoading.value = false;
    });

    const searchUser = ref('');

    const searchUserData = {
      searchUser,
      currentOffset,
      perPageUser,
    };

    store.dispatch('fetchAllUsers', searchUserData);

    const storyById = computed(() => store.getters.getStoryById);
    const stories = computed(() => store.getters.getDeletedStories);

    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchStory,
        currentOffset,
        perPage,
      };
      isLoading.value = true;
      store.dispatch(
        'fetchDeletedStories',
        search,
      ).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);

        isLoading.value = false;
      });
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;
      currentOffset.value = perPage.value * (pageNum - 1);
      const Data = {
        searchStory,
        currentOffset,
        perPage,
      };
      store.dispatch('fetchDeletedStories', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);

        isLoading.value = false;
      });
    };

    const restoreStory = (id) => {
      isLoading.value = true;
      storyService.restoreStory(id).then((res) => {
        store.dispatch('fetchDeletedStories', SearchData).then((response) => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          pageCount.value = Math.ceil(response.data.total / perPage.value);
          successMsg.value = res?.data?.success;
          isLoading.value = false;
          setTimeout(() => {
            successMsg.value = '';
          }, 3000);
        });
      }).catch((error) => {
        isLoading.value = false;
        errorMsg.value = error?.data;
        setTimeout(() => {
          errorMsg.value = '';
        }, 3000);
      });
    };

    return {
      stories,
      store,
      isLoading,
      searchStory,
      searchClick,
      currentOffset,
      perPage,
      pageCount,
      page,
      pageClick,
      routePath,
      successMsg,
      errorMsg,
      restoreStory,
    };
  },

};
</script>
