import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });
export default class CmsWidgetService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getCmsWidgets(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.searchCms.value) {
        apiEndPoint = `${this.baseURL}v1/cms/widgets/?search=${term.searchCms.value}&orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}`;
      } else {
        apiEndPoint = `${this.baseURL}v1/cms/widgets/?orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}`;
      }
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error.response);
        });
    });
  }

  getCmsWidgetDetails(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/widgets/${id}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  addCmsWidget(data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/widgets/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  editCmsWidget(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/widgets/${id}`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  deleteCmsWidget(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/widgets/${id}`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
}
