<template lang="html">
  <nav class="navbar navbar-expand-lg bg-white shadow-md sticky-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="@/assets/images/logo-black.png" alt="Logo" class="img-fluid" />
      </a>
      <div class="navbar-nav ml-auto">
        <ul class="nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="profileDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img :src="userDetails.image" alt="" class="rounded-circle" width="36" height="36" />
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profileDropdown">
              <li><router-link class="dropdown-item" :to="`/profile-edit/${userDetails.id}`">Profile</router-link></li>
              <!-- <li><a class="dropdown-item" href="#">Settings</a></li> -->
              <li><hr class="dropdown-divider" /></li>
              <li >
                <button @click="logOut()" class="dropdown-item btn btn-outline-primary">
              <i class="bi bi-x-octagon me-2"></i>
              <span>LogOut</span>
            </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useCookies } from 'vue3-cookies';

import {
  ref, computed, onMounted, reactive, watch,
} from 'vue';

const { cookies } = useCookies({ secure: true });

export default {
  name: 'TopNavBar',
  props: [],
  components: {
    // Loader,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const logOut = () => {
      cookies.remove('storyPhoneAdminCookie');
      cookies.remove('storyPhoneAdminRefreshCookie');
      localStorage.removeItem('storyPhonesUser');
      router.push('/');
    };
    store.dispatch('fetchUserProfile');
    const userDetails = computed(() => store.getters.getUserProfile);

    return { logOut, userDetails };
  },
};
</script>
