<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class=""></i> Add New Firmware</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to add firmware"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="title" class="form-label">Version</label>
                    <input type="text" class="form-control" v-model="firmWareUpload.version" />
                    <span class="text-danger" v-if="v$.version.$error">
                      {{ v$.version.$errors[0].$message }}*
                    </span>
                  </div>

                  <div class="col-md-6">
                    <div class="form-check form-switch mt-4">
                      <label class="form-check-label" for="flexSwitchActive"
                        >Selected Latest
                      </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="firmWareUpload.selected_latest"
                        id="flexSwitchActive"
                        :true-value="true"
                        :false-value="false"
                      />
                    </div>
                  </div>
                  <!-- File Section -->
                  <div class="col-md-12 border">
                    <form class="p-5">
                      <div class="form-group row">
                        <label for="title" class="form-label col-md-2">File</label>
                        <div class="col-md-6 mb-4">
                          <input
                            type="file"
                            class="form-control-file"
                            id="newfile"
                            hidden="hidden"
                            accept=".bin"
                          />
                          <button
                            class="btn btn-primary form-control"
                            type="button"
                            id="custom-button"
                            @click="uploadFile()"
                          >
                            <span>Add File <i class="bi bi-upload" aria-hidden="true"></i></span>
                          </button>
                          <span class="text-danger" v-if="v$.file.$error">
                      {{ v$.file.$errors[0].$message }}*
                    </span>
                        </div>
                        <label for="title" class="form-label">File Name :  {{ fileName }}</label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary mt-4" @click="createFirmware">Upload</button>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { ref, reactive, onMounted } from 'vue';
import { required, helpers } from '@vuelidate/validators';

import useVuelidate from '@vuelidate/core';

import FirmWareService from '@/services/FirmWareService';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

// import LoginVue from './Login.vue';

const firmWareService = new FirmWareService();
let FileBtn;

export default {
  name: 'AddFirmware',
  components: {
    DashboardTemplate,
    Loader,
    ErrorDisplay,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const searchFirm = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const successMsg = ref('');
    const errorMsg = ref('');
    const isLoading = ref(false);
    const fileName = ref('');
    const firmWareUpload = reactive({
      version: '',
      selected_latest: false,
      file: '',
    });

    const rules = {
      version: { required: helpers.withMessage('Please enter a version', required) },
      file: { required: helpers.withMessage('Please upload file', required) },
    };

    const v$ = useVuelidate(rules, firmWareUpload);
    onMounted(() => {
      FileBtn = document.getElementById('newfile');
    });

    const fileListener = async () => {
      if (FileBtn.value) {
        const file = FileBtn.files[0];
        firmWareUpload.file = file;
        fileName.value = file.name;
      } else {
        console.log('no file selected');
      }
    };

    const uploadFile = () => {
      FileBtn.click();
      FileBtn.addEventListener('change', fileListener);
    };

    const createFirmware = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        const formData = new FormData();
        formData.append('version', firmWareUpload.version);
        formData.append('selected_latest', firmWareUpload.selected_latest);
        // formData.append('is_active', FirmWareUpload.isActive);
        // formData.append('is_admin', FirmWareUpload.isAdmin);
        formData.append('file', firmWareUpload.file);
        firmWareService
          .FirmWareUpload(formData)
          .then((res) => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            isLoading.value = false;
            successMsg.value = ' Firmware Added Successfully';
            setTimeout(() => {
              successMsg.value = '';
              router.push('/firm-ware');
            }, 3000);
          })
          .catch((error) => {
            console.log(error.data);
            isLoading.value = false;
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };

    return {
      searchFirm,
      currentOffset,
      perPage,
      firmWareUpload,
      createFirmware,
      v$,
      isLoading,
      successMsg,
      errorMsg,
      uploadFile,
      fileName,
    };
  },
};
</script>
