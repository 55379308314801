import StoryPhoneService from '@/services/StoryphoneService';
import * as types from './mutation-types';

const ent = require('ent');

const storyPhoneService = new StoryPhoneService();

export const fetchAllStoryPhone = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    storyPhoneService
      .getAllStoryPhone(Data)
      .then((response) => {
        commit(types.STORYPHONE_DETAILS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchStoryPhoneById = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    storyPhoneService
      .getStoryPhoneDetailsById(Data)
      .then((response) => {
        commit(types.STORYPHONE_DETAILS_BY_ID, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchDeletedStoryPhone = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    storyPhoneService
      .getAllDeletedStoryPhone(Data)
      .then((response) => {
        commit(types.DELETED_STORYPHONE, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchStoryPhoneModels = ({ commit }) => new Promise(
  (resolve, reject) => {
    storyPhoneService
      .getStoryPhoneModels()
      .then((response) => {
        commit(types.STORYPHONE_MODELS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
