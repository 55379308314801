<template lang="html">
  <section class="home">
    <h1>Home Component </h1>
  </section>
</template>

<script lang="js">
export default {
  name: 'About',
  props: [],
  components: {
    // Loader,
  },
  setup(props) {
    return {};
  },
};
</script>
