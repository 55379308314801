<template lang="html">
    <DashboardTemplate>
      <div v-if="isLoading"><Loader /></div>
      <div class="col pt-3 fade-in">
        <div class="row p-3">
          <div class="col-md-12">
            <div class="row">
              <div class="col">
                <h1><i class="bi bi-file-music"></i> Stories</h1>
              </div>
              <!-- <div class="col text-end">
                <router-link to="/deleted-story" class="text-danger"
                  ><i class="bi bi-trash-fill"> </i>Deleted Stories</router-link
                >
              </div> -->
              <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
                <div class="row justify-content-end">
                  <div class="col-auto">
                    <label for="inputPassword2" class="visually-hidden">Search</label>
                    <input
                      type="text"
                      class="form-control rounded-5"
                      placeholder="Search"
                      v-model="searchStory"
                      v-on:keyup.enter="searchClick()"
                    />
                  </div>
                  <div class="col-auto tooltip-wrap">
                    <button
                      type="submit"
                      class="btn btn-outline-primary mb-3 rounded-5"
                      @click="searchClick()"
                    >
                      <i class="bi bi-search"></i>
                      <span class="tooltip">Search Here</span>
                    </button>
                  </div>
                  <div class="col-auto tooltip-wrap">
                    <!-- <a
                      href="#"
                      class="btn btn-outline-success me-3 rounded-5"
                      data-bs-toggle="modal"
                      data-bs-target="#storyAddModal"
                      ><i class="bi bi-database-add"></i>
                      <span class="tooltip">Add New</span>
                    </a> -->
                    <!-- <a href="#" class="btn btn-outline-secondary me-1 rounded-5"
                      ><i class="bi bi-upload"></i> Bulk Upload</a
                    > -->
                  </div>
                </div>
              </div>
              <!-- <div class="col text-end">
                <a
                  href="#"
                  class="btn btn-outline-success me-3"
                  data-bs-toggle="modal"
                  data-bs-target="#storyAddModal"
                  >Add</a
                >
                <a href="#" class="btn btn-outline-secondary">Bulk Import</a>
                <a href="#" class="btn btn-outline-danger">Delete</a>
              </div> -->
            </div>
            <!-- <div class="d-flex justify-content-end my-4">
              <input
                type="text"
                placeholder="Search"
                class="form-control w-25"
                v-model="searchStory"
              />
              <button class="btn btn-outline-info ms-3" @click="searchClick()">
                <i class="bi bi-search"></i>
              </button>
            </div> -->
            <div class="card shadow-sm">
              <div class="card-body">
                <div class="card-text">
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Title</th>
                          <th>Brand</th>
                          <th>Image</th>
                          <!-- <th>Audio</th> -->
                          <th>Parent</th>
                          <th>Language</th>
                          <!-- <th>Edit</th> -->
                          <!-- <th>Delete</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="stories?.total === 0">
                          <td scope="col" colspan="9" class="text-center text-primary pt-3 pb-3">
                            Not Found
                          </td>
                        </tr>
                        <tr v-else v-for="(item, index) in stories.items" :key="index">
                          <td>{{ item.id }}</td>
                          <td>{{ item.title }}</td>
                          <td>{{ item.brand }}</td>

                          <td><img :src="item.image" alt="" style="width: 50px" /></td>

                          <!-- <td>
                            <audio controls>
                              <source :src="item.file" type="audio/mpeg" />
                            </audio>
                          </td> -->
                          <!-- <td>{{ item.file }}</td> -->
                          <td>{{ item.parent }}</td>
                          <td>{{ item.language }}</td>
                          <!-- <td>
                            <button
                              class="btn btn-outline-primary bi bi-pencil-square rounded-5"
                              data-bs-toggle="modal"
                              data-bs-target="#storyEditModal"
                              @click="getStoryById(item.id)"
                            ></button>
                          </td>
                          <td>
                            <button
                              class="btn btn-outline-danger bi bi-trash rounded-5"
                              data-bs-toggle="modal"
                              data-bs-target="#storyDeleteModal"
                              @click="deletePopUp(item.id)"
                            ></button>
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <VuejsPaginateNext
                v-model="page"
                :page-count="pageCount"
                :click-handler="pageClick"
                :margin-pages="2"
                :page-range="3"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
              ></VuejsPaginateNext>
            </div>
          </div>
        </div>
      </div>
    </DashboardTemplate>

    <!-- add Modal -->
    <div
      class="modal fade modal-lg"
      id="storyAddModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="storyAddModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="storyAddModalLabel">create a new Story</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="isLoading"><Loader /></div>
            <div class="row g-3">
              <div class="col-md-6">
                <label for="" class="form-label">Title</label>
                <input type="text" class="form-control" v-model="storyUpload.title" />
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Language</label>
                <input class="form-control" type="text" v-model="storyUpload.language" />
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Type</label>
                <select name="" id="" class="form-select" v-model="storyUpload.type">
                  <option value="">select the Type</option>
                  <option v-for="(type, index) in storyType.items" :key="index" :value="type.slug">
                    {{ type.slug }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Owner</label>
                <select class="form-select" name="" id="" v-model="storyUpload.owner">
                  <option value="">select the Owner</option>
                  <option v-for="(user, index) in user.items" :key="index" :value="user.id">
                    {{ user.email }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <div class="upload-block">
                  <label for="" class="form-label">Audio</label>
                  <input
                    type="file"
                    class="form-control-file"
                    id="audio-file"
                    accept="audio/*"
                    hidden="hidden"
                  />
                  <button
                    class="btn btn-primary form-control"
                    type="button"
                    id="custom-button"
                    @click="audioFile()"
                  >
                    <span>Audio <i class="bi bi-upload" aria-hidden="true"></i></span>
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="upload-block">
                  <label for="" class="form-label">Image</label>
                  <input
                    type="file"
                    class="form-control-file"
                    id="image-file"
                    accept="image/*"
                    hidden="hidden"
                  />
                  <button
                    class="btn btn-primary form-control"
                    type="button"
                    id="custom-button"
                    @click="imageFile()"
                  >
                    <span>Image <i class="bi bi-upload" aria-hidden="true"></i></span>
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Description</label>
                <textarea
                  class="form-control"
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  v-model="storyUpload.description"
                ></textarea>
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Parant Story</label>
                <select class="form-select" name="" id="" v-model="storyUpload.parent">
                  <option value="">select the Parant Story</option>
                  <option v-for="(story, index) in stories.items" :key="index" :value="story.id">
                    {{ story.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button
              @click="uploadStory()"
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- edit Modal -->
    <div
      class="modal fade modal-lg"
      id="storyEditModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="storyEditModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="storyEditModalLabel">{{ editStory.title }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="isLoading"><Loader /></div>
            <div class="row g-3">
              <div class="col-md-6">
                <label for="" class="form-label">Title</label>
                <input type="text" class="form-control" v-model="editStory.title" />
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Language</label>
                <input class="form-control" type="text" v-model="editStory.language" />
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Type</label>
                <select name="" id="" class="form-select" v-model="editStory.type">
                  <option value="">select the Type</option>
                  <option v-for="(type, index) in storyType.items" :key="index" :value="type.slug">
                    {{ type.slug }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Owner</label>
                <select class="form-select" name="" id="" v-model="editStory.owner">
                  <option value="">select the Owner</option>
                  <option v-for="(user, index) in user.items" :key="index" :value="user.id">
                    {{ user.email }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <div class="upload-block">
                  <label for="" class="form-label">Audio</label>
                  <div ref="arrayAudio"></div>

                  <input
                    type="file"
                    class="form-control-file"
                    id="edit-audio-file"
                    accept="audio/*"
                    hidden="hidden"
                  />
                  <button
                    class="btn btn-primary form-control"
                    type="button"
                    id="custom-button"
                    @click="editAudioFile()"
                  >
                    <span>Audio <i class="bi bi-upload" aria-hidden="true"></i></span>
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Image</label>

                <div class="input-group">
                  <div class="input-group-text">
                    <img :src="storyById.image" :alt="storyById.title" style="width: 60px" />
                  </div>
                  <div class="upload-block ms-3 mt-4">
                    <input
                      type="file"
                      class="form-control-file"
                      id="edit-image-file"
                      accept="image/*"
                      hidden="hidden"
                    />
                    <button
                      class="btn btn-primary form-control"
                      type="button"
                      id="custom-button"
                      @click="editImageFile()"
                    >
                      <span>Image <i class="bi bi-upload" aria-hidden="true"></i></span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Description</label>
                <textarea
                  class="form-control"
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  v-model="editStory.description"
                ></textarea>
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Parant Story</label>
                <select class="form-select" name="" id="" v-model="editStory.parent">
                  <option value="">select the Parant Story</option>
                  <option v-for="(story, index) in stories.items" :key="index" :value="story.id">
                    {{ story.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button
              @click="editUpload()"
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- delete Modal -->
    <div
      class="modal fade"
      id="storyDeleteModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="storyDeleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="storyDeleteModalLabel">Are You Sure ?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">Do you Want to Delete? It can be retrieved.</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-primary"
              @click="storyDelete()"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>

<script lang="js">
import { useStore } from 'vuex';
import {
  ref, computed, onMounted, reactive,
} from 'vue';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';

import StoryService from '@/services/StoryService';
import UserService from '@/services/UserService';
import Loader from '@/components/commons/Loader.vue';

const storyService = new StoryService();
const userService = new UserService();

let audioFileBtn;
let imageFileBtn;
let editAudioFileBtn;
let editImageFileBtn;

export default {
  name: 'About',
  props: [],
  components: {
    DashboardTemplate,
    Loader,
    VuejsPaginateNext,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const arrayAudio = ref(null);
    const storyId = ref('');
    const searchStory = ref('');
    const searchStoryType = ref('');
    const currentOffset = ref(0);
    const perPageStory = ref(0);
    const perPage = ref(10);
    const perPageUser = ref(0);
    const pageCount = ref(0);
    const page = ref(1);

    const storyUpload = reactive({
      title: '',
      description: '',
      type: '',
      owner: '',
      image: '',
      audio: '',
      parent: '',
      language: '',

    });

    const editStory = reactive({
      title: '',
      description: '',
      type: '',
      owner: '',
      image: '',
      audio: '',
      parent: '',
      language: '',

    });
    isLoading.value = true;

    const storyData = {
      searchStoryType,
      currentOffset,
      perPageStory,
    };

    store.dispatch('fetchAllStoryType', storyData);

    const SearchData = {
      searchStory,
      currentOffset,
      perPage,
    };
    store.dispatch('fetchCmsAllStories', SearchData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / perPage.value);

      isLoading.value = false;
    });

    const searchUser = ref('');

    const searchUserData = {
      searchUser,
      currentOffset,
      perPageUser,
    };

    store.dispatch('fetchAllUsers', searchUserData);

    const storyById = computed(() => store.getters.getStoryById);
    const stories = computed(() => store.getters.getAllCmsStories);
    const user = computed(() => store.getters.getAllUsers);
    const storyType = computed(() => store.getters.getAllStoryType);

    onMounted(() => {
      audioFileBtn = document.getElementById('audio-file');
      imageFileBtn = document.getElementById('image-file');
      editAudioFileBtn = document.getElementById('edit-audio-file');
      editImageFileBtn = document.getElementById('edit-image-file');
    });

    const audioListener = () => {
      if (audioFileBtn.value) {
        const file = audioFileBtn.files[0];

        storyUpload.audio = file;
      } else {
        console.log('no file selected');
      }
    };

    const editAudioListener = () => {
      if (editAudioFileBtn.value) {
        const file = editAudioFileBtn.files[0];

        editStory.audio = file;
      } else {
        console.log('no file selected');
      }
    };

    const imageListener = () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.files[0];
        storyUpload.image = file;
      } else {
        console.log('no file selected');
      }
    };

    const editImageListener = () => {
      if (editImageFileBtn.value) {
        const file = editImageFileBtn.files[0];
        editStory.image = file;
      } else {
        console.log('no file selected');
      }
    };

    const audioFile = () => {
      audioFileBtn.click();
      audioFileBtn.addEventListener('change', audioListener);
    };

    const editAudioFile = () => {
      editAudioFileBtn.click();
      editAudioFileBtn.addEventListener('change', editAudioListener);
    };

    const imageFile = () => {
      imageFileBtn.click();
      imageFileBtn.addEventListener('change', imageListener);
    };

    const editImageFile = () => {
      editImageFileBtn.click();
      editImageFileBtn.addEventListener('change', editImageListener);
    };

    const uploadStory = () => {
      isLoading.value = true;

      const formData = new FormData();
      formData.append('title', storyUpload.title);
      formData.append('description', storyUpload.description);
      formData.append('type', storyUpload.type);
      formData.append('owner', storyUpload.owner);
      formData.append('image', storyUpload.image);
      formData.append('file', storyUpload.audio);
      // formData.append('parent', storyUpload.parent);
      formData.append('language', storyUpload.language);
      storyService.storyUpload(formData).then((res) => {
        // const SearchData1 = {
        //   searchStory,
        //   currentOffset,
        //   perPage,
        // };
        store.dispatch('fetchCmsAllStories', SearchData).then(() => {
          isLoading.value = false;
        });
      });
    };

    const getStoryById = (id) => {
      isLoading.value = true;
      store.dispatch('fetchStoryById', id).then((respones) => {
        isLoading.value = false;
        // storyById.value = respones.data;
        editStory.title = respones.data.title;
        editStory.description = respones.data.description;
        editStory.type = respones.data.type;
        editStory.owner = respones.data.owner;
        // editStory.image = respones.data.image;
        // editStory.audio = respones.data.file;
        editStory.parent = respones.data.parent;
        editStory.language = respones.data.language;
        const mainaudio = document.createElement('audio');
        mainaudio.setAttribute('controls', 'controls');
        mainaudio.setAttribute('style', 'width: 100%');
        const source = document.createElement('source');
        source.setAttribute('src', respones.data.file);
        source.setAttribute('type', 'audio/mpeg');
        mainaudio.appendChild(source);
        arrayAudio.value.innerHTML = '';
        arrayAudio.value.appendChild(mainaudio);
      });
    };
    const convertImageUrlToFile = async (url) => {
      if (url === null) {
        editStory.image = null;
      } else {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', {
            type: blob.type,
          });
          editStory.image = file;
        } catch (error) {
          console.error('Error converting image URL to file:', error);
        }
      }
    };
    const convertAudioURLtoFile = async (url) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], 'audio.mp3', {
          type: blob.type,
        });
        editStory.audio = file;
      } catch (error) {
        console.error('Error converting image URL to file:', error);
      }
    };

    const editUpload = async () => {
      isLoading.value = true;

      if (!editStory.audio && !editStory.image) {
        try {
          await convertImageUrlToFile(storyById.value.image);

          await convertAudioURLtoFile(storyById.value.file);

          const formData = new FormData();
          formData.append('title', editStory.title);
          formData.append('description', editStory.description);
          formData.append('type', editStory.type);
          formData.append('owner', editStory.owner);
          formData.append('image', editStory.image);
          formData.append('file', editStory.audio);
          formData.append('language', editStory.language);

          await storyService.storyUpdate(storyById.value.id, formData);
        } catch (error) {
          console.error('Error updating story:', error);
        } finally {
          store.dispatch('fetchCmsAllStories', SearchData).then(() => {
            isLoading.value = false;
          });
        }
      } else if (!editStory.audio && editStory.image) {
        try {
          // await convertImageUrlToFile(storyById.value.image);

          await convertAudioURLtoFile(storyById.value.file);

          const formData = new FormData();
          formData.append('title', editStory.title);
          formData.append('description', editStory.description);
          formData.append('type', editStory.type);
          formData.append('owner', editStory.owner);
          formData.append('image', editStory.image);
          formData.append('file', editStory.audio);
          formData.append('language', editStory.language);

          await storyService.storyUpdate(storyById.value.id, formData);
        } catch (error) {
          isLoading.value = false;
        } finally {
          store.dispatch('fetchCmsAllStories', SearchData).then(() => {
            isLoading.value = false;
          });
        }
      } else if (!editStory.image && editStory.audio) {
        try {
          await convertImageUrlToFile(storyById.value.image);

          const formData = new FormData();
          formData.append('title', editStory.title);
          formData.append('description', editStory.description);
          formData.append('type', editStory.type);
          formData.append('owner', editStory.owner);
          formData.append('image', editStory.image);
          formData.append('file', editStory.audio);
          formData.append('language', editStory.language);

          await storyService.storyUpdate(storyById.value.id, formData);
        } catch (error) {
          isLoading.value = false;
        } finally {
          store.dispatch('fetchCmsAllStories', SearchData).then(() => {
            isLoading.value = false;
          });
        }
      } else {
        const formData = new FormData();
        formData.append('title', editStory.title);
        formData.append('description', editStory.description);
        formData.append('type', editStory.type);
        formData.append('owner', editStory.owner);
        formData.append('image', editStory.image);
        formData.append('file', editStory.audio);
        // formData.append('parent', storyUpload.parent);
        formData.append('language', editStory.language);
        storyService.storyUpdate(storyById.value.id, formData).then((res) => {
          store.dispatch('fetchCmsAllStories', SearchData).then(() => {
            isLoading.value = false;
          });
        });
      }
    };
    const deletePopUp = (id) => {
      storyId.value = id;
    };
    const storyDelete = () => {
      isLoading.value = true;
      storyService.storyDelete(storyId.value).then(() => {
        store.dispatch('fetchCmsAllStories', SearchData).then((response) => {
          isLoading.value = false;
          pageCount.value = Math.ceil(response.data.total / perPage.value);
        });
      });
    };

    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchStory,
        currentOffset,
        perPage,
      };
      isLoading.value = true;
      store.dispatch(
        'fetchCmsAllStories',
        search,
      ).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);

        isLoading.value = false;
      });
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;
      currentOffset.value = perPage.value * (pageNum - 1);
      const Data = {
        searchStory,
        currentOffset,
        perPage,
      };
      store.dispatch('fetchCmsAllStories', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);

        isLoading.value = false;
      });
    };
    return {
      storyType,
      stories,
      user,
      store,
      audioFile,
      imageFile,
      storyUpload,
      uploadStory,
      isLoading,
      storyById,
      getStoryById,
      editStory,
      arrayAudio,
      editAudioFile,
      editImageFile,
      editUpload,
      storyId,
      deletePopUp,
      storyDelete,
      searchStory,
      searchClick,
      currentOffset,
      perPage,
      pageCount,
      page,
      pageClick,
    };
  },

};
</script>
