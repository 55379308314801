/* eslint-disable indent */
/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const getPlaylistGenres = (state) => isObj(state, 'playlistGenres', []);
export const getBrands = (state) => isObj(state, 'brand', []);
export const getProductType = (state) => isObj(state, 'productType', []);
export const getProductBySku = (state) => isObj(state, 'productBySku', []);
export const getShippingCategories = (state) => isObj(state, 'shippingCategories', []);
export const getPriceLists = (state) => isObj(state, 'priceLists', []);
export const getStockLocations = (state) => isObj(state, 'stockLocations', []);
