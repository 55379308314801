import * as types from './mutation-types';

const mutations = {
  [types.PLAYLIST_DETAILS](state, playlists) {
    state.playlists = playlists;
  },

  [types.PLAYLIST_DETAILS_BY_ID](state, playlistById) {
    state.playlistById = playlistById;
  },

  [types.STORY_DETAILS_BY_PLAYLIST_ID](state, storyByplaylistId) {
    state.storyByplaylistId = storyByplaylistId;
  },

  [types.STORY_ID](state, id) {
    state.storyId = id;
  },

  [types.REMOVE_STORY_ID](state, id) {
    state.storyId.forEach((element, index) => {
      if (element === id) {
        state.storyId.splice(index, 1);
      }
    });
  },

  [types.REMOVE_ALL_STORY_ID](state) {
    state.storyId = [];
  },

  [types.DELETED_PLAYLIST](state, deletedPlaylists) {
    state.deletedPlaylists = deletedPlaylists;
  },
};

export default mutations;
