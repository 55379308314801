<template>
  <router-view></router-view>
</template>

<script>
import 'bootstrap';
import './themes/storyphones/styles/style.scss';
import 'vue-multiselect/dist/vue-multiselect.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: 'App',
  components: {},
};
</script>

<style></style>
