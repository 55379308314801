import FirmWareService from '@/services/FirmWareService';
import * as types from './mutation-types';

const ent = require('ent');

const firmWareService = new FirmWareService();

export const fetchAllFirmWarelists = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    firmWareService
      .getFirmWare(Data)
      .then((response) => {
        commit(types.FIRMWARELIST_DETAILS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
export const fetchFirmById = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    firmWareService
      .getFirmWareDetails(Data)
      .then((response) => {
        commit(types.FIRMWARELIST_DETAILS_BY_ID, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
