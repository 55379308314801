import { useCookies } from 'vue3-cookies';
import { useRouter, useRoute } from 'vue-router';
import CLayerBaseService from '@/services/CLayerBaseService';

const cLayerBaseService = new CLayerBaseService();

const axios = require('axios');

const router = useRouter();
const { cookies } = useCookies({ secure: true });

export default class BaseService {
  constructor() {
    this.baseURL = process.env.VUE_APP_API_URL;
    this.http = axios;
    this.guestToken = process.env.VUE_APP_GUEST_API_TOKEN;

    axios.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalConfig = err.config;
        if (err?.response?.status) {
          if (
            err?.response?.status === 401 && err?.response?.data?.errors?.includes('token is invalid or expired.')
          ) {
            // eslint-disable-next-line no-underscore-dangle
            originalConfig._retry = true;
            try {
              const rToken = cookies.get('storyPhoneAdminRefreshCookie');
              cLayerBaseService.createAdminToken();
              const rs = await axios.post(`${this.baseURL}v1/auth/jwt-verify/`, {
                refreshToken: rToken.refresh_token,
              })

                .catch(() => {
                  cookies.remove('storyPhoneAdminCookie');
                  cookies.remove('storyPhoneAdminRefreshCookie');
                  localStorage.removeItem('storyPhonesUser');
                  window.location.href = '/';
                  console.log('error');
                });
              cookies.set('storyPhoneAdminCookie', {
                access_token: rs.data.accessToken,
              });
              cookies.set('storyPhoneAdminRefreshCookie', {
                refresh_token: rs.data.refreshToken,
              });
              originalConfig.headers.Authorization = `Bearer ${this.getUserToken()}`;
              return axios.request(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }

        return Promise.reject(err);
      },
    );
  }

  refresh(credentials) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/jwt-verify/`;
      this.http
        .post(apiEndPoint, credentials)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data.errors);
        });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getUserToken = () => {
    const token = cookies.get('storyPhoneAdminCookie');
    if (!token) {
      cookies.remove('storyPhoneAdminCookie');
      cookies.remove('storyPhoneAdminRefreshCookie');
      localStorage.removeItem('storyPhonesUser');
      window.location.href = '/';
    }
    return token.access_token;
  };
}
