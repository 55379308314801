import { useCookies } from 'vue3-cookies';
import CLayerAuth from '@commercelayer/js-auth';
import CommerceLayer from '@commercelayer/sdk';

const axios = require('axios');

const { cookies } = useCookies({ secure: true });

export default class CLayerBaseService {
  constructor() {
    this.clAmdmin = null;
    this.cLayerBaseURL = process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_ENDPOINT;
    this.http = axios;
    this.clientId = process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_CLIENT_ID;
    this.clientSecret = process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_CLIENT_SECRET;
  }

  createAdminToken = () => new Promise((resolve, reject) => {
    // const { cookies } = useCookies({ secure: true });
    // CLayerAuth.authenticate({
    //   grant_type: 'client_credentials',
    //   clientId: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_CLIENT_ID,
    //   clientSecret: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_CLIENT_SECRET,
    //   domain: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_ENDPOINT,
    // })
    const apiEndPoint = `${this.cLayerBaseURL}/oauth/token`;
    this.http
      .post(apiEndPoint, {
        grant_type: 'client_credentials',
        client_id: this.clientId,
        client_secret: this.clientSecret,
      })
      .then((response) => {
        cookies.set('clUserAuthAdminCookie', {
          access_token: response.data.access_token,
        });
        // this.clAmdmin = CommerceLayer({
        //   organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_ORG,
        //   accessToken: response.data.access_token,
        // });
        // resolve(this.clAmdmin);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

  // refreshToken = () => new Promise((resolve, reject) => {
  //   const { cookies } = useCookies({ secure: true });
  //   const refresh = cookies.get('clUserAuthRefreshCookie');
  //   const market = localStorage.getItem('marketNumber');
  //   CLayerAuth.getRefreshToken({
  //     clientId: process.env.VUE_APP_COMMERCE_LAYER_SC_CLIENT_ID,
  //     endpoint: process.env.VUE_APP_COMMERCE_LAYER_SC_ENDPOINT,
  //     scope: `market:${market}`,
  //     refreshToken: refresh.refresh_token,
  //   })
  //     .then((response) => {
  //       cookies.set('clUserAuthCookie', {
  //         access_token: response.accessToken,
  //       });
  //       cookies.set('clUserAuthRefreshCookie', {
  //         refresh_token: response.refreshToken,
  //       });
  //       cookies.set('clUserOwnerId', {
  //         owner_id: response.data.owner_id,
  //       });
  //       this.clAmdmin = CommerceLayer({
  //         organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ORG,
  //         accessToken: response.accessToken,
  //       });
  //       resolve(this.clAmdmin);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });

  // getToken = () => new Promise((resolve, reject) => {
  //   const { cookies } = useCookies({ secure: true });
  //   if (cookies.isKey('clUserAuthAdminCookie')) {
  //     const token = cookies.get('clUserAuthAdminCookie');
  //     this.clAmdmin = CommerceLayer({
  //       organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_ORG,
  //       accessToken: token.access_token,
  //     });
  //     this.clAmdmin.addResponseInterceptor(undefined, (err) => {
  //       // eslint-disable-next-line no-underscore-dangle
  //       if (err.response.status === 401 && err.response.data.errors[0].code === 'INVALID_TOKEN') {
  //         this.refreshToken().then(() => {
  //           window.location.reload();
  //         });
  //       }
  //     });
  //     resolve(this.clAmdmin);
  //   } else {
  //     this.createAdminToken();
  //     this.clAmdmin = CommerceLayer({
  //       organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_ORG,
  //       accessToken: cookies.get('clUserAuthAdminCookie').access_token,
  //     });
  //     this.clAmdmin.addResponseInterceptor(undefined, (err) => {
  //       // eslint-disable-next-line no-underscore-dangle
  //       if (err.response.status === 401 && err.response.data.errors[0].code === 'INVALID_TOKEN') {
  //         cookies.remove('clUserAuthAdminCookie');
  //         this.createAdminToken().then(() => {
  //           window.location.reload();
  //         });
  //       }
  //     });
  //     resolve(this.clAmdmin);
  //   }
  // });
   // eslint-disable-next-line class-methods-use-this
   getClayerAdminToken = () => {
     const token = cookies.get('clUserAuthAdminCookie');
     if (!token) {
       //  cookies.remove('storyPhoneAdminCookie');
       //  cookies.remove('storyPhoneAdminRefreshCookie');
       //  localStorage.removeItem('storyPhonesUser');
       //  window.location.href = '/';
       this.createAdminToken();
     }
     return token.access_token;
   };
}
