/* eslint-disable indent */
/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const getStoryType = (state) => isObj(state, 'storyType', []);
export const getAllStories = (state) => isObj(state, 'stories', []);
export const getStoryById = (state) => isObj(state, 'story', []);
export const getDeletedStories = (state) => isObj(state, 'deletedStories', []);
export const getAllStoriesWithOutLimt = (state) => isObj(state, 'storyWithOutLimit', []);
