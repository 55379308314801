import ShieldService from '@/services/ShieldService';
import * as types from './mutation-types';

const ent = require('ent');

const shieldService = new ShieldService();

export const fetchAllShields = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    shieldService
      .getAllShields(Data)
      .then((response) => {
        commit(types.SHIELD_DETAILS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchShieldById = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    shieldService
      .getShieldById(Data)
      .then((response) => {
        commit(types.SHIELD_BY_ID, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
export const fetchDeletedShield = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    shieldService
      .getAllDeletedShields(Data)
      .then((response) => {
        commit(types.DELETED_SHIELD, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
