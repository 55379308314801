<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-braces-asterisk"></i> CMS Pages</h1>
            </div>
            <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
              <div class="row justify-content-end">
                <div class="col-auto">
                  <div class="d-flex">
                  <!-- <div
                    class="btn-group"
                    role="group"
                    aria-label="Select Deleted or Active Items Here"
                  >
                    <router-link
                      to="/cms-add"
                      class="btn btn-outline-primary rounded-start-5 tooltip-wrap"
                    >
                      <i class="bi bi-plus-lg"></i>
                      Add Multiple
                      <span class="tooltip">Add Multiple Page</span>
                    </router-link>
                    <router-link
                      to="/quill"
                      class="btn btn-outline-secondary rounded-end-5 tooltip-wrap"
                    >
                      <i class="bi bi-plus-lg"></i>
                      Add single
                      <span class="tooltip">Add Single Page</span>
                    </router-link>
                  </div> -->
                  <label for="" class="form-label mt-2 me-3">Filter</label>
                    <select class="form-select rounded-5" id="dropdown" v-model="selectedLang" @change="languageFilter">
                      <option value="">All</option>
                      <option value=3f9b4813-525d-496a-8b25-192a4e1484c6>English</option>
                      <option value=b40ad8a0-e10b-4984-926b-69ed8ecb106e>German</option>
                      <option value=b18c2831-c26b-4228-b910-be609376758f>Spanish</option>
                      <option value=d8b3d690-0aca-4a3d-8cba-9b70b58ae354>Italian</option>
                      <option value=e9526b70-5d06-41ab-ac8a-8e7f7a957443>Chinese</option>
                      <option value=98602365-b963-43ba-adab-ffdd1d9b8d24>French</option>
                    </select>
                  </div>
                </div>
                <div class="col-auto">

                  <label for="inputPassword2" class="visually-hidden">Search</label>
                  <input
                    type="text"
                    class="form-control rounded-5"
                    placeholder="Search"
                    v-model="searchCms"
                    v-on:keyup.enter="searchClick()"
                  />
                </div>
                <div class="col-auto tooltip-wrap">
                  <button
                    type="submit"
                    class="btn btn-outline-primary mb-3 rounded-5"
                    @click="searchClick()"
                  >
                    <i class="bi bi-search"></i>
                    <span class="tooltip">Search Here</span>
                  </button>
                </div>
                <!-- <div class="col-auto tooltip-wrap">
                  <router-link to="/cms-add" class="btn btn-outline-success me-3 rounded-5"
                    ><i class="bi bi-database-add"></i>
                    <span class="tooltip">Add New</span>
                  </router-link>
                </div> -->
              </div>
            </div>
            <div class="card shadow-sm">
              <div class="card-body">
                <div class="card-text">
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>UUID</th>
                          <th>Page Name</th>
                          <th>Title</th>
                          <th>Slug</th>
                          <th>Edit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="cmsListDetails?.total === 0">
                          <td scope="col" colspan="8" class="text-center text-primary pt-3 pb-3">
                            Not Found
                          </td>
                        </tr>
                        <tr v-else v-for="(item, index) in cmsListDetails.items" :key="index">
                          <td>{{ item.id }}</td>
                          <td>{{ item.page_name }}</td>
                          <td>{{ item.title }}</td>
                          <td>{{ item.slug }}</td>
                          <td>
                            <button
                              class="btn btn-outline-primary bi bi-pencil-square rounded-5"
                              @click="goToDetails(item.id)"
                            ></button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <VuejsPaginateNext
                v-model="page"
                :page-count="pageCount"
                :click-handler="pageClick"
                :margin-pages="2"
                :page-range="3"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'prev-item'"
                :prev-link-class="'page-link prev-link-item'"
                :next-class="'next-item'"
                :next-link-class="'page-link next-link-item'"
                :break-view-class="'break-view'"
                :break-view-link-class="'break-view-link'"
              ></VuejsPaginateNext>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script>
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import CmsPageService from '@/services/CmsPageService';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';
import Loader from '@/components/commons/Loader.vue';

const cmsPageService = new CmsPageService();

export default {
  name: 'CmsPage',
  components: {
    DashboardTemplate,
    VuejsPaginateNext,
    Loader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const cmsList = ref([]);
    const searchCms = ref('');
    const content = ref('');
    const currentOffset = ref(0);
    const isLoading = ref(false);
    const pageCount = ref(0);
    const perPage = ref(10);
    const page = ref(1);
    const selectedLang = ref('');
    const SearchData = {
      searchCms,
      currentOffset,
      perPage,
      selectedLang,
    };
    isLoading.value = true;
    store.dispatch('fetchAllCmslists', SearchData).then((response) => {
      isLoading.value = false;
      pageCount.value = Math.ceil(response.data.total / perPage.value);
    });
    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchCms,
        currentOffset,
        perPage,
        selectedLang,
      };
      store.dispatch('fetchAllCmslists', search).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPage.value);
      });
    };
    const pageClick = (pageNum) => {
      isLoading.value = true;
      currentOffset.value = perPage.value * (pageNum - 1);
      const Data = {
        searchCms,
        currentOffset,
        perPage,
        selectedLang,
      };
      store.dispatch('fetchAllCmslists', Data).then((response) => {
        isLoading.value = false;
        pageCount.value = Math.ceil(response.data.total / perPage.value);
      });
    };

    const languageFilter = () => {
      isLoading.value = true;

      const Data = {
        searchCms,
        currentOffset,
        perPage,
        selectedLang,
      };
      store.dispatch('fetchAllCmslists', Data).then((response) => {
        isLoading.value = false;
        pageCount.value = Math.ceil(response.data.total / perPage.value);
      });
    };

    const cmsListDetails = computed(() => store.getters.getCmsLists);

    const cmsListDetailsById = computed(() => store.getters.getCmslistdetailsById);

    const goToDetails = (id) => {
      store.dispatch('fetchCmslistdetailsById', id).then(() => {
        router.push({ name: 'CmsDetails', params: { id } });
      });
    };

    const routes = () => {
      router.push({ name: 'AddCmsPage' });
    };
    return {
      cmsList,
      goToDetails,
      routes,
      cmsListDetails,
      cmsListDetailsById,
      searchClick,
      searchCms,
      pageClick,
      page,
      pageCount,
      selectedLang,
      languageFilter,
      isLoading,
    };
  },
};
</script>
