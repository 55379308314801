import CmsPageService from '@/services/CmsPageService';
import * as types from './mutation-types';

const ent = require('ent');

const cmsPageService = new CmsPageService();

export const fetchAllCmslists = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cmsPageService
      .getCmsPages(Data)
      .then((response) => {
        commit(types.CMSLIST_DETAILS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
export const fetchCmslistdetailsById = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    cmsPageService
      .getCmsPageDetails(Data)
      .then((response) => {
        commit(types.CMSLIST_DETAILS_BY_ID, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
