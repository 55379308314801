import PlaylistService from '@/services/PlaylistService';
import * as types from './mutation-types';

const ent = require('ent');

const playlistService = new PlaylistService();

export const fetchAllPlaylists = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    playlistService
      .getAllPlaylist(Data)
      .then((response) => {
        commit(types.PLAYLIST_DETAILS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchPlaylistById = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    playlistService
      .getPlaylistDetailsById(Data)
      .then((response) => {
        commit(types.PLAYLIST_DETAILS_BY_ID, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchStoryPlaylistById = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    playlistService
      .getStoryDetailsByPlaylistsId(Data)
      .then((response) => {
        commit(types.STORY_DETAILS_BY_PLAYLIST_ID, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const addStoryIds = ({ commit }, id) => {
  commit(types.STORY_ID, id);
};

export const removeStoryIds = ({ commit }, id) => {
  commit(types.REMOVE_STORY_ID, id);
};

export const removeAllStoryIds = ({ commit }) => {
  commit(types.REMOVE_ALL_STORY_ID);
};

export const fetchDeletedPlaylist = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    playlistService
      .getAllDeletedPlaylist(Data)
      .then((response) => {
        commit(types.DELETED_PLAYLIST, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
