<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-braces-asterisk"></i> Shield Type</h1>
            </div>
            <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
              <div class="row justify-content-end">
                <div class="col-auto">
                  <label for="inputPassword2" class="visually-hidden">Search</label>
                  <input
                    type="text"
                    class="form-control rounded-5"
                    placeholder="Search"
                    v-model="searchShieldType"
                    v-on:keyup.enter="searchClick()"
                  />
                </div>
                <div class="col-auto tooltip-wrap">
                  <button
                    type="submit"
                    class="btn btn-outline-primary mb-3 rounded-5"
                    @click="searchClick()"
                  >
                    <i class="bi bi-search"></i>
                    <span class="tooltip">Search Here</span>
                  </button>
                </div>
                <div class="col-auto tooltip-wrap">
                  <a
                    @click="addShield()"
                    class="btn btn-outline-success me-3 rounded-5"
                    ><i class="bi bi-database-add"></i>
                    <span class="tooltip">Add New</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to update type"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <!-- <th>ID</th> -->
                        <th>Type</th>
                        <th>Slug</th>
                        <!-- <th>Edit</th> -->
                        <!-- <th>Delete</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr  v-if="allTypeLists?.total === 0">
                        <td scope="col" colspan="9" class="text-center text-primary pt-3 pb-3">
                          Not Found
                        </td>
                      </tr>
                      <tr class="text-center" v-else v-for="(type, index) in allTypeLists.items" :key="index">
                        <!-- <td>{{ type.id }}</td> -->
                        <td>{{ type.title }}</td>
                        <td>{{ type.slug }}</td>
                        <!-- <td class="text-center">
                          <button
                            class="btn btn-outline-primary bi bi-pencil-square rounded-5"
                            data-bs-toggle="modal"
                            data-bs-target="#shieldTypeEditModal"
                            @click="typeById(type.id)"
                          ></button>
                        </td> -->
                        <!-- <td class="text-center">
                          <button
                            class="btn btn-outline-danger bi bi-trash rounded-5"
                            data-bs-toggle="modal"
                            data-bs-target="#shieldTypeDeleteModal"
                            @click="deletePopUp(type.id)"
                          ></button>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <VuejsPaginateNext
              v-model="page"
              :page-count="pageCount"
              :click-handler="pageClick"
              :margin-pages="2"
              :page-range="3"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
            ></VuejsPaginateNext>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>

  <!-- add Modal -->
  <div
    class="modal fade"
    id="shieldTypeAddModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="shieldTypeAddModalLabel"
    aria-hidden="true"
    :class="{ showModal: modalShow , show: modalShow }"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="shieldTypeAddModalLabel">Create New Type</h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            data-bs-dismiss="modal"
            @click="closeModal()"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="isLoading"><Loader /></div>
          <div class="container g-3">
            <div class="row">
              <div class="col-md-6">
                <label for="" class="form-label">Title</label>
                <input type="text" class="form-control" v-model="typesLists.title" />
                <span class="text-danger" v-if="v$.title.$error">
                      {{ v$.title.$errors[0].$message }}*
                    </span>
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Slug </label>
                <input type="text" class="form-control" v-model="typesLists.slug" />
                <span class="text-danger" v-if="v$.slug.$error">
                      {{ v$.slug.$errors[0].$message }}*
                    </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" @click="closeModal()" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            @click="uploadType()"
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- edit Modal -->
  <div
    class="modal fade"
    id="shieldTypeEditModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="shieldTypeEditModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="shieldTypeEditModalLabel">{{ typeDetails.title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="isLoading"><Loader /></div>
          <div class="container">
            <div class="row g-3">
              <div class="col-md-6">
                <label for="" class="form-label">Title</label>
                <input type="text" class="form-control" v-model="editTypesLists.title" />
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Slug </label>
                <input type="text" class="form-control" v-model="editTypesLists.slug" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            @click="editUploadType()"
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- delete Modal -->
  <div
    class="modal fade"
    id="shieldTypeDeleteModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="shieldTypeDeleteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="shieldTypeDeleteModalLabel">Are You Sure ?</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">Are you sure you want to delete? This action cannot be undone or reversed</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
            @click="typeDelete()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import { useStore } from 'vuex';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
import {
  required, email, helpers, between,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import VuejsPaginateNext from '@/components/partials/Paginate.vue';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import Loader from '@/components/commons/Loader.vue';
import TypesService from '@/services/TypesService';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const typesService = new TypesService();

export default {
  name: 'About',
  props: [],
  components: {
    Loader,
    DashboardTemplate,
    VuejsPaginateNext,
    ErrorDisplay,
  },
  setup(props) {
    const store = useStore();

    const isLoading = ref(false);
    const searchShieldType = ref('');
    const typeId = ref('');
    const modalShow = ref(false);
    const successMsg = ref('');
    const errorMsg = ref('');
    const currentOffset = ref(0);
    const perPageShield = ref(10);
    const pageCount = ref(0);
    const page = ref(1);
    const typesLists = reactive({
      title: '',
      slug: '',
    });
    const editTypesLists = reactive({
      title: '',
      slug: '',
    });

    isLoading.value = true;

    const rules = {
      title: { required: helpers.withMessage('Please enter title', required) },
      slug: { required: helpers.withMessage('Please enter a slug', required) },
    };

    const v$ = useVuelidate(rules, typesLists);

    const closeModal = () => {
      modalShow.value = false;
      typesLists.title = '';
      typesLists.slug = '';
    };

    const shieldData = {
      searchShieldType,
      currentOffset,
      perPageShield,
    };

    store.dispatch('fetchAllShieldType', shieldData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / perPageShield.value);
      isLoading.value = false;
    });

    const allTypeLists = computed(() => store.getters.getAllShieldType);

    const typeDetails = computed(() => store.getters.getShieldTypeDetails);

    const uploadType = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        const Data = {
          title: typesLists.title.trim(),
          slug: typesLists.slug.trim(),
        };
        typesService.shieldTypeUpload(Data).then(() => {
          typesLists.title = '';
          typesLists.slug = '';
          v$.value.$reset();
          modalShow.value = false;
          store.dispatch('fetchAllShieldType', shieldData).then((response) => {
            pageCount.value = Math.ceil(response.data.total / perPageShield.value);
            isLoading.value = false;
            successMsg.value = ' Shield Type Has Been Added Successfully';
            setTimeout(() => {
              successMsg.value = '';
            }, 3000);
          });
        })
          .catch((error) => {
            modalShow.value = false;
            isLoading.value = false;
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;

      currentOffset.value = perPageShield.value * (pageNum - 1);

      const Data = {
        searchShieldType,
        currentOffset,
        perPageShield,
      };

      store.dispatch('fetchAllShieldType', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPageShield.value);

        isLoading.value = false;
      });
    };

    const addShield = () => {
      modalShow.value = true;
    };

    const typeById = (id) => {
      typeId.value = id;
      isLoading.value = true;
      store.dispatch('fetchShieldTypeDetails', id).then((res) => {
        editTypesLists.title = res.data.title;
        editTypesLists.slug = res.data.slug;
        isLoading.value = false;
      });
    };
    const editUploadType = () => {
      isLoading.value = true;
      const Data = {
        title: editTypesLists.title.trim(),
        slug: editTypesLists.slug.trim(),
      };
      typesService.shieldTypeUpdate(typeId.value, Data).then(() => {
        store.dispatch('fetchAllShieldType', shieldData).then((response) => {
          pageCount.value = Math.ceil(response.data.total / perPageShield.value);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          isLoading.value = false;
        });
      });
    };

    const deletePopUp = (id) => {
      typeId.value = id;
    };

    const typeDelete = () => {
      isLoading.value = true;

      typesService.shieldTypeDelete(typeId.value).then((deleted) => {
        store.dispatch('fetchAllShieldType', shieldData).then((response) => {
          successMsg.value = deleted?.data?.message;
          setTimeout(() => {
            successMsg.value = '';
          }, 3000);
          pageCount.value = Math.ceil(response.data.total / perPageShield.value);
          isLoading.value = false;
        });
      });
    };

    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchShieldType,
        currentOffset,
        perPageShield,
      };
      isLoading.value = true;
      store.dispatch('fetchAllShieldType', search).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPageShield.value);
        isLoading.value = false;
      });
    };
    return {
      isLoading,
      allTypeLists,
      typesLists,
      uploadType,
      typeId,
      typeById,
      editTypesLists,
      editUploadType,
      deletePopUp,
      typeDelete,
      typeDetails,
      searchShieldType,
      searchClick,
      v$,
      closeModal,
      modalShow,
      addShield,
      successMsg,
      errorMsg,
      pageClick,
      currentOffset,
      perPageShield,
      pageCount,
      page,
    };
  },
};
</script>
