<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-file-music"></i> Stories</h1>
            </div>
            <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
              <div class="row justify-content-end">
                <div class="col">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Select Deleted or Active Items Here"
                  >
                    <router-link
                      to="/stories"
                      class="btn btn-outline-success rounded-start-5 tooltip-wrap"
                      :class="{ active: routePath === '/stories' }"
                    >
                      <i class="bi bi-radioactive"></i>
                      Active
                      <span class="tooltip">Active Items</span>
                    </router-link>
                    <router-link
                      to="/deleted-story"
                      class="btn btn-outline-danger rounded-end-5 tooltip-wrap"
                      :class="{ active: routePath === '/deleted-story' }"
                    >
                      <i class="bi bi-trash-fill"></i>
                      Deleted
                      <span class="tooltip">Deleted Items</span>
                    </router-link>
                  </div>
                </div>
                <div class="col-auto">
                  <label for="inputPassword2" class="visually-hidden">Search</label>
                  <input
                    type="text"
                    class="form-control rounded-5"
                    placeholder="Search"
                    v-model="searchStory"
                    v-on:keyup.enter="searchClick()"
                  />
                </div>
                <div class="col-auto tooltip-wrap">
                  <button
                    type="submit"
                    class="btn btn-outline-primary mb-3 rounded-5"
                    @click="searchClick()"
                  >
                    <i class="bi bi-search"></i>
                    <span class="tooltip">Search Here</span>
                  </button>
                </div>
                <div class="col-auto tooltip-wrap">
                  <router-link to="/add-story" class="btn btn-outline-success me-3 rounded-5"
                    ><i class="bi bi-database-add"></i>
                    <span class="tooltip">Add New</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="ISBN already exists"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>UUID</th>
                        <th>Title</th>
                        <th>Image</th>
                        <th>Audio</th>
                        <th>Orginal File</th>
                        <th>Language</th>
                        <th>Parent</th>
                        <th>OGG Conversion Status</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="stories?.total === 0">
                        <td scope="col" colspan="9" class="text-center text-primary pt-3 pb-3">
                          Not Found
                        </td>
                      </tr>
                      <tr v-else v-for="item in stories.items" :key="item?.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.title }}</td>
                        <td v-if="item.thumbnail">
                          <img :src="item.thumbnail" alt="" style="width: 50px" />
                        </td>
                        <td v-else>No Image</td>
                        <td v-if="item.file">
                          <audio controls>
                            <source :src="item.file" type="audio/mpeg" />
                          </audio>
                        </td>
                        <td v-else>No Audio</td>
                        <td>
                          <a :href="item?.original_file">{{ item?.original_file }}</a>
                        </td>
                        <td>{{ item?.language?.code }}</td>
                        <td>{{ item?.parent?.title }}</td>
                        <td>{{ conversionStatus(item?.conversion_status) }}</td>
                        <td>
                          <router-link
                            :to="`/edit-story/${item.id}`"
                            class="btn btn-outline-primary bi bi-pencil-square rounded-5"
                          ></router-link>
                        </td>
                        <td>
                          <button
                            class="btn btn-outline-danger bi bi-trash rounded-5"
                            data-bs-toggle="modal"
                            data-bs-target="#storyDeleteModal"
                            @click="deletePopUp(item.id)"
                          ></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <VuejsPaginateNext
              v-model="page"
              :page-count="pageCount"
              :click-handler="pageClick"
              :margin-pages="2"
              :page-range="3"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
            ></VuejsPaginateNext>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>

  <!-- delete Modal -->
  <div
    class="modal fade"
    id="storyDeleteModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="storyDeleteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="storyDeleteModalLabel">Are You Sure ?</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">Do you Want to Delete? It can be retrieved.</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
            @click="storyDelete()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';

import StoryService from '@/services/StoryService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const storyService = new StoryService();

export default {
  name: 'About',
  props: [],
  components: {
    DashboardTemplate,
    Loader,
    VuejsPaginateNext,
    ErrorDisplay,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(false);
    const storyId = ref('');
    const searchStory = ref('');
    const currentOffset = ref(0);
    const storyPerPage = ref(10);
    const pageCount = ref(0);
    const perPageStory = ref(0);
    const page = ref(1);
    const routePath = ref('');
    routePath.value = route.path;
    const successMsg = ref('');
    const errorMsg = ref('');

    isLoading.value = true;

    const SearchData = {
      searchStory,
      currentOffset,
      storyPerPage,
    };
    store.dispatch('fetchAllStories', SearchData).then((response) => {
      pageCount.value = Math.ceil(response.data.total / storyPerPage.value);
      isLoading.value = false;
    });

    const stories = computed(() => store.getters.getAllStories);

    const deletePopUp = (id) => {
      storyId.value = id;
    };

    const conversionStatus = (status) => {
      let newStatus;
      if (status === 'not_applicable') {
        newStatus = 'Conversion Completed';
      } else if (status === 'ready_to_send') {
        newStatus = 'Require Conversion';
      } else if (status === 'send_pending') {
        newStatus = 'Conversion In Progress';
      } else if (status === 'failed') {
        newStatus = 'Conversion Failed';
      } else {
        newStatus = 'Conversion Completed';
      }
      return newStatus;
    };

    const storyDelete = () => {
      isLoading.value = true;
      storyService.storyDelete(storyId.value).then((res) => {
        store.dispatch('fetchAllStories', SearchData).then((response) => {
          isLoading.value = false;
          storyId.value = '';
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          pageCount.value = Math.ceil(response.data.total / storyPerPage.value);
          successMsg.value = res?.data?.message;
          setTimeout(() => {
            successMsg.value = '';
          }, 3000);
        });
      }).catch((error) => {
        isLoading.value = false;
        errorMsg.value = error?.data;
        setTimeout(() => {
          errorMsg.value = '';
        }, 3000);
      });
    };

    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchStory,
        currentOffset,
        storyPerPage,
      };
      isLoading.value = true;
      store.dispatch(
        'fetchAllStories',
        search,
      ).then((response) => {
        pageCount.value = Math.ceil(response.data.total / storyPerPage.value);

        isLoading.value = false;
      });
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;
      currentOffset.value = storyPerPage.value * (pageNum - 1);
      const Data = {
        searchStory,
        currentOffset,
        storyPerPage,
      };
      store.dispatch('fetchAllStories', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / storyPerPage.value);
        isLoading.value = false;
      });
    };
    return {
      stories,
      store,
      isLoading,
      storyId,
      deletePopUp,
      storyDelete,
      searchStory,
      searchClick,
      currentOffset,
      storyPerPage,
      pageCount,
      page,
      pageClick,
      routePath,
      successMsg,
      errorMsg,
      conversionStatus,
    };
  },

};
</script>
