/* eslint-disable indent */
import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });

export default class PlaylistService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getAllPlaylist(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.searchPlaylist.value) {
        apiEndPoint = `${this.baseURL}v1/playlists/for-dashboard/?search=${term.searchPlaylist.value}&orderBy=-updated_at&limit=${term.playlistPerPage.value}&offset=${term.currentOffset.value}`;
      } else {
        apiEndPoint = `${this.baseURL}v1/playlists/for-dashboard/?orderBy=-updated_at&limit=${term.playlistPerPage.value}&offset=${term.currentOffset.value}`;
      }
      // const apiEndPoint = `${this.baseURL}v1/playlists/?limit=10&orderBy=-updated_at`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  playlistUpload(data, token) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/playlists/`;
      this.http
        .post(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  playlistUpdate(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/playlists/${id}/`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getPlaylistDetailsById(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/playlists/${id}/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  playlistDelete(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/playlists/${id}/`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  playlistPermanentDelete(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/playlists/delete/${id}/`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  playlistStoryDelete(playlistId, story) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/playlists/${playlistId}/stories`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
           data: story,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getStoryDetailsByPlaylistsId(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/playlists/${id}/stories`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getAllDeletedPlaylist(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.searchPlaylist.value) {
         apiEndPoint = `${this.baseURL}v1/playlists/?search=${term.searchPlaylist.value}&orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}&deleted_only=true`;
      } else {
         apiEndPoint = `${this.baseURL}v1/playlists/?orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}&deleted_only=true`;
      }
      // const apiEndPoint = `${this.baseURL}v1/playlists/?limit=10&orderBy=-updated_at`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  restorePlaylist(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/playlists/restore/${id}`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
}
