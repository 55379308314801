/* eslint-disable import/extensions */
import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

export default {
  state() {
    return {
      playlistGenres: [],
      brand: [],
      productType: [],
      productBySku: [],
      shippingCategories: [],
      priceLists: [],
      stockLocations: [],
    };
  },
  mutations,
  actions,
  getters,
};
