<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-headphones"></i> Add New Headphones</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to add headphone"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="" class="form-label">Name</label>
                    <input type="text" class="form-control" v-model="storyPhoneUpload.name" />
                    <span class="text-danger" v-if="v$.name.$error">
                      {{ v$.name.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Model Name</label>
                    <select
                        id="parent"
                        name="parent"
                        class="select form-select"
                        v-model="storyPhoneUpload.modelName"
                      >
                        <option
                          v-for="(item, index) in storyPhoneModels?.items"
                          :key="index"
                          :value="item.model_name"
                        >
                          {{ item.model_name }}
                        </option>
                      </select>
                    <span class="text-danger" v-if="v$.modelName.$error">
                      {{ v$.modelName.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Device ID</label>
                    <input type="text" class="form-control" v-model="storyPhoneUpload.deviceId" />
                    <span class="text-danger" v-if="v$.deviceId.$error">
                      {{ v$.deviceId.$errors[0].$message }}*
                    </span>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="form-check form-switch mt-5 ms-3">
                      <label class="form-check-label" for="flexSwitchCheckChecked">For kids</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="storyPhoneUpload.forKids"
                        id="flexSwitchCheckChecked"
                        :true-value="true"
                        :false-value="false"
                      />
                    </div>
                  </div> -->
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Paried</label>
                    <input type="text" class="form-control" v-model="storyPhoneUpload.paired" />
                  </div> -->
                  <div class="col-md-6">
                    <label for="" class="form-label">Owner</label>
                    <VueMultiselect
                      v-if="user.items"
                      v-model="storyPhoneUpload.owner"
                      :options="user.items"
                      :close-on-select="true"
                      :clear-on-select="false"
                      placeholder="Select one owner"
                      label="email"
                      track-by="email"
                    />
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Kid Birthday</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="storyPhoneUpload.kidBirthday"
                    />
                  </div> -->
                  <div class="col-md-6">
                    <label for="" class="form-label">Remote Id</label>
                    <input type="text" class="form-control" v-model="storyPhoneUpload.remoteId" />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Max Volume DB</label>
                    <select class="form-select" id="dropdown" v-model="storyPhoneUpload.maxVolumeDb">
                      <option value=75>75</option>
                      <option value=85>85</option>
                      <option value=94>94</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Mac Address</label>
                    <input type="text" class="form-control" v-model="storyPhoneUpload.macAddress" />
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Last Downloaded</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="storyPhoneUpload.lastDownloaded"
                    />
                  </div> -->
                </div>
                <div class="container text-end mt-4 mb-3">
                  <button
                    type="button"
                    @click="uploadStoryPhone()"
                    class="btn btn-outline-success me-1"
                  >
                    Add
                  </button>
                  <router-link to="/headphones" type="button" class="btn btn-outline-danger"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>

<script lang="js">

import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import {
  required, email, helpers, between,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';

import { formatUTC } from '@/helper/CommonHelper';

import StoryPhoneService from '@/services/StoryphoneService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const storyPhoneService = new StoryPhoneService();

export default {
  name: 'AddNewHeadphones',
  props: [],
  components: {
    Loader,
    DashboardTemplate,
    VueMultiselect,
    ErrorDisplay,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const routePath = ref('');
    const router = useRouter();
    routePath.value = route.path;
    const isLoading = ref(false);
    const addModel = ref(false);
    const searchStoryPhone = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const delErrorMsg = ref('');
    const storyPhoneId = ref('');

    const rules = {
      name: { required: helpers.withMessage('Please enter a name', required) },
      deviceId: { required: helpers.withMessage('Please enter a device id', required) },
      modelName: { required: helpers.withMessage('Please enter a model name', required) },
    };

    store.dispatch('fetchStoryPhoneModels');

    const storyPhoneUpload = reactive({
      paired: '',
      name: '',
      deviceId: '',
      forKids: false,
      kidBirthday: '',
      lastDownloaded: '',
      maxVolumeDb: 0,
      macAddress: '',
      remoteId: '',
      modelName: '',
      owner: '',

    });

    const v$ = useVuelidate(rules, storyPhoneUpload);
    const currentOffset = ref(0);
    const perPageUser = ref(0);

    const searchUser = ref('');

    const searchUserData = {
      searchUser,
      currentOffset,
      perPageUser,
    };
    store.dispatch('fetchAllUsers', searchUserData);

    const user = computed(() => store.getters.getAllUsers);
    const storyPhones = computed(() => store.getters.getAllStoryPhone);
    const storyPhonesDetals = computed(() => store.getters.getStoryPhoneById);
    const storyPhoneModels = computed(() => store.getters.getstoryPhoneModels);

    const uploadStoryPhone = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        let Data;
        if (storyPhoneUpload.maxVolumeDb) {
          Data = {
          // paired: storyPhoneUpload.paired ? new Date(storyPhoneUpload.paired).toISOString() : null,
            name: storyPhoneUpload.name,
            device_id: storyPhoneUpload.deviceId,
            // for_kids: storyPhoneUpload.forKids,
            // kid_birthday: storyPhoneUpload.kidBirthday ? new Date(storyPhoneUpload.kidBirthday).toISOString() : null,
            // last_downloaded: storyPhoneUpload.lastDownloaded ? new Date(storyPhoneUpload.lastDownloaded).toISOString() : null,
            max_volume_db: storyPhoneUpload.maxVolumeDb,
            mac_address: storyPhoneUpload.macAddress,
            remote_id: storyPhoneUpload.remoteId,
            details: storyPhoneUpload.modelName,
            owner: storyPhoneUpload.owner.id,
          };
        } else {
          Data = {
          // paired: storyPhoneUpload.paired ? new Date(storyPhoneUpload.paired).toISOString() : null,
            name: storyPhoneUpload.name,
            device_id: storyPhoneUpload.deviceId,
            // for_kids: storyPhoneUpload.forKids,
            // kid_birthday: storyPhoneUpload.kidBirthday ? new Date(storyPhoneUpload.kidBirthday).toISOString() : null,
            // last_downloaded: storyPhoneUpload.lastDownloaded ? new Date(storyPhoneUpload.lastDownloaded).toISOString() : null,
            mac_address: storyPhoneUpload.macAddress,
            remote_id: storyPhoneUpload.remoteId,
            details: storyPhoneUpload.modelName,
            owner: storyPhoneUpload.owner.id,
          };
        }
        storyPhoneService.storyPhoneUpload(Data)
          .then(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            isLoading.value = false;
            successMsg.value = ' HeadPhone Has Been Added Successfully';
            setTimeout(() => {
              successMsg.value = '';
              router.push('/headphones');
            }, 3000);
          })
          .catch((error) => {
            isLoading.value = false;
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };

    return {
      isLoading,
      storyPhones,
      user,
      storyPhoneUpload,
      uploadStoryPhone,
      storyPhoneId,
      storyPhonesDetals,
      formatUTC,
      searchStoryPhone,
      currentOffset,
      routePath,
      v$,
      addModel,
      successMsg,
      errorMsg,
      delErrorMsg,
      storyPhoneModels,
    };
  },
};
</script>
