import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });
export default class FirmWareService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getFirmWare(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.searchFirm.value) {
        apiEndPoint = `${this.baseURL}v1/firmware/?search=${term.searchFirm.value}&orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}`;
      } else {
        apiEndPoint = `${this.baseURL}v1/firmware/?orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}`;
      }
      // const apiEndPoint = `${this.baseURL}v1/firmware/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getFirmWareDetails(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/firmware/${id}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  FirmWareUpdate(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/firmware/${id}`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  FirmWareUpload(data, token) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/firmware/`;
      // console.log(data);
      this.http
        .post(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  firmWareDelete(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/firmware/${id}`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
