<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/valid-v-model -->
<!-- eslint-disable no-shadow -->
<!-- eslint-disable no-undef -->
<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class=""></i> Add New Page</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to add page"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="title" class="form-label">Page Name</label>
                    <input type="text" class="form-control" v-model="cmslistUpload.page_name" />
                    <span class="text-danger" v-if="v$.page_name.$error">
                      {{ v$.page_name.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="title" class="form-label">Slug</label>
                    <input type="text" class="form-control" v-model="cmslistUpload.slug" />
                    <span class="text-danger" v-if="v$.slug.$error">
                      {{ v$.slug.$errors[0].$message }}*
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="title" class="form-label">Subheading</label>
                    <input type="text" class="form-control" v-model="cmslistUpload.description" />
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="parent" class="form-label">parent</label>
                    <form action="">
                      <select
                        id="parent"
                        name="parent"
                        class="select form-select"
                        v-model="cmslistUpload.parent"
                      >
                        <option
                          v-for="(item, index) in cmsListDetails.items"
                          :key="index"
                          :value="item.id"
                        >
                          {{ item.page_name }}
                        </option>
                      </select>
                    </form>
                  </div> -->

                  <div class="col-md-6">
                    <label for="lang" class="form-label">Language</label>
                    <form action="">
                      <select
                        id="lang"
                        name="lang"
                        class="select form-select"
                        v-model="cmslistUpload.language"
                      >
                        <option
                          v-for="(item, index) in language.items"
                          :key="index"
                          :value="item.id"
                        >
                          {{ item.language }}
                        </option>
                      </select>
                      <span class="text-danger" v-if="v$.language.$error">
                        {{ v$.language.$errors[0].$message }}*
                      </span>
                    </form>
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="status" class="form-label">publish_status</label>
                    <form action="">
                      <select
                        id="status"
                        name="status"
                        class="select form-select"
                        v-model="cmslistUpload.publish_status"
                      >
                        <option value="draft">draft</option>
                        <option value="published">published</option>
                        <option value="deleted">deleted</option>
                      </select>
                      <span class="text-danger" v-if="v$.publish_status.$error">
                        {{ v$.publish_status.$errors[0].$message }}*
                      </span>
                    </form>
                  </div> -->

                  <!-- <div class="col-md-6">
                    <div class="upload-video">
                      <label for="title" class="form-label">Videos</label>
                      <input
                        type="file"
                        class="form-control-file"
                        id="video-file"
                        accept="video/*"
                        hidden="hidden"
                      />

                      <button
                        class="btn btn-primary form-control"
                        type="button"
                        id="custom-button"
                        @click="uploadVideoFile"
                      >
                        <span>Add videos <i class="bi bi-upload" aria-hidden="true"></i></span>
                      </button>
                      <ul>
                        <li v-for="(vdo, index) in video" :key="index">
                          {{ vdo }}<button @click="deleteVideoFile()">del</button>
                        </li>
                      </ul>
                    </div>
                  </div> -->
                  <!-- <div class="col-md-6">
                    <div class="upload-block">
                      <label for="" class="form-label">Images</label>
                      <input
                        type="file"
                        class="form-control-file"
                        id="image-file"
                        accept="image/*"
                        hidden="hidden"
                      />
                      <button
                        class="btn btn-primary form-control"
                        type="button"
                        id="custom-button"
                        @click="uploadFile()"
                      >
                        <span>Add images <i class="bi bi-upload" aria-hidden="true"></i></span>
                      </button>

                      <ul>
                        <li v-for="(img, index) in image" :key="index">
                          {{ img }}<button @click="deleteImageFile()">del</button>
                        </li>
                      </ul>
                    </div>
                  </div> -->

                  <!-- Page Image Section -->
                  <div class="col-md-12 border">
                    <h5 class="mt-4">Page Images</h5>
                    <form class="p-5">
                      <div class="form-group row">
                        <label for="title" class="form-label col-md-2">Image</label>
                        <div class="col-md-6 mb-4">
                          <input
                            type="file"
                            class="form-control-file"
                            id="image-file"
                            accept="image/*"
                            hidden="hidden"
                          />
                          <button
                            class="btn btn-primary form-control"
                            type="button"
                            id="custom-button"
                            @click="uploadFile()"
                          >
                            <span>Add images <i class="bi bi-upload" aria-hidden="true"></i></span>
                          </button>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="title" class="form-label col-md-2">Preview:</label>
                        <div class="col-md-6">
                          <!-- <ul>
                            <li v-for="(img, index) in image" :key="index">
                              {{ img }}<button @click="deleteImageFile()">del</button>
                            </li>
                          </ul> -->
                          <ul>
                            <li v-for="(img, index) in imageName" :key="index">
                              <img style="width: 100px" :src="img" alt="" class="mt-2 me-4" />
                              <button
                                class="btn btn-outline-danger rounded-5 bi bi-trash"
                                @click="deleteImageFile(img, index)"
                              ></button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>

                  <!-- Details Section -->
                  <div
                    class="col-md-12 border"
                    v-for="(data, counter) in detail"
                    v-bind:key="counter"
                  >
                    <div class="container text-end mt-3">
                      <span @click="deleteDetail(counter)">x</span>
                    </div>
                    <h5>Details</h5>
                    <form class="p-5">
                      <label for="title" class="form-label mx-2">Title</label>
                      <input type="text" v-model.lazy="data.title" required class="form-control" />
                      <label for="title" class="form-label mx-2 mt-2">Description</label>
                      <textarea
                        rows="10"
                        cols="30"
                        name=""
                        id=""
                        type="text"
                        class="form-control mt-2"
                        v-model.lazy="data.description"
                      ></textarea>
                      <label for="title" class="form-label mx-2 mt-2">Sort Order</label>
                      <input
                        type="number"
                        min="0"
                        class="form-control mt-2"
                        v-model.lazy="data.sort_order"
                      />
                    </form>
                  </div>
                  <button @click="add()" class="btn btn-primary mt-4">Add more details</button>
                </div>
              </div>
            </div>
            <button class="btn btn-primary mt-4" @click="createCmslist">Upload</button>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script>
import { useStore } from 'vuex';
import {
  ref, reactive, onMounted, computed,
} from 'vue';
import { required, helpers } from '@vuelidate/validators';
import { useRouter } from 'vue-router';

import useVuelidate from '@vuelidate/core';

import CmsPageService from '@/services/CmsPageService';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';
// import LoginVue from './Login.vue';

const cmsPageService = new CmsPageService();
let imageFileBtn;
let editImageFileBtn;
let videoFileBtn;
let editVideoFileBtn;

export default {
  name: 'AddCmsPage',
  components: {
    DashboardTemplate,
    Loader,
    ErrorDisplay,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const Details = ref([]);
    const searchCms = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const input = ref([]);
    const language = ref([]);
    const cmsList = ref([]);
    const image = ref([]);
    const imageName = ref([]);
    const video = ref([]);
    const successMsg = ref('');
    const errorMsg = ref('');
    const isLoading = ref(false);
    const detail = reactive([
      {
        title: '',
        description: '',
        sort_order: '',
      },
    ]);

    const add = () => {
      detail.push({
        title: '',
        description: '',
        sort_order: '',
      });
    };

    const cmslistUpload = reactive({
      page_name: '',
      slug: '',
      description: '',
      parent: '',
      language: '',
      publish_status: '',
      details: [],
      det: '',
      sort: '',
      images: [],
      videos: [],
    });

    const rules = {
      page_name: { required: helpers.withMessage('Please enter a Page Name', required) },
      slug: { required: helpers.withMessage('Please enter a slug', required) },
      language: { required: helpers.withMessage('Please select a language', required) },
      publish_status: {
        required: helpers.withMessage('Please select a publish language', required),
      },
    };

    const v$ = useVuelidate(rules, cmslistUpload);

    const deleteDetail = (counter) => {
      detail.splice(counter, 1);
    };

    onMounted(() => {
      imageFileBtn = document.getElementById('image-file');
      editImageFileBtn = document.getElementById('edit-image-file');
      videoFileBtn = document.getElementById('video-file');
      editVideoFileBtn = document.getElementById('edit-video-file');
    });

    const imageListener = async () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.files[0];
        cmslistUpload.images = file;
        const url = await URL.createObjectURL(file);
        imageName.value.push(url);
        image.value.push(file);
      } else {
        console.log('no file selected');
      }
    };

    const uploadFile = () => {
      imageFileBtn.click();
      imageFileBtn.addEventListener('change', imageListener);
    };

    const videoListener = () => {
      if (videoFileBtn.value) {
        const file = videoFileBtn.files[0];
        cmslistUpload.videos = file;
        video.value.push(file);
      } else {
        console.log('no file selected');
      }
    };

    const uploadVideoFile = () => {
      videoFileBtn.click();
      videoFileBtn.addEventListener('change', videoListener);
    };

    const isDetailsEmpty = (obj) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== '') {
          return false;
        }
      }
      return true;
    };

    const createCmslist = async () => {
      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        const formData = new FormData();
        formData.append('page_name', cmslistUpload.page_name);
        formData.append('slug', cmslistUpload.slug);
        formData.append('description', cmslistUpload.description);
        // if (cmslistUpload.parent) {
        //   formData.append('parent', cmslistUpload.parent);
        // }

        formData.append('language', cmslistUpload.language);
        // formData.append('publish_status', cmslistUpload.publish_status);

        const newDetails = detail.filter((obj) => !isDetailsEmpty(obj));

        if (newDetails.length > 0) {
          detail.forEach((item, index) => {
            formData.append(`details[${index}][title]`, item.title);
            formData.append(`details[${index}][description]`, item.description);
            formData.append(`details[${index}][sort_order]`, item.sort_order);
          });
        }

        image.value.forEach((item) => formData.append('images', item));
        image.value.forEach((item, index) => formData.append(`image_sort_orders[${index}]`, index));
        // video.value.forEach((item, index) => formData.append(`video_sort_orders[${index}]`, index));
        // video.value.forEach((item) => formData.append('videos', item));
        cmsPageService
          .cmslistUpload(formData)
          .then((res) => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            isLoading.value = false;
            successMsg.value = 'Page Has Been Added Successfully';
            setTimeout(() => {
              successMsg.value = '';
              router.push('/cms-page');
            }, 3000);
          })
          .catch((error) => {
            isLoading.value = false;
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };
    const deleteImageFile = (img, index) => {
      image.value.splice(index, 1);
      imageName.value.splice(index, 1);
    };
    const deleteVideoFile = (vdo) => {
      video.value.forEach((element, index) => {
        if (element.name !== vdo) {
          video.value.splice(index, 1);
        }
      });
    };
    const deleteData = (i) => {
      input.value.forEach((element, index) => {
        if (element === i) {
          input.value.splice(index, 1);
        }
      });
    };
    const SearchData = {
      searchCms,
      currentOffset,
      perPage,
    };

    store.dispatch('fetchAllCmslists', SearchData);
    const cmsListDetails = computed(() => store.getters.getCmsLists);
    cmsPageService.getCmsLanguage().then((res) => {
      language.value = res.data;
    });

    return {
      cmslistUpload,
      createCmslist,
      uploadFile,
      uploadVideoFile,
      image,
      deleteImageFile,
      deleteVideoFile,
      video,
      cmsList,
      detail,
      Details,
      deleteData,
      input,
      language,
      cmsListDetails,
      v$,
      add,
      deleteDetail,
      isLoading,
      successMsg,
      errorMsg,
      imageName,
    };
  },
};
</script>
