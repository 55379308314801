<template lang="html">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-file-music"></i> Edit Story</h1>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="ISBN already exists"/>
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="" class="form-label">Title</label>
                    <input type="text" class="form-control" v-model="editStory.title" />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">ISBN</label>
                    <input type="text" class="form-control" v-model="editStory.isbn" />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Language</label>
                    {{ lang }}
                    <select name="" id="" class="form-select" v-model="lang">
                      <option value="">select the Language</option>
                      <option
                        v-for="(lang, index) in language.items"
                        :key="index"
                        :value="lang.language"
                      >
                        {{ lang?.language }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Type</label>
                    <select name="" id="" class="form-select" v-model="editStory.type">
                      <option value="">select the Type</option>
                      <option
                        v-for="(type, index) in storyType.items"
                        :key="index"
                        :value="type.slug"
                      >
                        {{ type.slug }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Owner</label>

                    <VueMultiselect
                      v-if="user.items"
                      v-model="editStory.owner"
                      :options="user.items"
                      :close-on-select="true"
                      :clear-on-select="false"
                      placeholder="Select one owner"
                      label="email"
                      track-by="id"
                      :taggable="true"
                    />
                  </div>

                  <div class="col-md-6">
                    <div class="upload-block">
                      <label for="" class="form-label">Audio</label>
                      <div>
                        <audio
                          id="audio-preview"
                          class="w-100"
                          :src="audioURL"
                          controls
                          v-show="file != ''"
                        />
                      </div>
                      <input
                        type="file"
                        class="form-control-file"
                        id="edit-audio-file"
                        accept="audio/*"
                        hidden="hidden"
                      />
                      <button
                        class="btn btn-primary form-control"
                        type="button"
                        id="custom-button"
                        @click="editAudioFile()"
                      >
                        <span>Audio <i class="bi bi-upload" aria-hidden="true"></i></span>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Image</label>

                    <div class="input-group">
                      <div class="input-group-text">
                        <div v-if="imageURL">
                          <img :src="imageURL" :alt="storyById.title" style="width: 60px" />
                        </div>
                        <!-- <div v-if="storyById.image || (storyById.thumbnail && !imageURL)">
                          <img
                            :src="storyById.thumbnail"
                            :alt="storyById.title"
                            style="width: 60px"
                          />
                        </div>
                        <div v-else-if="imageURL">
                          <img :src="imageURL" alt="" style="width: 80px" />
                        </div> -->
                        <div v-else class="text-danger">No Image</div>
                      </div>
                      <div class="upload-block ms-3 mt-4">
                        <input
                          type="file"
                          class="form-control-file"
                          id="edit-image-file"
                          accept="image/*"
                          hidden="hidden"
                        />
                        <button
                          class="btn btn-primary form-control"
                          type="button"
                          id="custom-button"
                          @click="editImageFile()"
                        >
                          <span>Image <i class="bi bi-upload" aria-hidden="true"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="" class="form-label">Description</label>
                    <textarea
                      class="form-control"
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      v-model="editStory.description"
                    ></textarea>
                  </div> -->
                  <div class="col-md-6">
                    <label for="" class="form-label">Parent Story</label>
                    <VueMultiselect
                      v-if="isbnStories"
                      v-model="editStory.parent"
                      :options="isbnStories"
                      :close-on-select="true"
                      :clear-on-select="false"
                      placeholder="Select Parent Story"
                      trackBy="id"
                      label="title"
                      :taggable="true"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label">Orginal Story</label>
                    <input type="text" class="form-control" v-model="editStory.orginal_file"  disabled/>
                  </div>
                </div>
                <div class="container text-end">
                  <router-link to="/stories" type="button" class="btn btn-outline-danger me-2"
                    >Cancel</router-link
                  >
                  <button @click="editUpload()" class="btn btn-outline-success">Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';

import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';

import StoryService from '@/services/StoryService';
import Loader from '@/components/commons/Loader.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const storyService = new StoryService();

let editAudioFileBtn;
let editImageFileBtn;

export default {
  name: 'About',
  props: [],
  components: {
    DashboardTemplate,
    Loader,
    VueMultiselect,
    ErrorDisplay,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);
    const arrayAudio = ref(null);
    const storyId = ref('');
    const searchStory = ref('');
    const searchStoryType = ref('');
    const currentOffset = ref(0);
    const perPage = ref(10);
    const perPageUser = ref(0);
    const pageCount = ref(0);
    const page = ref(1);
    const routePath = ref('');
    const perPageStory = ref(0);
    const routeId = ref('');
    const successMsg = ref('');
    const storyPerPage = ref(10);
    const errorMsg = ref('');
    routePath.value = route.path;
    const owner = ref('');
    const lang = ref('');
    const searchLanguage = ref('');
    const langPerPage = ref(0);
    const imageURL = ref('');
    const audioURL = ref('');
    const isbnStories = ref([]);
    routeId.value = route.params.id;

    const storyUpload = reactive({
      title: '',
      description: '',
      type: '',
      owner: '',
      image: '',
      audio: '',
      parent: '',
      language: '',

    });

    const editStory = reactive({
      title: '',
      description: '',
      type: '',
      owner: '',
      image: '',
      isbn: '',
      audio: '',
      parent: '',
      language: '',
      orginal_file: '',

    });
    const value = ref([
      { name: 'Javascript', code: 'js' },
    ]);

    const options = ref([
      { name: 'Vue.js', code: 'vu' },
      { name: 'Javascript', code: 'js' },
      { name: 'Open Source', code: 'os' },
    ]);

    isLoading.value = true;

    const previewAudio = () => {
      const audio = document.getElementById('audio-preview');
      const reader = new FileReader();

      reader.readAsDataURL(audioURL.value);
      reader.addEventListener('load', () => {
        audio.src = reader.result;
      });
    };

    const convertImageUrlToFile = async (url) => {
      if (url === null || url === '') {
        editStory.image = null;
      } else {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', {
            type: blob.type,
          });
          editStory.image = file;
        } catch (error) {
          console.error('Error converting image URL to file:', error);
        }
      }
    };
    const convertAudioURLtoFile = async (url) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], 'audio.mp3', {
          type: blob.type,
        });
        audioURL.value = URL.createObjectURL(blob);
      } catch (error) {
        console.error('Error converting image URL to file:', error);
      }
    };

    store.dispatch('fetchStoryById', routeId.value).then((response) => {
      isLoading.value = false;
      // storyById.value = respones.data;
      editStory.title = response.data.title;
      editStory.description = response.data.description;
      editStory.type = response.data.type;
      editStory.owner = response.data.owner;
      editStory.isbn = response.data.isbn;
      convertImageUrlToFile(response.data.image);
      convertAudioURLtoFile(response.data.file);
      imageURL.value = response.data.thumbnail;
      audioURL.value = response.data.file;
      editStory.orginal_file = response.data.original_file;
      lang.value = response?.data?.language?.language;
      editStory.parent = response.data.parent;
      editStory.language = response.data.language;
    });

    const storyData = {
      searchStoryType,
      currentOffset,
      perPageStory,
    };

    store.dispatch('fetchAllStoryType', storyData);

    store.dispatch('fetchStoryShieldStories').then((response) => {
      isbnStories.value = response.data.items.filter((item) => item.isbn !== null);
    });

    const searchUser = ref('');

    const searchUserData = {
      searchUser,
      currentOffset,
      perPageUser,
    };

    store.dispatch('fetchAllUsers', searchUserData);

    const langData = {
      searchLanguage,
      currentOffset,
      langPerPage,
    };

    store.dispatch('fetchAllLanguages', langData);

    const storyById = computed(() => store.getters.getStoryById);
    const stories = computed(() => store.getters.getAllStoriesWithOutLimt);
    const user = computed(() => store.getters.getAllUsers);
    const storyType = computed(() => store.getters.getAllStoryType);
    const language = computed(() => store.getters.getLanguage);

    onMounted(() => {
      editAudioFileBtn = document.getElementById('edit-audio-file');
      editImageFileBtn = document.getElementById('edit-image-file');
    });

    const editAudioListener = () => {
      if (editAudioFileBtn.value) {
        const file = editAudioFileBtn.files[0];
        editStory.audio = file;
        audioURL.value = file;
        // const blob = new Blob([file], { type: file.type });
        // audioURL.value = URL.createObjectURL(blob);
        previewAudio();
      } else {
        console.log('no file selected');
      }
    };

    const editImageListener = () => {
      if (editImageFileBtn.value) {
        const file = editImageFileBtn.files[0];

        editStory.image = file;

        const blob = new Blob([file], { type: file.type });
        imageURL.value = URL.createObjectURL(blob);
      } else {
        console.log('no file selected');
      }
    };

    const editAudioFile = async () => {
      editAudioFileBtn = await document.getElementById('edit-audio-file');
      editAudioFileBtn.click();
      editAudioFileBtn.addEventListener('change', editAudioListener);
    };

    const editImageFile = () => {
      editImageFileBtn.click();
      editImageFileBtn.addEventListener('change', editImageListener);
    };

    const editUpload = async () => {
      isLoading.value = true;

      let languageSelected = '';
      if (lang.value) {
        language.value.items.forEach((item) => {
          if (item.language === lang.value) {
            languageSelected = item.code;
          }
        });
      }
      try {
        const formData = new FormData();
        formData.append('title', editStory.title);
        // formData.append('description', editStory.description);
        formData.append('type', editStory.type);
        if (owner.value) {
          formData.append('owner', owner.value.id);
        } else if (editStory.owner) {
          formData.append('owner', editStory.owner.id);
        }
        if (editStory.image) {
          formData.append('image', editStory.image);
        }
        if (editStory.audio) {
          formData.append('file', editStory.audio);
        }
        formData.append('language', languageSelected);
        if (editStory.isbn) {
          formData.append('isbn', editStory.isbn);
        }
        if (editStory.parent) {
          formData.append('parent', editStory.parent.isbn);
        }
        await storyService.storyUpdate(storyById.value.id, formData);
        isLoading.value = false;
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        successMsg.value = ' Story Has Been Updated Successfully';
        setTimeout(() => {
          successMsg.value = '';
          router.push('/stories').then(() => { router.go(); });
        }, 3000);
      } catch (error) {
        isLoading.value = false;
        errorMsg.value = error?.data;
        setTimeout(() => {
          errorMsg.value = '';
        }, 3000);
        console.error('Error updating story:', error);
      }
    };

    const addTag = (newTag) => {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      options.value.push(tag);
      value.value.push(tag);
    };

    return {
      storyType,
      stories,
      user,
      store,
      storyUpload,
      isLoading,
      storyById,
      editStory,
      arrayAudio,
      editAudioFile,
      editImageFile,
      editUpload,
      storyId,
      searchStory,
      currentOffset,
      perPage,
      pageCount,
      page,
      language,
      routePath,
      owner,
      successMsg,
      errorMsg,
      imageURL,
      audioURL,
      lang,
      value,
      options,
      addTag,
      isbnStories,
    };
  },

};
</script>
