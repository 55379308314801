<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <DashboardTemplate>
    <div class="row p-3">
      <div class="col-md-12">
        <div class="row">
          <div class="col">
            <h1><i class="bi bi-people"></i> Users</h1>
          </div>
          <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
            <div class="row justify-content-end">
              <div class="col">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Select Deleted or Active Items Here"
                >
                  <router-link
                    to="/users"
                    class="btn btn-outline-success rounded-start-5 tooltip-wrap"
                    :class="{ active: routePath === '/users' }"
                  >
                    <i class="bi bi-radioactive"></i>
                    Active
                    <span class="tooltip">Active Items</span>
                  </router-link>
                  <router-link
                    to="/deleted-user"
                    class="btn btn-outline-danger rounded-end-5 tooltip-wrap"
                    :class="{ active: routePath === '/deleted-user' }"
                  >
                    <i class="bi bi-trash-fill"></i>
                    Deleted
                    <span class="tooltip">Deleted Items</span>
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <label for="inputPassword2" class="visually-hidden">Search</label>
                <input
                  type="text"
                  class="form-control rounded-5"
                  placeholder="Search"
                  v-model="searchUser"
                  v-on:keyup.enter="searchClick()"
                />
              </div>
              <div class="col-auto tooltip-wrap">
                <button
                  type="submit"
                  class="btn btn-outline-primary mb-3 rounded-5"
                  @click="searchClick()"
                >
                  <i class="bi bi-search"></i>
                  <span class="tooltip">Search Here</span>
                </button>
              </div>
              <div class="col-auto">
                <a href="/new-user" class="btn btn-outline-success me-3 rounded-5 tooltip-wrap"
                  ><i class="bi bi-database-add"></i>
                  <span class="tooltip">Add New</span>
                </a>
                <!-- <a href="#" class="btn btn-outline-secondary me-1 rounded-5"
                    ><i class="bi bi-upload"></i> Bulk Upload</a
                  > -->
                <a
                  href="#"
                  @click="downloadCSV"
                  class="btn btn-outline-secondary me-1 rounded-5 tooltip-wrap"
                  ><i class="bi bi-download"></i>
                  <span class="tooltip">Download CSV</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="successMsg" class="card text-success text-center border-success rounded-5 mb-3">
          <div class="card-body">{{ successMsg }}</div>
        </div>
        <ErrorDisplay :error="errorMsg" message="Unable to update user"/>
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="card-text">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>UUID</th>
                      <th>Email Address</th>
                      <th>Primary Language</th>
                      <th>Story Download Language</th>
                      <!-- <th>Staff Status</th> -->
                      <th>Active</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="allUsers?.total === 0">
                      <td scope="col" colspan="9" class="text-center text-primary pt-3 pb-3">
                        Not Found
                      </td>
                    </tr>
                    <tr v-else v-for="(item, index) in allUsers.items" :key="index">
                      <td>{{ item.id }}</td>
                      <td>
                       {{ item.email }}
                      </td>
                      <td>{{ item.primary_language }}</td>
                      <td>{{ item.story_download_language }}</td>
                      <!-- <td>{{ item.is_staff }}</td> -->
                      <td>{{ item.is_active }}</td>
                      <td>
                          <button
                            class="btn bi bi-pencil-square btn-outline-primary rounded-5"
                            @click="getUserDetails(item.id)"
                          ></button>
                        </td>
                      <td>
                        <button
                          class="btn btn-outline-danger bi bi-trash rounded-5"
                          data-bs-toggle="modal"
                          data-bs-target="#userDeleteModal"
                          @click="deletePopUp(item.id)"
                        ></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <VuejsPaginateNext
            v-model="page"
            :page-count="pageCount"
            :click-handler="pageClick"
            :margin-pages="2"
            :page-range="5"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'prev-item'"
            :prev-link-class="'page-link prev-link-item'"
            :next-class="'next-item'"
            :next-link-class="'page-link next-link-item'"
            :break-view-class="'break-view'"
            :break-view-link-class="'break-view-link'"
          ></VuejsPaginateNext>
        </div>
      </div>
    </div>
  </DashboardTemplate>
  <!-- edit Modal -->
  <div
    class="modal fade modal-lg"
    id="userEditModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="userEditModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="userEditModalLabel">{{ userDetails.email }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="isLoading"><Loader /></div>

          <div class="container">
            <div class="row g-3">
              <div class="col-md-6">
                <label for="" class="form-label">name</label>
                <input class="form-control" type="text" v-model="userDetails.name" />
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">email</label>
                <input class="form-control" type="text" v-model="userDetails.email" />
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">DOB</label>
                <input class="form-control" type="text" v-model="userDetails.dob" />
              </div>
              <div class="col-md-6">
                <!-- <label for="" class="form-label">Active User</label>
                <input
                class="form-control"
                  type="checkbox"
                  v-model="userDetails.is_active"
                  true-value="true"
                  false-value="false"
                /> -->
                <div class="form-check form-switch mt-3 ms-3">
                  <label class="form-check-label">Active User </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="userDetails.is_active"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- <label for="" class="form-label">Staff User</label>
                <input
                class="form-control"
                  type="checkbox"
                  v-model="userDetails.is_staff"
                  true-value="true"
                  false-value="false"
                /> -->
                <div class="form-check form-switch mt-3 ms-3">
                  <label class="form-check-label">Staff User </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="userDetails.is_staff"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- <label for="" class="form-label">Admin User</label>
                <input
                class="form-control"
                  type="checkbox"
                  v-model="userDetails.is_admin"
                  true-value="true"
                  false-value="false"
                /> -->
                <div class="form-check form-switch mt-3 ms-3">
                  <label class="form-check-label">Admin User </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="userDetails.is_admin"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- <label for="" class="form-label">OnBoarding Completed</label>
                <input
                class="form-control"
                  type="checkbox"
                  v-model="userDetails.onboarding_completed"
                  true-value="true"
                  false-value="false"
                /> -->
                <div class="form-check form-switch mt-3 ms-3">
                  <label class="form-check-label">OnBoarding Completed </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="userDetails.onboarding_completed"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!-- <label for="" class="form-label">Account at Commercelayer</label>
                <input
                class="form-control"
                  type="checkbox"
                  v-model="userDetails.account_created_at_commercelayer"
                  true-value="true"
                  false-value="false"
                /> -->
                <div class="form-check form-switch mt-3 ms-3">
                  <label class="form-check-label">Account at Commercelayer </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="userDetails.account_created_at_commercelayer"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Story Download language update count</label>
                <input
                  class="form-control"
                  type="number"
                  min="0"
                  v-model="userDetails.story_download_language_updated_count"
                />
              </div>
              <div class="col-md-6">
                <div class="input-group">
                  <div class="input-group-text">
                    <img :src="userDetails.image" alt="image" style="width: 60px" />
                  </div>
                  <div class="upload-block ms-3 mt-4">
                    <input
                      type="file"
                      class="form-control-file"
                      id="edit-user-image-file"
                      accept="image/*"
                      hidden="hidden"
                    />
                    <button
                      class="btn btn-primary form-control"
                      type="button"
                      id="custom-button"
                      @click="editImageFile()"
                    >
                      <span>Image <i class="bi bi-upload" aria-hidden="true"></i></span>
                    </button>
                  </div>
                </div>
                <!-- <div class="upload-block">
                  <img :src="userDetails.image" style="width: 50px" alt="" />
                  <label for="" class="form-label">Image</label>
                  <input
                    type="file"
                    class="form-control-file"
                    id="edit-user-image-file"
                    accept="image/*"
                    hidden="hidden"
                  />
                  <button
                    class="btn btn-primary"
                    type="button"
                    id="custom-button"
                    @click="editImageFile()"
                  >
                    <span><i class="bi bi-upload" aria-hidden="true"></i></span>
                    <div id="custom-text">Image</div>
                  </button>
                </div> -->
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Last LoggedIn</label>
                <input class="form-control" type="text" v-model="userDetails.last_logged_in" />
              </div>
              <div class="col-md-6">
                <!-- <label  for="" class="form-label">Is Registered</label>
                <input
                class="form-control"
                  type="checkbox"
                  v-model="userDetails.is_registered"
                  true-value="true"
                  false-value="false"
                /> -->
                <div class="form-check form-switch mt-3 ms-3">
                  <label class="form-check-label">Is Registered </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="userDetails.is_registered"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Story Download Language</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="userDetails.story_download_language"
                />
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Primary Language</label>
                <input type="text" class="form-control" v-model="userDetails.primary_language" />
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Groups</label>
                <VueMultiselect
                  v-if="userGroups.items"
                  v-model="selectedGroups"
                  :options="userGroups.items"
                  :multiple="true"
                  trackBy="title"
                  label="title"
                >
                </VueMultiselect>
              </div>
              <!-- <div class="col-md-6 story-col"> -->
              <!-- <label for="">Owner</label> -->
              <!-- <select name="" id="" v-model="editStory.owner">
                  <option value="">select the Owner</option>
                  <option v-for="(user, index) in user.items" :key="index" :value="user.id">
                    {{ user.email }}
                  </option>
                </select> -->
              <!-- </div> -->
              <!-- <div class="col-md-6 story-col"></div> -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            @click="updateUser()"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="userDeleteModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="userDeleteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="userDeleteModalLabel">Are You Sure ?</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">Are you sure about deleting this user? It can be retrieved</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
            @click="userDelete()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import VueMultiselect from 'vue-multiselect';

import Loader from '@/components/commons/Loader.vue';
import UserService from '@/services/UserService';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const userService = new UserService();
let imageFileBtn;

export default {
  name: 'About',
  props: [],
  components: {
    DashboardTemplate,
    Loader,
    VueMultiselect,
    VuejsPaginateNext,
    ErrorDisplay,
  },
  setup(props) {
    onMounted(() => {
      imageFileBtn = document.getElementById('edit-user-image-file');
    });
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const routePath = ref('');
    routePath.value = route.path;

    store.dispatch('fetchUserGroups');
    const userDetails = ref({});
    const userId = ref('');
    const uploadedImage = ref('');
    const isLoading = ref(false);
    const currentOffset = ref(0);
    const perPageUser = ref(10);
    const pageCount = ref(0);
    const page = ref(1);
    const searchUser = ref('');
    const convertedImage = ref('');
    const selectedGroups = ref([]);
    const successMsg = ref('');
    const errorMsg = ref('');
    const searchData = {
      searchUser,
      currentOffset,
      perPageUser,
    };
    isLoading.value = true;
    store.dispatch('fetchAllUsers', searchData)
      .then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPageUser.value);

        isLoading.value = false;
      });

    const userGroups = computed(() => store.getters.getUserGroups);

    const imageListener = () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.files[0];
        uploadedImage.value = file;
        userDetails.value.image = '';
      } else {
        console.log('no file selected');
      }
    };

    const editImageFile = () => {
      imageFileBtn = document.getElementById('edit-user-image-file');
      imageFileBtn.click();
      imageFileBtn.addEventListener('change', imageListener);
    };

    const convertImageUrlToFile = async (url) => {
      if (url === null) {
        convertedImage.value = '';
      } else {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', {
            type: blob.type,
          });
          convertedImage.value = file;
        } catch (error) {
          console.error('Error converting image URL to file:', error);
        }
      }
    };

    const getUserDetails = (id) => {
      router.push({ name: 'edit-user', params: { id } });
    };

    const updateUser = async () => {
      if (!userDetails.value.image) {
        try {
          await convertImageUrlToFile(userDetails.value.image);
          const formData = new FormData();
          formData.append('name', userDetails.value.name);
          formData.append('email', userDetails.value.email);
          formData.append('image', convertedImage.value);
          // formData.append('dob', userDetails.value.dob);
          formData.append('is_active', 'true');
          formData.append('is_admin', 'true');
          formData.append('story_download_language', userDetails.value.story_download_language);
          formData.append('primary_language', userDetails.value.primary_language);
          formData.append('story_download_language_updated_count', userDetails.value.story_download_language_updated_count);
          selectedGroups.value.forEach((item) => formData.append('groups[]', item.slug));
          await userService.updateUser(userDetails.value.id, formData);
        } catch (error) {
          console.error('Error updating story:', error);
        } finally {
          isLoading.value = false;
          // store.dispatch('fetchAllPlaylists', searchData);
        }
      } else {
        const formData = new FormData();
        formData.append('name', userDetails.value.name);
        formData.append('email', userDetails.value.email);
        formData.append('image', uploadedImage.value);
        // formData.append('dob', userDetails.value.dob);
        formData.append('is_active', 'true');
        formData.append('is_admin', 'true');
        formData.append('story_download_language', userDetails.value.story_download_language);
        formData.append('primary_language', userDetails.value.primary_language);
        formData.append('story_download_language_updated_count', userDetails.value.story_download_language_updated_count);
        selectedGroups.value.forEach((item) => formData.append('groups[]', item.slug));
        userService.updateUser(userDetails.value.id, formData)
          .then((response) => {
            isLoading.value = false;
            // router.push('/users');
          })
          .catch(() => {
            isLoading.value = false;
          });
      }
    };

    const deletePopUp = (id) => {
      userId.value = id;
    };
    const userDelete = () => {
      isLoading.value = true;
      userService.deleteUser(userId.value).then((res) => {
        store.dispatch('fetchAllUsers', searchData)
          .then((response) => {
            pageCount.value = Math.ceil(response.data.total / perPageUser.value);
            userId.value = '';
            isLoading.value = false;
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            successMsg.value = res?.data?.message;
            setTimeout(() => {
              successMsg.value = '';
            }, 3000);
          });
      }).catch((error) => {
        isLoading.value = false;
        errorMsg.value = error?.data;
        setTimeout(() => {
          errorMsg.value = '';
        }, 3000);
      });
    };

    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchUser,
        currentOffset,
        perPageUser,
      };
      isLoading.value = true;
      store.dispatch(
        'fetchAllUsers',
        search,
      ).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPageUser.value);

        isLoading.value = false;
      });
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;

      currentOffset.value = perPageUser.value * (pageNum - 1);
      const Data = {
        searchUser,
        currentOffset,
        perPageUser,
      };
      store.dispatch('fetchAllUsers', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPageUser.value);

        isLoading.value = false;
      });
    };

    const allUsers = computed(() => store.getters.getAllUsers);

    const downloadCSV = () => {
      isLoading.value = true;
      userService.downloadUserCSV().then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'UserData.csv';
        link.click();
        window.URL.revokeObjectURL(url);
        isLoading.value = false;
      });
    };

    return {
      allUsers,
      getUserDetails,
      userDetails,
      deletePopUp,
      userDelete,
      isLoading,
      editImageFile,
      updateUser,
      userGroups,
      searchClick,
      searchUser,
      currentOffset,
      perPageUser,
      pageCount,
      page,
      pageClick,
      selectedGroups,
      routePath,
      downloadCSV,
      successMsg,
      errorMsg,
    };
  },
};
</script>
