/* eslint-disable import/extensions */
import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

export default {
  state() {
    return {
      language: [],
    };
  },
  mutations,
  actions,
  getters,
};
