import LanguageService from '@/services/LanguageService';
import * as types from './mutation-types';

const ent = require('ent');

const languageService = new LanguageService();

export const fetchAllLanguages = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    languageService
      .getAllLanguage(Data)
      .then((response) => {
        commit(types.LANGUAGE, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
