import TypesService from '@/services/TypesService';
import * as types from './mutation-types';

const ent = require('ent');

const typesService = new TypesService();

export const fetchAllPlaylistType = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    typesService
      .getAllPlaylistTypes(Data)
      .then((response) => {
        commit(types.PLAYLIST_TYPE, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchPlaylistTypeDetails = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    typesService
      .playlistTypeDetailsById(Data)
      .then((response) => {
        commit(types.PLAYLIST_TYPE_DETAILS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchAllStoryType = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    typesService
      .getAllStoriesTypes(Data)
      .then((response) => {
        commit(types.STORY_TYPE, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchStoryTypeDetails = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    typesService
      .storyTypeDetailsById(Data)
      .then((response) => {
        commit(types.STORY_TYPE_DETAILS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchAllShieldType = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    typesService
      .getAllShieldTypes(Data)
      .then((response) => {
        commit(types.SHIELD_TYPE, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchShieldTypeDetails = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    typesService
      .shieldTypeDetailsById(Data)
      .then((response) => {
        commit(types.SHIELD_TYPE_DETAILS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);
