import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });
export default class CmsPageService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getCmsPages(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term?.selectedLang?.value) {
        if (term?.searchCms?.value) {
          apiEndPoint = `${this.baseURL}v1/cms/pages/?search=${term.searchCms.value}&orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}&language__eq=${term?.selectedLang?.value}`;
        } else {
          apiEndPoint = `${this.baseURL}v1/cms/pages/?orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}&language__eq=${term?.selectedLang?.value}`;
        }
      } else if (term?.searchCms?.value) {
        apiEndPoint = `${this.baseURL}v1/cms/pages/?search=${term.searchCms.value}&orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}`;
      } else {
        apiEndPoint = `${this.baseURL}v1/cms/pages/?orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}`;
      }
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error.response);
        });
    });
  }

  getCmsPageDetails(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/pages/${id}`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  cmslistUpload(data, token) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/pages/`;
      // console.log(data);
      this.http
        .post(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getCmsLanguage() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/languages/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  cmslistUpdate(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/pages/${id}`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  cmslistDelete(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/pages/${id}`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getPageBySlug() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/cms/pages/by-slug/quilldemo2`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
}
